import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import Pagination from 'react-js-pagination'
import Spinner from '../../Spinner';
import axiosConfig from '../../axiosConfig';
import { handleError, scrollToTop } from '../../helper_funcitons';


export default class AccountHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],

            // pagination
            _activePage: 1,
            _itemsCountPerPage: 10,
            _totalItemsCount: 1,
            _pageRangeDisplayed: 3,
            _search_term: '',
        }

        this.getAccountHistory = this.getAccountHistory.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.changeSearchTerm = this.changeSearchTerm.bind(this)
        this.onChangeItemCount = this.onChangeItemCount.bind(this)
        this.toLogin = this.toLogin.bind(this)

    }

    toLogin() {
        this.setState({
            redirect_to_login: true
        })
    }

    componentDidMount() {
        scrollToTop(0, 0)
        this.getAccountHistory()
    }

    async getAccountHistory(show_spinner = true) {
        this.setState({
            show_spinner: show_spinner,
        })

        await axiosConfig.get(`/api/order-history?page=${this.state._activePage}&per_page=${this.state._itemsCountPerPage}&search_name=${this.state._search_term}`)
            .then(res => {
                this.setState({
                    show_spinner: false
                })

                if (res.data.success) {
                    this.setState({
                        orders: res.data.data.data,
                        _totalItemsCount: res.data.data.total
                    })
                }
            })
            .catch(err => {
                handleError(err, this.toLogin);
            })
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                _activePage: pageNumber
            }, () => {
                this.getAccountHistory()
            }
        );

        let elDistanceToTop = window.pageYOffset + document.getElementById("manage_table").getBoundingClientRect().top
        scrollToTop(elDistanceToTop);
    }

    changeSearchTerm(e) {
        e.preventDefault();
        this.setState({
            _search_term: e.target.value,
            _activePage: 1,
        }, () => {
            this.getAccountHistory(false)
        })
    }

    onChangeItemCount(e) {
        e.preventDefault();
        this.setState({
            _itemsCountPerPage: parseInt(e.target.value),
        }, () => {
            if (this.state._itemsCountPerPage * this.state._activePage > this.state._totalItemsCount) {
                this.setState({
                    _activePage: 1,
                }, () => {
                    this.getAccountHistory()
                })
            }
            else {
                this.getAccountHistory()
            }
        })
    }

    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }}/>
        }

        let order_history_mapping = this.state.orders.map((el, index) => {

            return <tr key={el.id}>
                <td><b>{el.order_number}</b>
                    <Link to={`/invoice?id=${el.id}`} className="ml-1">
                        View Details
                    </Link>
                </td>
                <td>{el.created_at.substring(0, 10)}</td>
                <td>{el.order_status}</td>
                <td className="text-left">${el.total_price}</td>
            </tr>
        })
        return (
            <div className="col-md-18 aside">
                <h1 className="mb-2" id="manage_table">Order History</h1>
                <div className="d-sm-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex align-items-center">
                        <span>Show </span> <select className="custom-select mx-1" id="" name="itemsCountPerPage" onChange={this.onChangeItemCount} value={this.state._itemsCountPerPage}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>

                        </select> <span> Entries</span>

                    </div>

                    <div className="d-flex mt-1 mt-sm-0  align-items-center ww-50">
                        <input type="text" className="form-control rounded" name="search_term" value={this.state._search_term} placeholder="Search for ..." onChange={this.changeSearchTerm} />
                    </div>
                </div>
                {
                    this.state.show_spinner ?
                        <Spinner />
                        :
                        <>
                            <div className="table-responsive" >
                                <table className="table table-bordered table-striped table-order-history">
                                    <thead>
                                        <tr>
                                            <th scope="col">Order Number</th>
                                            <th scope="col">Order Date </th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.orders.length > 0
                                                ?
                                                order_history_mapping
                                                :
                                                <tr>
                                                    <td colSpan="5">
                                                        <p className="text-center p-3"> There are no records to display.</p>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {
                                this.state.orders.length > 0
                                &&
                                <Pagination
                                    activePage={this.state._activePage}
                                    itemsCountPerPage={this.state._itemsCountPerPage}
                                    totalItemsCount={this.state._totalItemsCount}
                                    pageRangeDisplayed={this.state._pageRangeDisplayed}
                                    onChange={this.handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            }
                            {/* <div className="text-right mt-2">
                                <a href="#" className="btn btn--alt">Clear History</a>
                            </div> */}
                        </>
                }
            </div>
        )
    }
}
