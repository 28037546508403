import React, { Component } from 'react'

export default class QuickViewButton extends Component {

    render() {
        return (
            <>
                <span className="circle-label-qview js-prd-quickview prd-hide-mobile"
                    onClick={
                        () => this.props.toggleQuickViewModal(this.props.product_id, this.props.product_data)
                    }>
                    <i className="icon-eye" /><span>QUICK VIEW
                    </span>
                </span>
            </>
        )
    }
}
