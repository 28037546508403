import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";

class StoreProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profile_store_images: [],
            profile_contacts: [],

            profile_description: "",

            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,

            grid_type: "prd-horgrid",
            activeSlide: 0,
        }
    }


  


    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            pauseOnHover: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
        };


        let choose_us_sliders_mapping = this.props.profile_store_images.map((el, index) => {
            return <div key={index}>
                <Link to={el.redirect_url || ""}>

                    <div className="store-slider-background" style={{
                        backgroundImage: `url('${this.state.app_site_storage_path}${el.image}')`
                    }}>
                        <img src="/images/skins/electronics/slider/slide-electronics-01-m-half.png"
                            alt={"name"}
                            className="" />
                    </div>
                </Link>
            </div>
        })

        let profile_featured_categories_mapping = this.props.profile_featured_categories.map((el, index) => {
            return <li key={el.id} className={""}> {
                index !== 0
                &&
                <span className="categories-line">|</span>
            }
                {el.name} </li>
        })

        let contacts_mapping = this.props.profile_contacts.map((el, index) => {
            return <li key={el.id} className={""}>
                <strong className="text-capitalize">{el.contact_to}</strong> : {el.value} </li>
        })

        return (
            <div className="container">

                <div>
                    <h1 className=" mt-4">Store Information</h1>
                    <div className='row col'>
                        <div className="col-md-9">
                            <Slider {...settings}>
                                {
                                    choose_us_sliders_mapping
                                }
                            </Slider>
                        </div>
                        <div className="col-md-9">
                            <h3 className=" mb-0">Main Category </h3>
                            <ul className="row list-unstyled col">
                                {profile_featured_categories_mapping}
                            </ul>
                            <h3 className=" mt-2 mb-0">Contacts</h3>
                            <ul className=" list-unstyled">
                                {contacts_mapping}
                            </ul>
                        </div>

                    </div>
                </div>
                <p className="mt-4">
                    {this.props.profile_description}
                </p>

            </div >
        )
    }
}

export default StoreProfile
