import React, { Component } from 'react'

import axiosConfig from '../../axiosConfig';
import { Redirect } from 'react-router-dom';

import { withRouter } from 'react-router-dom';


import { connect } from 'react-redux'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist, replaceWishlist, replaceCart } from '../../actions'
import Spinner from '../../Spinner';
import { scrollToTop } from '../../helper_funcitons';


class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect_login: false,
        }

        // localStorage.removeItem('userToken')
        // localStorage.removeItem('userdata')

        // return (window.location = '/login')
    }

    componentDidMount() {

        scrollToTop(0,0)

        let url = "/api/logout"
        let data = {}

        this.props.replaceWishlist([]);
        localStorage.removeItem("userToken")
        localStorage.removeItem("appState")

        this.props.updateParentUserDataState({ isLoggedIn: false })
        axiosConfig.post(url, data)
            .then(res => {

                // if (res.data.success) {
                // }
                // this.setState({
                //     redirect_login: true,
                // })

            })
        this.setState({
            redirect_login:true,
        })
        // window.location = "/login"
    }

    render() {
        axiosConfig.defaults.headers.common = {
            'Authorization': 'Bearer ' + localStorage.userToken
        };

        if (this.state.redirect_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }}/>
        }

        return (
            <div className="">
                {/* <h3>Logging out</h3> */}
                <Spinner/>
            </div>
        )

    }
}



const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});

const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist, replaceCart, replaceWishlist,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(Logout));