import React from 'react';
import { withRouter } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
// import { Range, getTrackBackground } from 'react-range';

import axiosConfig from '../../axiosConfig';
import Spinner from '../../Spinner';
import { getPosition, getUrlParameters, scrollToTop } from '../../helper_funcitons';
import ProductVertical from '../ProductVertical'
import Progressbar from '../../Progressbar';
import QuickViewModal from '../../QuickViewModal';
import ProductsRecommended from '../../ProductsRecommended';
import HelmetComponent from '../../HelmetComponent';
import StoreHeader from '../Store/StoreHeader';
class ProductListing extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            selected_categories: [],
            selected_category_id: "",
            title: "",
            selected_sort: "price",
            page: 1,
            per_page: 12,
            show_spinner: true,
            show_load_more_spinner: false,
            show_products_loading_spinner: true,
            category_products: [],
            total_products: "",

            grid_type: "prd-horgrid",

            // modal
            show_quickview_modal: false,
            product_id: "",

            // sidebar-collapse
            sidebar_collapse: {
                categories: true,
                storefronts: true,
                colors: true,
                size: true,
                brands: true,
                price: true,
                quantity: true,
            },

            // filter
            search_text: "",
            colors: [],
            selected_colors: [],
            sizes: [],
            selected_sizes: [],
            brands: [],
            selected_brands: [],

            // react-range
            values: [],
            STEP: 1,
            MIN: 0,
            MAX: 999999,



            min_range: "",
            max_range: "",

            quantities: [],
            min_qty: "",
            max_qty: "",
            p_qty_changed: false,


            selected_root_category: [],
            storefronts: [],
            selected_storefront_id: "",


            // store header
            selected_tab: "",
            quick_view_product: {}

        }

        this.getCategories = this.getCategories.bind(this)
        this.onchangeSelectedCategories = this.onchangeSelectedCategories.bind(this)
        this.onchangeSelectedStorefront = this.onchangeSelectedStorefront.bind(this)
        this.getCategoryData = this.getCategoryData.bind(this)
        this.onChangeDropDown = this.onChangeDropDown.bind(this)
        this.getProductByCategory = this.getProductByCategory.bind(this)
        this.loadMoreProducts = this.loadMoreProducts.bind(this)
        this.toggleQuickViewModal = this.toggleQuickViewModal.bind(this)
        this.toggleSidebarBlock = this.toggleSidebarBlock.bind(this)
        this.onChangeFilter = this.onChangeFilter.bind(this)
        this.setStateFromUrl = this.setStateFromUrl.bind(this)
        this.getElement = this.getElement.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    toggleQuickViewModal(id, quick_view_product = {}) {
        this.setState({
            product_id: id,
            quick_view_product

        }, () => {
            this.setState((prev) => ({
                show_quickview_modal: !prev.show_quickview_modal
            }))
        })
    }

    functionToMakeSubTree = (parent, original_arr) => {

        let child = original_arr.filter(category => category.parent_id === parent.id)

        if (child.length > 0) {
            // child = child.map(category => ({ ...category }))
            return {
                ...parent,
                child: child.map(subCategory => this.functionToMakeSubTree(subCategory, original_arr))
            }
        }
        else {
            return {
                ...parent
            }
        }

    }

    getCategories() {
        // this.setState({
        //     show_spinner: true,
        // })

        let url = "/api/category?storefront=true"

        if (this.state.selected_tab === "products") {
            console.log("only store categories. ")
            url = "/api/category-store/" + this.state.selected_storefront_id;
        }

        axiosConfig.get(url)
            .then(res => {
                // this.setState({
                //     show_spinner: false
                // })
                if (res.data.success) {

                    let arr = [...res.data.data];

                    let result = arr.filter(category => category.parent_id === null)
                        .map(parentCategory => this.functionToMakeSubTree(parentCategory, arr))

                    this.setState({
                        categories: result,
                        storefronts: res.data.storefronts || []
                    }, () => {
                    })

                }
            })
            .catch(err => {
                this.setState({
                    // show_spinner: false
                })
            })
    }

    resetJqueries() {
        setTimeout(() => {
            if (document.getElementById("add_to_cart_popup")) {
                document.getElementById("add_to_cart_popup").remove()
            } else {
                // if(this.props.history.action == "POP"){
                if (!window.popup_checkout_called) {
                    var script = document.createElement('script');
                    script.id = "add_to_cart_popup"
                    script.defer = true
                    script.text = `
                
                            window.THEME = {};
                            function checkOut() {
                            THEME.checkOutModal = {
                              defaults: {
                                popup: '.js-popupAddToCart',
                                popupAlt: ['.js-stickyAddToCart', '.js-popupSelectOptions'],
                                button: '.js-prd-addtocart',
                                closePopup: '.js-popupAddToCart-close',
                                title: '.popup-addedtocart_title',
                                imageWrap: '.popup-addedtocart_image',
                                image: '.popup-addedtocart_image > img, .popup-addedtocart_image > span > img',
                                error: '.popup-addedtocart_error_message'
                              },
                              init: function init(options) {
                                $.extend(this.defaults, options);
                                var that = this,
                                  $popup = $(that.defaults.popup);
                                if ($popup.length) {
                                  that._handlers();
                                }
                              },
                              reinit: function reinit() {
                                if (!$(this.defaults.popup).hasClass('closed')) {
                                  this._setHeigth();
                                }
                              },
                              setData: function setData(data) {
                                var that = this,
                                  $popup = $(this.defaults.popup);
                                $popup.removeClass('has-error');
                                $(this.defaults.title, $popup).empty().html('<b>' + data.name + '</b>');
                                $(this.defaults.title, $popup).attr('href', data.url);
                                $(this.defaults.imageWrap, $popup).attr('href', data.url);
                                $(this.defaults.image, $popup).fadeTo(0, 0).attr('src', '');
                                if (data.path) {
                                  if (data.path.indexOf('no-image') == -1) {
                                    $(this.defaults.image, $popup).attr('src', data.path);
                                  } else {
                                    $(this.defaults.image, $popup).attr('src', data.main_image);
                                  }
                                }
                                if (data.aspect_ratio > 0) {
                                  $(this.defaults.imageWrap, $popup).find('.image-container').css({
                                    'padding-bottom': 1 / data.aspect_ratio * 100 + "%"
                                  });
                                }
                                $(this.defaults.imageWrap, $popup).imagesLoaded(function () {
                                  anime({
                                    targets: that.defaults.image,
                                    opacity: 1,
                                    duration: 1000
                                  });
                                });
                                $(this.defaults.image, $popup).parent().attr('href', data.url);
                                $(this.defaults.error, $popup).empty().html(data.error_message);
                                if (!$popup.hasClass('closed')) {
                                  this.open();
                                }
                              },
                              setError: function setError(error) {
                                var $popup = $(this.defaults.popup);
                                $popup.addClass('has-error');
                                $(this.defaults.error, $popup).empty().html(error);
                                if (!$popup.hasClass('closed')) {
                                  this.open();
                                }
                              },
                              ifOpened: function ifOpened() {
                                return $(this.defaults.popup).hasClass('closed') ? false : true;
                              },
                              _correctHeight: function _correctHeight() {
                                var that = this,
                                  h = $(that.defaults.popup).find('.container').outerHeight();
                                anime({
                                  targets: that.defaults.popup,
                                  height: {
                                    value: h + 'px'
                                  },
                                  duration: 100,
                                  easing: 'linear'
                                });
                              },
                              _setHeigth: function _setHeigth() {
                                var $popup = $(this.defaults.popup),
                                  popupAlt = this.defaults.popupAlt,
                                  h = $popup.find('.container').outerHeight();
                                for (var i = 0; i < popupAlt.length; ++i) {
                                  if (!$(popupAlt[i]).hasClass('closed') && $(popupAlt[i]).length) {
                                    h += $(popupAlt[i]).find('.container').outerHeight();
                                  }
                                }
                                $popup.removeClass('closed');
                                $('.js-pn').css({
                                  'margin-bottom': h + 20 + 'px'
                                });
                              },
                              _handlers: function _handlers() {
                                var that = this,
                                  $popup = $(that.defaults.popup);
                                $(document).on('click', that.defaults.button, function (e) {
                                  var product_data = $(this).data('product');
                                  if (!$popup.hasClass('closed')) {
                                    that.close();
                                    setTimeout(function () {
                                      that.setData(product_data);
                                      that.open();
                                    }, 500);
                                  } else {
                                    that.setData(product_data);
                                    that.open();
                                  }
                                  e.preventDefault();
                                });
                                $(document).on('click', that.defaults.closePopup, function (e) {
                                  that.close();
                                  e.preventDefault();
                                });
                              },
                              open: function open() {
                                var that = this,
                                  $popup = $(that.defaults.popup),
                                  $qty = $popup.find('.qty-input'),
                                  h = $popup.find('.container').outerHeight(),
                                  duration = 300;
                                $popup.removeClass('closed');
                                $qty.val($qty.data('min'));
                                that._setHeigth();
                                anime({
                                  targets: that.defaults.popup,
                                  easing: 'linear',
                                  duration: duration,
                                  delay: 500,
                                  height: {
                                    value: h + 'px'
                                  }
                                });
                                if ($popup.attr('data-close')) {
                                  that.timer = setTimeout(function () {
                                    that.close();
                                  }, $popup.attr('data-close'));
                                }
                              },
                              close: function close() {
                                var that = this,
                                  $popup = $(that.defaults.popup),
                                  popupAlt = $(that.defaults.popupAlt),
                                  duration = 300,
                                  h = 0;
                                for (var i = 0; i < popupAlt.length; ++i) {
                                  if (!$(popupAlt[i]).hasClass('closed') && $(popupAlt[i]).length) {
                                    h += $(popupAlt[i]).find('.container').outerHeight();
                                  }
                                }
                                $popup.addClass('closed');
                                $popup.data('variant-id', '');
                                $('.js-pn').css({
                                  'margin-bottom': h + 20 + 'px'
                                });
                                clearTimeout(that.timer);
                                anime({
                                  targets: that.defaults.popup,
                                  easing: 'linear',
                                  duration: duration,
                                  height: {
                                    value: '0px'
                                  },
                                  complete: function complete() {
                                    $(that.defaults.imageWrap, $popup).find('.image-container').css({
                                      'padding-bottom': ''
                                    });
                                  }
                                });
                              }
                            };
                            THEME.checkOutModal.init();
                          }
                          setTimeout(()=>{
                              checkOut();
                          },500)
                        `
                    document.getElementsByTagName('body')[0].appendChild(script);
                }
            }
        }, 500)


    }

    async setStateFromUrl(url_obj, fetch_category_data = false, fetch_store_data = false) {
        let { category_id, sort, page, per_page, q, colors, sizes, brands, p_range, p_qty, store_id, tab } = url_obj

        await this.setState({
            selected_tab: tab || (store_id ? "products" : ""),
            selected_category_id: category_id || "",
            selected_storefront_id: store_id || "",
            page: page || 1,
            per_page: per_page || 12,
            selected_sort: sort || "price",
            search_text: q || "",
            selected_colors: colors ? colors.split(",").map(el => parseInt(el)) : [],
            selected_sizes: sizes ? sizes.split(",").map(el => parseInt(el)) : [],
            selected_brands: brands ? brands.split(",").map(el => parseInt(el)) : [],
            values: p_range ? p_range.split(",").map((el, index) => {
                if (index === 0) {
                    if (el < 0) {
                        return 0;
                    } else {
                        return parseInt(el) || ""
                    }
                }
                return parseInt(el) || ""
            }) : [],

            quantities: p_qty ? p_qty.split(",").map((el, index) => {
                if (index === 0) {
                    if (el < 0) {
                        return 0;
                    } else {
                        return parseInt(el) || ""
                    }
                }
                return parseInt(el) || ""
            }) : [],

        }, async () => {

            if (this.state.values.length > 0) {
                this.setState({
                    min_range: this.state.values[0] || "",
                    max_range: this.state.values[1] || ""

                })
            }

            if (this.state.quantities.length > 0) {
                this.setState({
                    min_qty: this.state.quantities[0] || "",
                    max_qty: this.state.quantities[1] || ""

                })
            }

            // await this.getProductByCategory(false, false);
            let scroll_to_top = this.state.loaded_once ? true : false
            await this.getProductByCategory(false, true, false, scroll_to_top);

            // this.setUrlParameters()

            if (fetch_category_data) {
                if (this.state.categories.length > 0) {
                    this.getElement(this.state.categories)
                } else {
                    await this.getCategoryData();
                }
            }
            if (fetch_store_data) {
                await this.getStorefrontData();
            }
        })
    }

    getElement(arr) {
        arr.some(el => {
            console.log("____")
            if (el.id === this.state.selected_category_id) {
                this.setState({
                    selected_category_title: el.name
                })
                return true;
            }
            else {
                if (el.child) {
                    this.getElement(el.child)
                }
            }
            return false;
        })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.location.search !== this.props.location.search) {

            let old_url_parameters = getUrlParameters(prevProps.location)
            let new_url_parameters = getUrlParameters(this.props.location)

            console.log({ new_url_parameters })


            if (old_url_parameters && new_url_parameters) {

                let fetch_category_data = false;
                if (old_url_parameters.category_id !== new_url_parameters.category_id) {
                    fetch_category_data = true;
                }

                let fetch_store_data = false;
                if (old_url_parameters.store_id === new_url_parameters.store_id) {
                    fetch_store_data = false;
                }

                console.log({ old_url_parameters })
                console.log({ new_url_parameters })

                if (old_url_parameters.tab !== new_url_parameters.tab) {
                    this.setState({
                        selected_tab: new_url_parameters.tab || ""
                    })
                }


                if (
                    (old_url_parameters.category_id !== new_url_parameters.category_id) ||  // when changing categories from header
                    (old_url_parameters.q !== new_url_parameters.q)
                    // (old_url_parameters.store_id != new_url_parameters.store_id)
                ) {
                    this.setStateFromUrl(new_url_parameters, fetch_category_data, fetch_store_data)
                }
            }
        }
        // setTimeout(() => {
        // let range_draggables = document.getElementsByClassName("range_draggable");
        // let temp = [...range_draggables];
        // if (temp.length == 2) {
        //     if (temp.every(el => el.dataset.isdragged == "false")) {
        //         this.getProductByCategory(false, false)
        //     } else {
        //     }
        // }
        // }, 400)
    }

    async componentDidMount() {

        scrollToTop(0, 0)

        let url_parameters = getUrlParameters(this.props.location)

        if (url_parameters) {
            await this.setStateFromUrl(url_parameters, true, true)
        }

        await this.getCategories();

    }

    async getCategoryData() {
        if (this.state.selected_category_id) {
            let url = "/api/category/edit/" + this.state.selected_category_id
            await axiosConfig.get(url)
                .then(async (res) => {
                    if (res.data.success) {
                        await this.setState({
                            selected_category_title: res?.data?.data?.name
                        })
                    }
                })
        }
    }

    async getStorefrontData() {
        if (this.state.selected_storefront_id) {
            let url = "/api/storefront/edit/" + this.state.selected_storefront_id
            await axiosConfig.get(url)
                .then(async (res) => {
                    if (res.data.success) {
                        await this.setState({
                            selected_storefront_title: res?.data?.data?.company_name
                        })
                    }
                })
        }
    }

    mapCategories(arr) {
        return arr.map(el => {
            return <li key={el.id}>
                <input type="checkbox"
                    id={`category-${el.id}`}
                    checked={this.state.selected_category_id === el.id}
                    onChange={() => { }}
                    onClick={() => this.onchangeSelectedCategories(el)}
                />&nbsp;<label htmlFor={`category-${el.id}`} style={{
                    cursor: "Pointer"
                }}>
                    {el.name}
                </label>
                {
                    // el.child
                    (el.child && this.showChild(el))
                    &&
                    <>
                        <div className="toggle-category js-toggle-category"><span><i className="icon-angle-down" /></span></div>
                        <ul className="category-list category-list">
                            {this.mapCategories(el.child)}
                        </ul>
                    </>
                }
            </li >
        })
    }

    showChild(el) {
        if (!this.state.selected_category_id) {
            return false
        } else {
            return this.checkId(el)
        }
    }

    checkId(el) {
        if (el.id === this.state.selected_category_id || el.parent_id === this.state.selected_category_id) {
            return true;
        } else if (el.child) {
            return el.child.some(child => {
                return this.checkId(child)
            })
        }
    }




    onchangeSelectedCategories(category) {

        this.setState({
            // selected_categories: temp
            // selected_storefront_id: "",
            selected_category_id: category.id,
            selected_category_title: category.name

        }, () => {
            this.setUrlParameters();
            // this.getProductByCategory(false);

            // this.getCategoriesToBeMapped();

        })
    }

    onchangeSelectedStorefront(storefront) {

        this.setState({
            // selected_category_id: "",
            selected_storefront_id: storefront.company_id,
            selected_storefront_title: storefront.company_name,

        }, () => {
            this.setUrlParameters();
            this.getProductByCategory(false);
            // this.getCategoriesToBeMapped();
        })
    }

    async setUrlParameters() {

        // update only change field and keep others same

        let changes = "?";
        if (this.state.selected_category_id) {
            changes += ((changes.length) > 1 ? "&" : "") + "category_id=" + this.state.selected_category_id;
        }
        if (this.state.selected_storefront_id) {
            changes += ((changes.length) > 1 ? "&" : "") + "store_id=" + this.state.selected_storefront_id;
        }
        // if (this.state.selected_sort) {
        //     changes += ((changes.length) > 1 ? "&" : "") + "sort=" + this.state.selected_sort;
        // }
        // if (this.state.page) {
        //     changes += ((changes.length) > 1 ? "&" : "") + "page=" + this.state.page;
        // }

        if (this.state.per_page && this.state.per_page_changed_once) {
            changes += ((changes.length) > 1 ? "&" : "") + "per_page=" + this.state.per_page;
        }
        if (this.state.selected_colors.length > 0) {
            changes += ((changes.length) > 1 ? "&" : "") + "colors=" + this.state.selected_colors;
        }

        if (this.state.selected_sizes.length > 0) {
            changes += ((changes.length) > 1 ? "&" : "") + "sizes=" + this.state.selected_sizes;
        }
        if (this.state.selected_brands.length > 0) {
            changes += ((changes.length) > 1 ? "&" : "") + "brands=" + this.state.selected_brands;
        }

        // if (this.state.p_range_changed || (this.state.values.length > 0)) {
        if ((this.state.values.length > 0)) {

            if (this.state.values.length > 0) {
                changes += ((changes.length) > 1 ? "&" : "") + "p_range=" + this.state.values;
            }
        }

        // if (this.state.p_qty_changed || (this.state.quantities.length > 0)) {
        if ((this.state.quantities.length > 0)) {
            if (this.state.quantities.length > 0) {
                changes += ((changes.length) > 1 ? "&" : "") + "p_qty=" + this.state.quantities;
            }
        }

        if (this.state.selected_tab) {
            changes += "&tab=" + this.state.selected_tab
        }

        let old_temp = getUrlParameters(this.props.location);

        if (old_temp.q || old_temp.q === "") {
            changes += ((changes.length) > 1 ? "&" : "") + "q=" + old_temp.q;
        }

        let temp_changes = getUrlParameters({ search: changes })


        let temp = { ...old_temp, ...temp_changes };

        let str = "?";
        Object.entries(temp).forEach(([key, value], index) => {
            if (temp[key] || temp_changes[key]) {
                str += (index === 0 ? "" : "&") + key + "=" + (temp_changes[key] === undefined ? temp[key] : temp_changes[key])
            }
        });

        // this.props.history.push(Object.entries(old_temp).length > 0 ? str : changes)
        this.props.history.push(changes)


    }

    async getProductByCategory(show_spinner = true, show_products_loading_spinner = true, show_load_more_spinner = false, scroll_to_top = true) {

        this.setState({
            show_spinner: show_spinner,
            show_load_more_spinner: show_load_more_spinner,
            show_products_loading_spinner: show_products_loading_spinner,
            loaded_once: true,
        })
        let url = "/api/product/categories?category_id=" + (this.state.selected_category_id || "") + "&sort=" + (this.state.selected_sort || "") + "&page=" + (this.state.page || "") + "&per_page=" + this.state.per_page + "&getcolors=true&getsizes=true&getbrands=true"
            + "&q=" + (this.state.search_text || "") + "&colors=" + (this.state.selected_colors || "") + "&sizes=" + (this.state.selected_sizes || "") + "&brands=" + (this.state.selected_brands || "") + "&p_range=" + (this.state.values || "")
            + "&p_qty=" + (this.state.quantities || "")
            + "&store_id=" + this.state.selected_storefront_id;

        await axiosConfig.get(url)
            .then((res) => {
                this.setState({
                    show_spinner: false,
                    show_load_more_spinner: false,
                    show_products_loading_spinner: false,
                })

                if (res.data.success) {

                    if (scroll_to_top) {
                        let elem = document.getElementById("category_title");
                        if (elem) {
                            let position = getPosition(elem)
                            scrollToTop(position)
                        }
                    }

                    // let url_params = getUrlParameters(this.props.location)

                    // let max_price = Math.ceil(res.data.max_product_price)
                    // let temp = "1";
                    // for (let i = 0; i < max_price.toString().length; i++) {
                    //     temp += "0";
                    // }

                    this.setState({
                        category_products: res.data.data.data,
                        total_products: res.data.data.total,
                        colors: res.data.colors || [],
                        sizes: res.data.sizes || [],
                        brands: res.data.brands || [],

                    }, () => {
                        this.resetJqueries()
                    })

                    // if (!url_params.p_range) {
                    //     this.setState({
                    //         // values: [0, (parseInt(Math.ceil(res.data.max_product_price) / 2))]
                    //         values: [0, parseInt(temp)]
                    //     })
                    // }

                    // if (url_params.p_range) {
                    //     let old = [...this.state.values]
                    //     if (url_params.p_range.split(",")[0] < this.state.MIN) {
                    //         old[0] = 0;
                    //     }

                    //     if (url_params.p_range.split(",")[1] > this.state.MAX) {
                    //         old[1] = parseInt(temp);
                    //     }
                    //     this.setState({
                    //         values: old
                    //     }, () => {
                    //         this.setUrlParameters();
                    //     })
                    // }

                    if (document.getElementById("selected_sort")) {
                        document.getElementById("selected_sort").value = this.state.selected_sort
                    }
                    if (document.getElementById("per_page")) {
                        document.getElementById("per_page").value = this.state.per_page
                    }
                    // let arr = [...res.data.data];

                    // let result = arr.filter(category => category.parent_id === null)
                    //     .map(parentCategory => this.functionToMakeSubTree(parentCategory, arr))

                    // this.setState({
                    //     categories: result
                    // })

                }
            })
            .catch(err => {
                this.setState({
                    show_spinner: false,
                    show_load_more_spinner: false,
                })
            })
    }

    onChangeDropDown(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {

            if (e.target.name === "per_page") {
                this.setState({
                    per_page_changed_once: true,
                }, () => {
                    this.setUrlParameters()
                    this.getProductByCategory(false);
                })
            } else {
                this.setUrlParameters()
                this.getProductByCategory(false);
            }
        })

    }

    loadMoreProducts() {
        this.setState((prev) => ({
            per_page: parseInt(prev.per_page) + 12,
            per_page_changed_once: true,
        }), () => {
            this.setUrlParameters();
            this.getProductByCategory(false, false, true, false)
        })
    }

    toggleSidebarBlock(type) {

        let temp = { ...this.state.sidebar_collapse }

        temp[type] = !this.state.sidebar_collapse[type]
        this.setState({
            sidebar_collapse: temp
        })
    }

    // async getAttributes(){

    // }

    onChangeFilter(id, state_field) {
        let temp = [...this.state[state_field]]

        if (temp.includes(id)) {
            // remove
            temp = temp.filter(el => el !== id);
        } else {
            temp.push(id)
        }
        this.setState({
            [state_field]: temp.length > 0 ? [temp.pop()] : []
        }, () => {
            this.getProductByCategory(false, true)
            this.setUrlParameters()
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        if (this.state.show_spinner) {
            return <Spinner />
        }

        let category_products_mapping = this.state.category_products.map(el => {
            return <ProductVertical
                key={el.id}
                product_data={el}
                toggleQuickViewModal={this.toggleQuickViewModal}
            />

        })

        let arr = [12, 36, 100]

        arr.some((el, index) => {

            if (el > this.state.per_page) {
                if (!arr.includes(parseInt(this.state.per_page))) {

                    arr.splice(index, 0, this.state.per_page)
                }
            }
            return true;
        })


        let per_page_view_options = arr.map((el, index) => {
            return <option value={el} key={index}>{el}</option>
        })

        if (document.getElementById("per_page") && document.getElementById("per_page").value !== this.state.per_page) {
            document.getElementById("per_page").value = this.state.per_page
        }


        let categories_to_be_mapped = this.state.categories;

        if (this.state.selected_category_id) {

            this.state.categories.some(el => {
                if (el.id === this.state.selected_category_id) {
                    categories_to_be_mapped = [el]
                    return true;
                } else {
                    if (el.child) {
                        el.child.some(child => {
                            if (child.id === this.state.selected_category_id) {
                                categories_to_be_mapped = [el]
                                return true;
                            } else {
                                if (child.child) {
                                    child.child.some(third => {
                                        if (third.id === this.state.selected_category_id) {
                                            categories_to_be_mapped = [el]
                                        }
                                        return true;
                                    })
                                }
                            }
                            return true;
                        })
                    }
                }
                return true;
            })
        }

        let categories_mapping = this.mapCategories(categories_to_be_mapped);

        // let store_front_mapping = this.state.storefronts.map(el => {
        //     if (this.state.selected_storefront_id) {
        //         if (this.state.selected_storefront_id == el.company_id) {
        //             return <li key={el.id}>
        //                 <input type="checkbox"
        //                     id={`storefront-${el.company_id}`}
        //                     checked={this.state.selected_storefront_id == el.company_id}
        //                     onClick={() => this.onchangeSelectedStorefront(el)}
        //                 />&nbsp;<label htmlFor={`storefront-${el.company_id}`}
        //                     style={{
        //                         cursor: "Pointer"
        //                     }}>
        //                     {el.company_name}
        //                 </label>
        //             </li >
        //         } else {
        //             return <></>
        //         }

        //     } else {
        //         return <li key={el.id}>
        //             <input type="checkbox"
        //                 id={`storefront-${el.company_id}`}
        //                 checked={this.state.selected_storefront_id == el.company_id}
        //                 onClick={() => this.onchangeSelectedStorefront(el)}
        //             />&nbsp;<label htmlFor={`storefront-${el.company_id}`} style={{
        //                 cursor: "Pointer"
        //             }}>
        //                 {el.company_name}
        //             </label>
        //         </li >
        //     }

        // })


        let colors_mapping = this.state.colors.map(el => {
            return <li key={el.id} className={`${this.state.selected_colors.includes(el.id) ? "active" : ""}`}>
                <a
                    // href="#"
                    key={el.id}
                    data-tooltip={el.name}
                    title={el.name}
                    onClick={() => this.onChangeFilter(el.id, "selected_colors")}
                >
                    <span className="value">
                        {/* <img src="/images/colorswatch/color-red.png" alt="" /> */}
                        <div style={{
                            backgroundColor: el.hex_value,
                            height: "18px",
                            width: "18px"
                        }}>

                        </div>
                    </span>
                    <span className="colorname" style={{
                        cursor: "Pointer"
                    }}>
                        {el.name}
                    </span>
                </a>
            </li>
        })

        let sizes_mapping = this.state.sizes.map(el => {
            return <li
                key={el.id}
                className={`${this.state.selected_sizes.includes(el.id) ? "active" : ""}`}
                data-value={el.name}
                onClick={() => this.onChangeFilter(el.id, "selected_sizes")}
            >
                <a style={{
                    cursor: "Pointer"
                }}>
                    {el.name}
                </a>
            </li>
        })

        let brands_mapping = this.state.brands.map(el => {
            return <li key={el.id} className={`${this.state.selected_brands.includes(el.id) ? "active" : ""}`}
                onClick={() => this.onChangeFilter(el.id, "selected_brands")}
            >
                <a style={{
                    cursor: "Pointer"
                }}
                // href="#"
                >{el.name}</a>
            </li>
        })

        let store_products_on = false;

        if (this.state.selected_tab === "products") {
            store_products_on = true;
        }

        return (
            <div id="product-listing">

                <HelmetComponent
                    // title={this.state.title}
                    title={"Product Listing"}
                />

                <div className="holder mt-0">
                    {

                        store_products_on
                        &&
                        <StoreHeader
                            selected_storefront_id={this.state.selected_storefront_id}
                            selected_tab={this.state.selected_tab}
                        />
                    }

                    <div className="container mt-5">

                        <div className="page-title text-center" id="category_title">
                            <h1>{this.state.selected_storefront_title} {(this.state.selected_storefront_title && this.state.selected_category_title) && ","} {this.state.selected_category_title}</h1>
                        </div>
                        {/* /Page Title */}
                        {/* Filter Row */}
                        <div className="filter-row">
                            <div className="row">
                                <div className="items-count">{this.state.total_products} item(s)</div>
                                {/* <div className="select-wrap d-none d-md-flex">
                                    <div className="select-label">SORT:</div>
                                    <div className="select-wrapper select-wrapper-xxs">
                                        <select className="form-control input-sm" id="selected_sort" name="selected_sort" onChange={this.onChangeDropDown}>
                                            <option value="featured">Featured</option>
                                            <option value="rating">Rating</option>
                                            <option value="price">Price</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="select-wrap d-none d-md-flex">
                                    <div className="select-label">VIEW:</div>
                                    <div className="select-wrapper select-wrapper-xxs">
                                        <select className="form-control input-sm" name="per_page" id="per_page" onChange={this.onChangeDropDown}>
                                            {
                                                per_page_view_options
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="viewmode-wrap">
                                    <div className="view-mode">
                                        {/* <span className={`js-horview d-none d-lg-inline-flex ${this.state.grid_type == "prd-horgrid "? "active"  : ""}`} */}
                                        <span className={`js-horview ${this.state.grid_type === "prd-horgrid" ? "active" : ""}`}
                                            onClick={() => {
                                                this.setState({
                                                    grid_type: "prd-horgrid"
                                                })
                                            }}
                                        ><i className="icon-grid" /></span>
                                        <span className={`js-gridview ${this.state.grid_type === "prd-grid" ? "active" : ""}`}
                                            onClick={() => {
                                                this.setState({
                                                    grid_type: "prd-grid"
                                                })
                                            }}
                                        ><i className="icon-grid" /></span>
                                        <span className={`js-listview ${this.state.grid_type === "prd-listview" ? "active" : ""}`}
                                            onClick={() => {
                                                this.setState({
                                                    grid_type: "prd-listview"
                                                })
                                            }}
                                        ><i className="icon-list" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Filter Row */}
                        <div className="row">
                            {/* Left column */}
                            <div className="col-lg-4 aside aside--left filter-col filter-mobile-col filter-col--sticky js-filter-col filter-col--opened-desktop filter-col--init" data-grid-tab-content style={{ left: '-680px' }}>
                                <div className="filter-col-content filter-mobile-content">


                                    <div className="sidebar-block">
                                        <div className="sidebar-block_title">
                                            <span>Current selection</span>
                                        </div>
                                        <div className="sidebar-block_content pb-0">
                                            <div className="selected-filters-wrap mb-0">
                                                <ul className="selected-filters">
                                                    {
                                                        (
                                                            !this.state.selected_category_id &&
                                                            (store_products_on || !this.state.selected_storefront_id) &&
                                                            this.state.selected_colors?.length === 0 &&
                                                            this.state.selected_brands?.length === 0 &&
                                                            this.state.selected_sizes?.length === 0
                                                        )
                                                        &&
                                                        <p>0 Selected</p>
                                                    }
                                                    {
                                                        (this.state.selected_category_id)
                                                        &&
                                                        <li onClick={
                                                            () => {
                                                                this.setState({
                                                                    selected_category_title: "",
                                                                    selected_category_id: "",
                                                                }, () => {
                                                                    this.setUrlParameters();
                                                                    this.getProductByCategory(false);
                                                                })
                                                            }}>
                                                            <a>
                                                                {this.state.selected_category_title}
                                                            </a>
                                                        </li>
                                                    }


                                                    {
                                                        (!store_products_on && this.state.selected_storefront_id)
                                                        &&
                                                        <li onClick={
                                                            () => {
                                                                this.setState({
                                                                    selected_storefront_title: "",
                                                                    selected_storefront_id: "",
                                                                }, () => {
                                                                    this.setUrlParameters();
                                                                    this.getProductByCategory(false);
                                                                })
                                                            }}>
                                                            <a>
                                                                {this.state.selected_storefront_title}
                                                            </a>
                                                        </li>
                                                    }




                                                    {
                                                        this.state.selected_colors.length > 0
                                                        &&
                                                        <li onClick={
                                                            () => {
                                                                let temp = [...this.state.selected_colors];
                                                                temp.pop();

                                                                this.setState({
                                                                    selected_colors: temp
                                                                }, () => {
                                                                    this.setUrlParameters();
                                                                    this.getProductByCategory(false);
                                                                })
                                                            }}>
                                                            <a>
                                                                {this.state.colors.find(el => el.id === this.state.selected_colors[0])?.name}
                                                            </a>
                                                        </li>
                                                    }

                                                    {
                                                        this.state.selected_sizes.length > 0
                                                        &&
                                                        <li onClick={
                                                            () => {
                                                                let temp = [...this.state.selected_sizes];
                                                                temp.pop();

                                                                this.setState({
                                                                    selected_sizes: temp
                                                                }, () => {
                                                                    this.setUrlParameters();
                                                                    this.getProductByCategory(false);
                                                                })
                                                            }}>
                                                            <a>
                                                                {this.state.sizes.find(el => el.id === this.state.selected_sizes[0])?.name}
                                                            </a>
                                                        </li>
                                                    }

                                                    {
                                                        this.state.selected_brands.length > 0
                                                        &&
                                                        <li onClick={
                                                            () => {
                                                                let temp = [...this.state.selected_brands];
                                                                temp.pop();

                                                                this.setState({
                                                                    selected_brands: temp
                                                                }, () => {
                                                                    this.setUrlParameters();
                                                                    this.getProductByCategory(false);
                                                                })
                                                            }}>
                                                            <a>
                                                                {this.state.brands.find(el => el.id === this.state.selected_brands[0])?.name}
                                                            </a>
                                                        </li>
                                                    }
                                                </ul>
                                                {/* <div className="d-flex flex-wrap align-items-center">
                                                    <a href="#" className="clear-filters"><span>Clear All</span></a>
                                                    <div className="selected-filters-count ml-auto d-none d-lg-block">Selected <span>{this.state.selected_categories.length} items</span></div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="sidebar-block d-filter-mobile">
                                        <h3 className="mb-1">SORT BY</h3>
                                        <div className="select-wrapper select-wrapper-xs">
                                            <select className="form-control">
                                                <option value="featured">Featured</option>
                                                <option value="rating">Rating</option>
                                                <option value="price">Price</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={`sidebar-block filter-group-block ${this.state.sidebar_collapse.categories ? "open" : ""}`}>
                                        <div className="sidebar-block_title">
                                            <span onClick={() => this.toggleSidebarBlock("categories")}>Categories</span>
                                            <span className="toggle-arrow"
                                                onClick={() => this.toggleSidebarBlock("categories")}
                                            ><span /><span /></span>
                                        </div>
                                        <div className={`sidebar-block_content ${this.state.sidebar_collapse.categories ? "" : "d-none"}`}>
                                            <ul className="category-list">
                                                {
                                                    categories_mapping
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                    {/* {
                                        store_products_on
                                        &&
                                        <div className={`sidebar-block filter-group-block ${this.state.sidebar_collapse.storefronts ? "open" : ""}`}>
                                            <div className="sidebar-block_title">
                                                <span onClick={() => this.toggleSidebarBlock("storefronts")}>Stores</span>
                                                <span className="toggle-arrow"
                                                    onClick={() => this.toggleSidebarBlock("storefronts")}
                                                ><span /><span /></span>
                                            </div>
                                            <div className={`sidebar-block_content ${this.state.sidebar_collapse.storefronts ? "" : "d-none"}`}>
                                                <ul className="category-list">
                                                    {
                                                        store_front_mapping
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    } */}

                                    <div className={`sidebar-block filter-group-block ${this.state.sidebar_collapse.colors ? "open" : ""}`}
                                    >
                                        <div className="sidebar-block_title">
                                            <span onClick={() => this.toggleSidebarBlock("colors")}>Colors</span>
                                            <span className="toggle-arrow"
                                                onClick={() => this.toggleSidebarBlock("colors")}
                                            ><span /><span /></span>
                                        </div>
                                        <div className={`sidebar-block_content ${this.state.sidebar_collapse.colors ? "" : "d-none"}`}>
                                            <ul className="color-list two-column">
                                                {colors_mapping}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`sidebar-block filter-group-block ${this.state.sidebar_collapse.size ? "open" : ""}`}
                                    >
                                        <div className="sidebar-block_title">
                                            <span onClick={() => this.toggleSidebarBlock("size")}>Size</span>
                                            <span className="toggle-arrow"
                                                onClick={() => this.toggleSidebarBlock("size")}
                                            ><span /><span /></span>
                                        </div>
                                        <div className={`sidebar-block_content ${this.state.sidebar_collapse.size ? "" : "d-none"}`}>
                                            <ul className="category-list two-column size-list" data-sort="[&quot;XXS&quot;,&quot;XS&quot;,&quot;S&quot;,&quot;M&quot;,&quot;L&quot;,&quot;XL&quot;,&quot;XXL&quot;,&quot;XXXL&quot;]">
                                                {sizes_mapping}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`sidebar-block filter-group-block ${this.state.sidebar_collapse.brands ? "open" : ""}`}
                                    >
                                        <div className="sidebar-block_title">
                                            <span onClick={() => this.toggleSidebarBlock("brands")}>Brands</span>
                                            <span className="toggle-arrow" onClick={() => this.toggleSidebarBlock("brands")}><span /><span /></span>
                                        </div>
                                        <div className={`sidebar-block_content ${this.state.sidebar_collapse.brands ? "" : "d-none"}`}>
                                            <ul className="category-list">
                                                {brands_mapping}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`sidebar-block filter-group-block ${this.state.sidebar_collapse.price ? "open" : ""}`}>
                                        <div className="sidebar-block_title">
                                            <span onClick={() => this.toggleSidebarBlock("price")}>Price</span>
                                            <span className="toggle-arrow" onClick={() => this.toggleSidebarBlock("price")}><span /><span /></span>
                                        </div>
                                        <div className={`sidebar-block_content ${this.state.sidebar_collapse.price ? "" : "d-none"}`}>

                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                let values = [];
                                                if (this.state.min_range || this.state.max_range) {
                                                    values = [this.state.min_range, this.state.max_range]
                                                }

                                                this.setState({
                                                    values
                                                }, async () => {
                                                    // if (!this.state.p_range_changed) {
                                                    //     await this.setState({
                                                    //         p_range_changed: true
                                                    //     }, () => {
                                                    //         this.setUrlParameters()
                                                    //         this.getProductByCategory(false, true)
                                                    //     })
                                                    // } else {
                                                    this.setUrlParameters()
                                                    this.getProductByCategory(false, true)
                                                    // }
                                                })
                                            }}>
                                                <div className="row justify-content-around align-items-center px-1">
                                                    <div className="  col-8 p-0">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text border border-0" id="basic-addon1">$</span>
                                                            </div>
                                                            <input className="form-control" type="number" placeholder="Min" value={this.state.min_range} name="min_range" onChange={this.onChange}>
                                                            </input>
                                                        </div>
                                                    </div>
                                                    <div className=""> - </div>
                                                    <div className="col-8 p-0">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text border border-0" id="basic-addon1">$</span>
                                                            </div>
                                                            <input className="form-control" type="number" placeholder="Max" value={this.state.max_range} name="max_range" onChange={this.onChange}>

                                                            </input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <button type="submit"
                                                        className="btn btn-sm text-center mt-2 ">Go</button>
                                                </div>
                                            </form>

                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className={`sidebar-block filter-group-block ${this.state.sidebar_collapse.quantity ? "open" : ""}`}>
                                        <div className="sidebar-block_title">
                                            <span onClick={() => this.toggleSidebarBlock("quantity")}>Quantity</span>
                                            <span className="toggle-arrow" onClick={() => this.toggleSidebarBlock("quantity")}><span /><span /></span>
                                        </div>
                                        <div className={`sidebar-block_content ${this.state.sidebar_collapse.quantity ? "" : "d-none"}`}>

                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                let quantities = [];

                                                if (this.state.min_qty || this.state.max_qty) {
                                                    quantities = [this.state.min_qty, this.state.max_qty]
                                                }
                                                this.setState({
                                                    quantities
                                                }, async () => {
                                                    // if (!this.state.p_qty_changed) {
                                                    //     await this.setState({
                                                    //         p_qty_changed: true
                                                    //     }, () => {
                                                    //         this.setUrlParameters()
                                                    //         this.getProductByCategory(false, true)
                                                    //     })
                                                    // } else {
                                                    this.setUrlParameters()
                                                    this.getProductByCategory(false, true)
                                                    // }
                                                })
                                            }}>
                                                <div className="row justify-content-around align-items-center px-1">
                                                    <div className="  col-8 p-0">
                                                        {/* <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text border border-0" id="basic-addon1">$</span>
                                                            </div> */}
                                                        <input className="form-control" type="number" placeholder="Min" value={this.state.min_qty} name="min_qty" onChange={this.onChange} />
                                                        {/* </div> */}
                                                    </div>
                                                    <div className=""> - </div>
                                                    <div className="col-8 p-0">
                                                        {/* <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text border border-0" id="basic-addon1">$</span>
                                                            </div> */}
                                                        <input className="form-control" type="number" placeholder="Mx" value={this.state.max_qty} name="max_qty" onChange={this.onChange} />
                                                        {/* </input> */}
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <button type="submit"
                                                        className="btn btn-sm text-center mt-2 ">Go</button>
                                                </div>
                                            </form>

                                            {/* </div> */}
                                        </div>
                                    </div>


                                    {/* <div className={`sidebar-block filter-group-block ${this.state.sidebar_collapse.categories ? "open" :""}`}>
                                        <div className="sidebar-block_title">
                                            <span>Popular tags</span>
                                            <span className="toggle-arrow"><span /><span /></span>
                                        </div>
                                        <div className="sidebar-block_content">
                                            <ul className="tags-list">
                                                <li className="active"><a href="#">Jeans</a></li>
                                                <li><a href="#">St.Valentine’s gift</a></li>
                                                <li><a href="#">Sunglasses</a></li>
                                                <li><a href="#">Discount</a></li>
                                                <li><a href="#">Maxi dress</a></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    {/* <a href="https://bit.ly/3eJX5XE" className="bnr image-hover-scale bnr--bottom bnr--left fontratio-calc" data-fontratio="3.95" style={{ fontSize: '73.4177px' }}>
                                        <div className="bnr-img">
                                            <img src="/images/banners/banner-collection-aside.png" alt="" />
                                        </div>
                                    </a> */}
                                </div>
                            </div>
                            {/* filter toggle */}
                            <div className="filter-toggle js-filter-toggle" style={{ marginLeft: '-680px', marginRight: 630 }}>
                                <div className="loader-horizontal js-loader-horizontal">
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }} />
                                    </div>
                                </div>
                                <span className="filter-toggle-icons js-filter-btn"><i className="icon-filter" /><i className="icon-filter-close" /></span>
                                <span className="filter-toggle-text"><a href="#" className="filter-btn-open js-filter-btn">REFINE &amp; SORT</a><a href="#" className="filter-btn-close js-filter-btn">RESET</a><a href="#" className="filter-btn-apply js-filter-btn">APPLY &amp; CLOSE</a></span>
                            </div>
                            {/* /Left column */}
                            {/* Center column */}
                            {
                                this.state.show_products_loading_spinner ?
                                    <div className="col-lg aside">
                                        <div className="prd-grid-wrap">
                                            <Spinner />
                                        </div>
                                    </div>
                                    :
                                    this.state.category_products.length > 0
                                    &&
                                    <div className="col-lg aside">
                                        <div className="prd-grid-wrap">
                                            {/* Products Grid */}
                                            <div className={`product-listing data-to-show-3 data-to-show-md-3 data-to-show-sm-2 js-category-grid ${this.state.grid_type}`}
                                                data-grid-tab-content>
                                                {
                                                    category_products_mapping
                                                }
                                            </div>
                                            {
                                                this.state.show_load_more_spinner
                                                &&
                                                <Spinner type={BarLoader} container_size="50px" css={{ "height": "4px", "width": "300px" }} />
                                            }

                                            {
                                                this.state.category_products.length > 0 && ((this.state.category_products.length / this.state.total_products) !== 1)
                                                &&

                                                <Progressbar
                                                    onClick={(this.state.category_products.length / this.state.total_products) !== 1 && this.loadMoreProducts}
                                                    value={this.state.category_products.length / this.state.total_products}
                                                    cursor={(this.state.category_products.length / this.state.total_products) !== 1 && "pointer"}
                                                    title={(this.state.category_products.length / this.state.total_products) === 1 ? "All Loaded" : "Load More"}
                                                />
                                            }
                                        </div>
                                    </div>
                            }


                            {
                                (this.state.category_products.length === 0 && (!this.state.show_spinner) && (!this.state.show_products_loading_spinner))
                                &&
                                <div className="col-lg aside">
                                    <div className="prd-grid-wrap">
                                        <div className="page404-bg">
                                            <div className="page404-text">
                                                <div className="txt3"><i className="icon-shopping-bag" /></div>
                                                <div className="txt4">Unfortunately, there are no products<br />matching the selection</div>
                                            </div>
                                            <svg className="blob" id="morphing" xmlns="http://www.w3.org/2000/svg" width={600} height={600} viewBox="0 0 600 600">
                                                <g transform="translate(50,50)">
                                                    <path className="p" d="M93.5441 2.30824C127.414 -1.02781 167.142 -4.63212 188.625 21.7114C210.22 48.1931 199.088 86.5178 188.761 119.068C179.736 147.517 162.617 171.844 136.426 186.243C108.079 201.828 73.804 212.713 44.915 198.152C16.4428 183.802 6.66731 149.747 1.64848 118.312C-2.87856 89.9563 1.56309 60.9032 19.4066 38.3787C37.3451 15.7342 64.7587 5.14348 93.5441 2.30824Z" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                            }
                            {/* /Center column */}
                        </div>
                        {/* /Two columns */}
                    </div>
                </div >
                <div className="holder">
                    <div className="container">
                        <div className="title-wrap text-center">
                            <h2 className="h1-style">You may also like</h2>
                            <div className="carousel-arrows carousel-arrows--center" />
                        </div>
                        <ProductsRecommended
                            toggleQuickViewModal={this.toggleQuickViewModal}
                        />

                    </div>
                </div>
                {
                    this.state.show_quickview_modal
                    &&
                    <QuickViewModal
                        product_id={this.state.product_id}
                        toggleQuickViewModal={this.toggleQuickViewModal}
                        product_data={this.state.quick_view_product}
                    />
                }
            </div >

        );
    }
}

export default withRouter(ProductListing)