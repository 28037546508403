function getUrlParameters(location) {

    let search_param_obj;
    if (location) {
        search_param_obj = {}
        let decodedurl = decodeURI(location.search);
        let search_params = decodedurl.split("?")[1]?.split("&");
        if (search_params) {
            search_params.forEach(el => {
                let arr = el?.split("=");
                search_param_obj[arr[0]] = arr[1]
            })
        }
    }
    return search_param_obj;

}

function scrollToTop(position = 0, smooth = true) {
    if (smooth) {
        window.scrollTo({
            top: position,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo({
            top: position,
        });
    }

}

function getPosition(element) {
    var yPosition = 0;

    while (element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }
    return yPosition;
}

function inputValidation(arr) {

    let temp = [];

    arr.forEach(el => {
        const { field, state, target } = el;
        if (state === "" || state === null) {
            temp.push({ "error_field": field, "error": `The field is required` });
            target?.parentElement?.classList.add("has-error");
        }
    })

    return temp;
}

function handleError(err, toLogin) {
    if (err?.response?.data === "Unauthorized.") {
        let appState = {
            isLoggedIn: false
        };
        localStorage["appState"] = JSON.stringify(appState);
        localStorage.removeItem("userToken")

        if (toLogin) {
            toLogin()
        } else {
            window.location = "/login"
        }
    }
}

function getMainImage(res_image) {


    let arr = getImagesArr(res_image);
    let main_image = ""
    let main_images = arr.filter(el => el.main === true);

    if (main_images.length > 0) {
        main_image = main_images[0]?.path || "";
    } else {
        main_image = arr[0]?.path || "";
    }

    // return 'guide.png';
    return (main_image || "");
}


function getImagesArr(res_image) {

    /* 
        for singl old images in bitgo, return as arr
    */
    let images = [];

    try {
        if (res_image) {
            images = JSON.parse(res_image);
        } else {
            images = []
        }

    }
    catch (err) {
        let obj = {
            path: (res_image || ""),
            main: false,
            attribute: "",
        }
        images = [obj]
    }
    return images;

}

function getParsedDiscription(description) {
    //  if parsable, parse, else return same

    let parsed_desc;
    if (description) {
        try {
            parsed_desc = JSON.parse(description);
        } catch (e) {
            parsed_desc = description
        }
    } else {
        parsed_desc = ""
    }

    return parsed_desc;


}



export {
    getUrlParameters,
    scrollToTop,
    getPosition,
    inputValidation,
    handleError,
    getMainImage,
    getImagesArr,
    getParsedDiscription
}
