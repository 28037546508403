import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Slider from "react-slick";
import ProductVertical from '../ProductVertical'
import QuickViewModal from '../../QuickViewModal'

class StoreHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sliders: [],
            profile_store_images: [],
            profile_contacts: [],
            new_arrivals: [],
            featured_products: [],
            featured_categories: [],
            choose_us: [],
            profile_description: "",

            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,

            show_quickview_modal: false,
            grid_type: "prd-horgrid",
            activeSlide: 0,

            product_id: "",


        }
        this.toggleQuickViewModal = this.toggleQuickViewModal.bind(this)

    }


    componentDidMount() {
        // this.getStorefrontData();

    }

    toggleQuickViewModal(id, quick_view_product = {}) {
        this.setState({
            product_id: id,
            quick_view_product

        }, () => {
            this.setState((prev) => ({
                show_quickview_modal: !prev.show_quickview_modal
            }))
        })
    }



    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            pauseOnHover: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),

        };

        let sliders_mapping = this.props.sliders.map((el, index) => {
            return <div key={index}>
                <Link to={el.redirect_url}>

                    <div className="store-slider-background" style={{
                        backgroundImage: `url('${this.state.app_site_storage_path}${el.image}')`
                    }}>
                        <img src="/images/skins/electronics/slider/store-home.png"
                            alt={"name"}
                            className="" />
                    </div>
                </Link>
            </div>
        })

        let product_categories_mapping = this.props.featured_categories.map(el => {
            return <div className="collection-grid-2-item col-9 col-md-quarter col-lg-3" key={el.id}>
                {/* <a href="collections.html" target="_self" className="bnr-wrap collection-grid-2-item-inside"> */}
                <Link
                    to={`/product-listing?store_id=${this.props.match.params.id}&category_id=${el.id}`}
                    className="bnr-wrap collection-grid-2-item-inside"
                >
                    <div className="collection-grid-2-img image-container image-hover-scale"
                        style={{ paddingBottom: '77.778%' }}>
                        {/* <img className="lazyload fade-up"
                            data-src="/images/skins/electronics/category-img-01.png"
                            data-sizes="auto" alt={el.name}
                        /> */}
                        <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${el.file_name}')` }}>
                            <img src="/images/skins/electronics/category-img-01.png"
                                alt={el.name}
                                className="js-prd-imggg fade-up" />
                        </div>
                    </div>
                    <h3 className="text-center collection-grid-2-title pb-15 heading-font">{el.name}</h3>

                </Link>
                {/* </a> */}
            </div>
        })

        let choose_us_sliders_mapping = this.props.choose_us.map((el, index) => {
            return <div key={index}>
                <Link to={el.redirect_url || ""}>

                    <div className="store-slider-background" style={{
                        backgroundImage: `url('${this.state.app_site_storage_path}${el.image}')`
                    }}>
                        <img src="/images/skins/electronics/slider/slide-electronics-01-m-half.png"
                            alt={"name"}
                            className="" />
                    </div>
                </Link>
            </div>
        })

        let featured_products_mapping = this.props.featured_products.map(el => {
            return <ProductVertical
                key={el.id}
                product_data={el}
                toggleQuickViewModal={this.toggleQuickViewModal}
            />

        })


        let new_arrivals_mapping = this.props.new_arrivals.map(el => {
            return <ProductVertical
                key={el.id}
                product_data={el}
                toggleQuickViewModal={this.toggleQuickViewModal}
            />

        })

        return (
            <div>
                {
                    this.props.sliders.length > 0
                    &&
                    <div className="react-slick-slider">
                        <Slider {...settings}>
                            {
                                sliders_mapping
                            }
                        </Slider>
                    </div>
                }
                <div className="container">
                    {
                        this.props.featured_products.length > 0
                        &&
                        <div>
                            <h1 className="col mt-4">Featured Products</h1>
                            <div className="col-lg aside">
                                <div className="prd-grid-wrap">
                                    <div className={`product-listing data-to-show-3 data-to-show-md-3 data-to-show-sm-2 js-category-grid ${this.state.grid_type}`}
                                        data-grid-tab-content>
                                        {
                                            featured_products_mapping
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.props.new_arrivals.length > 0
                        &&
                        <div>
                            <h1 className="col mt-4">New Arrivals</h1>
                            <div className="col-lg aside">
                                <div className="prd-grid-wrap">
                                    <div className={`product-listing data-to-show-3 data-to-show-md-3 data-to-show-sm-2 js-category-grid ${this.state.grid_type}`}
                                        data-grid-tab-content>
                                        {
                                            new_arrivals_mapping
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.props.featured_categories.length > 0
                        &&
                        <div>
                            <h1 className="col mt-4">Featured Categories</h1>
                            <div className="col-lg aside">
                                <div className="prd-grid-wrap">
                                    <div className={`product-listing data-to-show-3 data-to-show-md-3 data-to-show-sm-2 js-category-grid ${this.state.grid_type}`}
                                        data-grid-tab-content>
                                        {
                                            product_categories_mapping
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.props.choose_us.length > 0
                        &&
                        <div>
                            <h1 className="col mt-4">Why Choose Us</h1>
                            <div className='row col'>
                                <div className="col-md-9 react-slick-slider">
                                    <Slider {...settings}>
                                        {
                                            choose_us_sliders_mapping
                                        }
                                    </Slider>
                                </div>
                                <div className="col-md-9">
                                    <p>
                                        {this.props.choose_us_description}
                                    </p>
                                </div>

                            </div>
                        </div>
                    }

                </div>


                {
                    this.state.show_quickview_modal
                    &&
                    <QuickViewModal
                        product_id={this.state.product_id}
                        toggleQuickViewModal={this.toggleQuickViewModal}
                        product_data={this.state.quick_view_product || {}}

                    />
                }
            </div >
        )
    }
}

export default withRouter(StoreHome)
