const PointsReducer = (state = [], action) => {
    switch (action.type) {
        // case 'INCREMENT': {
        //     let temp = [...state];
        //     let old_cart_item = temp.find(el => el.id == action.payload.id);

        //     if (old_cart_item) {
        //         // increase quantity
        //         let old_quantity = old_cart_item.quantity;
        //         temp = temp.map(el => el.id == old_cart_item.id ? { ...el, quantity: (old_quantity + (action?.payload?.quantity || 1)) } : el);
        //     } else {
        //         temp.push(action.payload);
        //     }
        //     return temp;
        // }

        // case 'INCREMENTQUANTITY': {
        //     let temp = [...state];
        //     temp = temp.map((el, index) => index == action.payload ? { ...el, quantity: (el.quantity + 1) } : el);
        //     return temp;
        // }

        // case 'DECREMENT': {
        //     let temp = [...state]
        //     temp = temp.filter((el, index) => index != action.payload)
        //     return temp;
        // }

        // case 'DECREMENTQUANTITY': {
        //     let temp = [...state]
        //     temp = temp.map((el, index) => index == action.payload ?
        //         { ...el, quantity: (el.quantity == 1 ? 1 : (el.quantity - 1)) }
        //         :
        //         el
        //     );
        //     return temp;
        // }
        // case 'CLEARCART': {

        //     return [];
        // }

        case 'REPLACEPOINTS': {
            return action.payload;
        }

        default:
            return state;
    }
}

export default PointsReducer;