import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class StoreHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }


    render() {

        return (
            <div>
                {/* <div className=" container d-flex">
                    <div className="store-store-home d-flex align-items-center">
                        <span>
                            <img src="https://b2bfiles1.gigab2b.cn/image/wkseller/7008/20210806_fc4d7c022108c1dd25a288d83fe71dac.jpg?x-oss-process=image%2Fresize%2Cw_120%2Ch_120%2Cm_pad" />
                        </span>
                        <div>
                            <div className="p-2">
                                <span className="d-block">
                                    LiyasiFurnitureCo.,Ltd.
                                </span>
                                <span className="d-block">Store Code: W820</span>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="bg-dark py-2 ">
                    <div className="container ">
                        <div className=" row  justify-content-between">
                            <div className="d-flex align-items-center col-md-13">
                                <span className={`store-menu-link ${this.props.selected_tab === "home" ? "active" : ""}`} >
                                    <Link to={`/store/${this.props.selected_storefront_id}?tab=home`} className="text-light">
                                        Store Home
                                    </Link>
                                </span>
                                <span className={`store-menu-link ml-2 ${this.props.selected_tab === "products" ? "active" : ""}`} >

                                    <Link to={`/product-listing?store_id=${this.props.selected_storefront_id}&tab=products`} className="text-light">
                                        Products
                                    </Link>
                                </span>
                                <span className={`store-menu-link ml-2 ${this.props.selected_tab === "profile" ? "active" : ""}`} >

                                    <Link to={`/store/${this.props.selected_storefront_id}?tab=profile`} className="text-light">
                                        Store Profile
                                    </Link>
                                </span>
                            </div>
                            {/* <div className="col-md-5">
                                <input id="" className="form-control rounded" type="search" name="storeSearch" defaultvalue placeholder="Search in This Store" autoComplete="off" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default StoreHeader
