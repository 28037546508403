import React, { Component } from 'react'
import BreadCrumb from '../BreadCrumb'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router';
import axiosConfig from '../../axiosConfig';

import AccountDetails from './AccountDetails';
import AccountAddresses from './AccountAddresses';
import AccountWishlist from './AccountWishlist';
import AccountHistory from './AccountHistory';
import AccountPoints from './AccountPoints';
import AccountRecharge from './AccountRecharge';
import EditAccountRecharge from './EditAccountRecharge';
import HelmetComponent from '../../HelmetComponent';


class AccountDetailsListing extends Component {
    constructor(props) {
        super(props);
        var appState = JSON.parse(localStorage.getItem('appState'))
        this.state = {
            isLoggedIn: appState?.isLoggedIn || false,
        }
    }


    render() {

        if (!this.state.isLoggedIn) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }}/>
        }

        axiosConfig.defaults.headers.common = {
            'Authorization': 'Bearer ' + localStorage.userToken
        };

        // let links = [
        //     { link: "/account-details", "name": "Account Details" },
        //     { link: "/account-addresses", "name": "My Addresses" },
        //     { link: "/account-wishlist", "name": "My Wishlist" },
        //     { link: "/account-history", "name": "My Order History" },
        //     { link: "/account-points", "name": "My Points" },
        //     { link: "/logout", "name": "Logout" },
        // ]

        // let links_mapping = links.map(el => {
        //     return <Link key={el.link} to={el.link} className={`list-group-item ${el.link == this.props.location.pathname && "active"}`}>
        //         {el.name}
        //     </Link>
        // })

        let bread_crumb_title = ""

        if (this.props.location.pathname === "/account-details") {
            bread_crumb_title = "Account Details"
        } else if (this.props.location.pathname === "/account-addresses") {
            bread_crumb_title = "Account Addresses"
        } else if (this.props.location.pathname === "/account-wishlist") {
            bread_crumb_title = "Account Wishlist"
        } else if (this.props.location.pathname === "/account-history") {
            bread_crumb_title = "Account History"
        } else if (this.props.location.pathname === "/account-balance") {
            bread_crumb_title = "Account Balance"
        } else if (this.props.location.pathname === "/account-balance/recharge") {
            bread_crumb_title = "Account Recharge"
        } else if (this.props.location.pathname === ("/account-balance/recharge/" + this.props.match.params.id)) {
            bread_crumb_title = "Account Funds Detail"
        }

        return (

            <div className="page-content">
                <HelmetComponent
                    title={bread_crumb_title}
                />

                <BreadCrumb
                    title={bread_crumb_title}
                    parents={[
                        {
                            title: "Home",
                            route: "/",
                        },
                    ]}
                />
                <div className="holder">
                    <div className="container">
                        <div className="row">

                            {/* <div className="col-md-4 aside aside--left d-none">
                                <div className="list-group">
                                    {
                                        links_mapping
                                    }
                                    
                                </div>
                            </div> */}

                            {
                                this.props.location.pathname === "/account-details"
                                &&
                                <AccountDetails />
                            }
                            {
                                this.props.location.pathname === "/account-addresses"
                                &&
                                <AccountAddresses />
                            }
                            {
                                this.props.location.pathname === "/account-wishlist"
                                &&
                                <AccountWishlist />
                            }
                            {
                                this.props.location.pathname === "/account-history"
                                &&
                                <AccountHistory />
                            }
                            {
                                this.props.location.pathname === "/account-balance"
                                &&
                                <AccountPoints />
                            }
                            {
                                this.props.location.pathname === "/account-balance/recharge"
                                &&
                                <AccountRecharge />
                            }
                            {
                                this.props.location.pathname === ("/account-balance/recharge/" + this.props.match.params.id)
                                &&
                                <EditAccountRecharge />
                            }

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(AccountDetailsListing);
