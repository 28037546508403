import React, { Component } from 'react'
import BreadCrumb from '../BreadCrumb'
import { Redirect } from 'react-router-dom';


import axiosConfig from '../../axiosConfig'
import { inputValidation, scrollToTop, getPosition } from '../../helper_funcitons'
import InputValidationErrorMessage from '../../inputValidationErrorMessage'
import $ from 'jquery';
import Select from 'react-select';

import { Link } from 'react-router-dom';

export default class AccountCreate extends Component {

    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }
        this.state = {
            isLoggedIn: appState?.isLoggedIn || false,

            validation_errors: [],
            first_name: "",
            last_name: "",
            job_title: "",
            email: "",
            phone: "",
            primary_time_zone: "",
            company: "",
            tax_id: "",
            state_of_incorporation: "",
            company_founded_year: "",
            annual_online_sales_revenue: "",
            website_url: "",
            address_line_1: "",
            address_line_2: "",
            city: "",
            countries: [],
            country: "",
            postal_code: "",
            states: [],
            state: "",

            target_market: [],
            find_us: "",
            find_us_response: "",
            business_offer_response: "",
            most_utilized_ecommerce_response: "",




            password: "",
            password_confirmation: "",
            terms_and_conditions: false,
            redirect_to_account_details: false,
            registration_step: 1

        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChangeReactSelect = this.onChangeReactSelect.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault();

        let validation_fields = [
            "first_name",
            // "last_name",
            "email",
            "job_title",
            "phone",
            "company",
            "address_line_1",
            "city",
            "country",
            "postal_code",
            "state"
        ];

        let arr = [];
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })
        let validation_errors = inputValidation(arr);

        this.setState({
            validation_errors: validation_errors
        })

        if (validation_errors.length > 0) {
            let element;
            if (validation_errors[0].error_field === "country" || validation_errors[0].error_field === "state") {
                element = document.getElementsByClassName("registration_form")[0].querySelector(`.validate_react_select.${validation_errors[0].error_field}`)
            } else {
                element = document.getElementsByClassName("registration_form")[0].querySelector(`input[name="${validation_errors[0].error_field}"]`)
            }

            let x = getPosition(element)
            let header_menu_height = document.getElementsByClassName("hdr-nav hide-mobile nav-holder-s")[0]?.offsetHeight || 0
            scrollToTop((x - header_menu_height))
        }

        if (validation_errors.length === 0) {
            let { first_name, last_name, job_title, email, phone, company, tax_id, state_of_incorporation, company_founded_year, annual_online_sales_revenue, website_url, address_line_1, address_line_2, city, country, postal_code, state,
            } = this.state

            let data = {
                first_name, last_name, job_title, email, phone, company, tax_id, state_of_incorporation, company_founded_year, annual_online_sales_revenue, website_url, address_line_1, address_line_2, city, country, postal_code, state,
            }

            axiosConfig.post("/api/register", data)
                .then(res => {
                    if (res.data.success) {
                        this.setState({
                            // redirect_to_account_details: true,
                            registration_step: 2
                        })

                    } else {
                        var messages = JSON.parse(res.data.messages);
                        let count = 0;
                        if (messages instanceof Object) {
                            var _message = '';
                            for (const [key, value] of Object.entries(messages)) {
                                _message += `${value}`;
                                count++;
                                $("#" + key).closest('.form-group').addClass('has-error');
                                $("#" + key).after(`<p class="invalid-feedback text-left"> + ${value} + </p>`);
                            }
                            if (count <= 1) {
                                this.setState({
                                    alert_status: "error",
                                    alert_message: _message,
                                })
                            }
                        }
                        else {
                            this.setState({
                                alert_status: "error",
                                alert_message: res.data.messages,
                            })
                        }
                    }
                })
                .catch(err => {

                })
        }

    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            if (e.target.name === "target_market") {
                let temp = [...this.state.target_market]
                if (e.target.checked) {
                    temp.push(e.target.value);
                } else {
                    temp = temp.filter(el => el !== e.target.value);
                }

                this.setState({
                    target_market: temp
                })


            } else {
                this.setState({
                    [e.target.name]: e.target.checked
                })
            }
        } else if (e.target.type === "radio") {
            if (e.target.name === "find_us") {
                this.setState({
                    find_us: e.target.value
                })
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
            if (e.target.value.length > 0) {
                e.target.parentElement.classList.remove("has-error");
            }
            else {
                if(
                    e.target.name !== "last_name" &&
                    e.target.name !== "tax_id" &&
                    e.target.name !== "state_of_incorporation" &&
                    e.target.name !== "company_founded_year" &&
                    e.target.name !== "annual_online_sales_revenue" &&
                    e.target.name !== "website_url" &&
                    e.target.name !== "address_line_2"
                ){
                    e.target.parentElement.classList.add("has-error");
                }
            }
        }
    }

    componentDidMount() {
        scrollToTop(0, 0)
        this.getCountries();
    }

    onChangeReactSelect(e, event) {

        if (e) {
            this.setState({
                [event.name]: e.value
            }, () => {

                if (event.name === "country") {
                    this.getStates(this.state.country)
                }

                let group_select_grand_parent = document.querySelector(`.${[event.name]}`);
                group_select_grand_parent.querySelector(".has-error")?.classList.remove("has-error")

                // if (e.value.length > 0) {

                //     document.getElementsByName([event.name])[0].parentElement.classList.remove("has-error")
                //     // e.target.parentElement.classList.remove("has-error");

                // }
                // else {
                //     // e.target.parentElement.classList.add("has-error");
                //     // document.getElementsByName([event.name])[0].add("has-error")

                // }
            })
        } else {
            this.setState({
                [event.name]: ""
            })
        }
    }

    getCountries() {
        axiosConfig.get("api/countries")
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        countries: res.data.data,
                    }, () => {
                        if (this.state.country) {
                            this.getStates()
                        }
                    })
                }
            })
            .catch(err => {

            })
    }

    getStates() {
        axiosConfig.get("api/states/" + this.state.country)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        states: res.data.data,
                    })
                }
            })
            .catch(err => {

            })

    }

    render() {

        let country_options = this.state.countries.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })

        // let targets = ["united states", "united kingdom", "germany", "japan"]
        // let target_market_mapping = targets.map((el, index) => {
        //     return <div className="form-group mt-1" key={index}>
        //         <input
        //             type="checkbox"
        //             id={el}
        //             name="target_market"
        //             value={el}
        //             checked={this.state.target_market.includes(el)}
        //             onChange={this.onChange}
        //         />
        //         <label htmlFor={el}> <span className="text-capitalize">{el} </span> </label>
        //     </div>
        // })

        // let find_us = ["business representative recommended", "others"]
        // let find_us_mapping = find_us.map((el, index) => {
        //     return <div className="form-group col-md-6 mt-1 ">
        //         <input
        //             type="radio"
        //             id={el}
        //             value={el}
        //             name="find_us"
        //             checked={this.state.find_us === el}
        //             onChange={this.onChange}
        //         />
        //         <label htmlFor={el} className="required-input_star">{el}</label>
        //     </div>
        // })

        let country_options_value = country_options.filter(el => el.value === this.state.country)

        let state_options = this.state.states.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })

        let state_options_value = state_options.filter(el => el.value === this.state.state)


        if (this.state.redirect_to_account_details || this.state.isLoggedIn) {
            return <Redirect to="/account-details" />
        }

        return (
            <>
                <div className="page-content">
                    <BreadCrumb
                        title="Create Account"
                        parents={[
                            {
                                title: "Home",
                                route: "/",
                            },
                        ]}
                    />
                    <div className="holder">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-18 col-lg-12">
                                    <h2 className="text-center">I am a new customer</h2>
                                    <div className="form-wrapper mt-0">
                                        <ul className="list-unstyled d-flex justify-content-between align-items-center px-3 py-1"
                                            style={{
                                                background: "#f3f5f7"
                                            }}>
                                            <li
                                                style={{
                                                    color: this.state.registration_step >= 1 ? 'rgb(58, 117, 220)' : ""
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        // registration_step: 1
                                                    })
                                                }}
                                            >
                                                1. Submit Buyer information
                                            </li>
                                            <li className="p-1">&gt;</li>
                                            <li
                                                style={{
                                                    color: this.state.registration_step >= 2 ? 'rgb(58, 117, 220)' : ""
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        // registration_step: 2
                                                    })
                                                }}

                                            >2. Receive contact from business representative</li>
                                            <li className="p-1">&gt;</li>
                                            <li
                                                style={{
                                                    color: this.state.registration_step >= 3 ? 'rgb(58, 117, 220)' : ""
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        // registration_step: 3
                                                    })
                                                }}
                                            >3. Account approval and creation</li>
                                        </ul>
                                        {/* <p>To access your whishlist, address book and contact preferences and to take advantage of our speedy checkout, create an account with us now.</p> */}
                                        {
                                            this.state.registration_step === 1
                                            &&
                                            <>
                                                <p className="form-title">Contact Information</p>
                                                <form onSubmit={this.handleSubmit} className="mt-0 registration_form">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">First Name</label>
                                                                <input
                                                                    type="text"
                                                                    value={this.state.first_name}
                                                                    onChange={this.onChange}
                                                                    name="first_name"
                                                                    id="first_name"
                                                                    className="form-control"
                                                                    placeholder="First name" />
                                                            </div>
                                                            <InputValidationErrorMessage
                                                                validation_errors={this.state.validation_errors}
                                                                input_field={this.state.first_name}
                                                                error_field="first_name"
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className=" text-uppercase">Last Name</label>

                                                                <input type="text"
                                                                    value={this.state.last_name}
                                                                    onChange={this.onChange}
                                                                    name="last_name"
                                                                    id="last_name"
                                                                    className="form-control" placeholder="Last name" />
                                                                {/* <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.last_name}
                                                                    error_field="last_name"
                                                                /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">Job Title</label>

                                                                <input type="text"
                                                                    value={this.state.job_title}
                                                                    onChange={this.onChange}
                                                                    name="job_title"
                                                                    id="job_title"
                                                                    className="form-control" placeholder="Job Title" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.job_title}
                                                                    error_field="job_title"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">Email</label>
                                                                <input
                                                                    value={this.state.email}
                                                                    onChange={this.onChange}
                                                                    name="email"
                                                                    id="email"
                                                                    type="email"
                                                                    className="form-control" placeholder="E-mail"
                                                                />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.email}
                                                                    error_field="email"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">Phone</label>

                                                                <input type="phone"
                                                                    value={this.state.phone}
                                                                    onChange={this.onChange}
                                                                    name="phone"
                                                                    id="phone"
                                                                    className="form-control" placeholder="Phone" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.phone}
                                                                    error_field="phone"
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="required-input_star text-uppercase">Primary Time Zone</label>

                                                        <input type="primary_time_zone"
                                                            value={this.state.primary_time_zone}
                                                            onChange={this.onChange}
                                                            name="primary_time_zone"
                                                            id="primary_time_zone"
                                                            className="form-control" placeholder="Primary Time Zone" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.primary_time_zone}
                                                            error_field="primary_time_zone"
                                                        />
                                                    </div>
                                                </div>
                                            */}
                                                    </div>
                                                    <p className="form-title">Business Information</p>
                                                    <div className="row mt-0">
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">Company</label>
                                                                <input type="company"
                                                                    value={this.state.company}
                                                                    onChange={this.onChange}
                                                                    name="company"
                                                                    id="company"
                                                                    className="form-control" placeholder="Company" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.company}
                                                                    error_field="company"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">

                                                                <label className="text-uppercase">Tax ID</label>

                                                                <input type="tax_id"
                                                                    value={this.state.tax_id}
                                                                    onChange={this.onChange}
                                                                    name="tax_id"
                                                                    id="tax_id"
                                                                    className="form-control" placeholder="Tax ID" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.tax_id}
                                                                    error_field="tax_id"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className=" text-uppercase">State of Incorporation</label>
                                                                <input type="state_of_incorporation"
                                                                    value={this.state.state_of_incorporation}
                                                                    onChange={this.onChange}
                                                                    name="state_of_incorporation"
                                                                    id="state_of_incorporation"
                                                                    className="form-control" placeholder="State of Incorporation" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.state_of_incorporation}
                                                                    error_field="state_of_incorporation"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_starr text-uppercase">Year Company was Founded</label>
                                                                <input type="company_founded_year"
                                                                    value={this.state.company_founded_year}
                                                                    onChange={this.onChange}
                                                                    name="company_founded_year"
                                                                    id="company_founded_year"
                                                                    className="form-control" placeholder="Year Company was Founded" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.company_founded_year}
                                                                    error_field="company_founded_year"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_starr text-uppercase">Annual Online Sales Revenue</label>
                                                                <input type="annual_online_sales_revenue"
                                                                    value={this.state.annual_online_sales_revenue}
                                                                    onChange={this.onChange}
                                                                    name="annual_online_sales_revenue"
                                                                    id="annual_online_sales_revenue"
                                                                    className="form-control" placeholder="Annual Online Sales Revenue" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.annual_online_sales_revenue}
                                                                    error_field="annual_online_sales_revenue"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_starr text-uppercase">WEBSITE(S)</label>
                                                                <input type="website_url"
                                                                    value={this.state.website_url}
                                                                    onChange={this.onChange}
                                                                    name="website_url"
                                                                    id="website_url"
                                                                    className="form-control" placeholder="http://" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.website_url}
                                                                    error_field="website_url"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">Address Line 1</label>

                                                                <input type="address_line_1"
                                                                    value={this.state.address_line_1}
                                                                    onChange={this.onChange}
                                                                    name="address_line_1"
                                                                    id="address_line_1"
                                                                    className="form-control" placeholder="Address Line 1" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.address_line_1}
                                                                    error_field="address_line_1"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="text-uppercase">Address Line 2</label>
                                                                <input type="address_line_2"
                                                                    value={this.state.address_line_2}
                                                                    onChange={this.onChange}
                                                                    name="address_line_2"
                                                                    id="address_line_2"
                                                                    className="form-control" placeholder="Address Line 2" />

                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">City/Town</label>

                                                                <input type="city"
                                                                    value={this.state.city}
                                                                    onChange={this.onChange}
                                                                    name="city"
                                                                    id="city"
                                                                    className="form-control" placeholder="City/Town" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.city}
                                                                    error_field="city"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">Country</label>

                                                                <div className="form-group select-wrapper mt-0">
                                                                    <div className="validate_react_select country">
                                                                        <Select
                                                                            options={country_options}
                                                                            value={country_options_value}
                                                                            name="country"
                                                                            className=""
                                                                            onChange={this.onChangeReactSelect}
                                                                            styles={{
                                                                                control: base => ({
                                                                                    ...base,
                                                                                    height: document.getElementById("city")?.offsetHeight,
                                                                                    minHeight: document.getElementById("city")?.offsetHeight,
                                                                                    background: "#f7f7f8",
                                                                                    border: "transparent",
                                                                                    outline: "transparent"
                                                                                })
                                                                            }}
                                                                        />
                                                                        {/* <select className="form-control input-sm" name="per_page" id="per_page" onChange={this.onChangeDropDown}>
                                                                    {
                                                                        per_page_view_options
                                                                    }
                                                                </select> */}

                                                                        <InputValidationErrorMessage
                                                                            validation_errors={this.state.validation_errors}
                                                                            input_field={this.state.country}
                                                                            error_field="country"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">ZIP/Postal Code</label>

                                                                <input type="postal_code"
                                                                    value={this.state.postal_code}
                                                                    onChange={this.onChange}
                                                                    name="postal_code"
                                                                    id="postal_code"
                                                                    className="form-control" placeholder="ZIP/Postal Code" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.postal_code}
                                                                    error_field="postal_code"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="required-input_star text-uppercase">STATE / REGION / PROVINCE</label>
                                                                <div className="form-group select-wrapper mt-0">
                                                                    <div className="validate_react_select state">
                                                                        <Select
                                                                            options={state_options}
                                                                            value={state_options_value}
                                                                            name="state"
                                                                            onChange={this.onChangeReactSelect}
                                                                            styles={{
                                                                                control: base => ({
                                                                                    ...base,
                                                                                    height: document.getElementById("city")?.offsetHeight,
                                                                                    minHeight: document.getElementById("city")?.offsetHeight,
                                                                                    background: "#f7f7f8",
                                                                                    border: "transparent",
                                                                                    outline: "transparent"
                                                                                })
                                                                            }}
                                                                        />
                                                                        <InputValidationErrorMessage
                                                                            validation_errors={this.state.validation_errors}
                                                                            input_field={this.state.state}
                                                                            error_field="state"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <p className="form-title">Q&amp;A</p>
                                            <p>Q1 — WHO IS YOUR TARGET MARKET?</p>
                                            <div className="row mt-0">
                                                {target_market_mapping}
                                            </div>

                                            <p>Q2 — HOW DID YOU FIND US?</p>
                                            <div className="row mt-0">
                                                {find_us_mapping}
                                                <div className="form-group  col-md-18 mt-1">
                                                    <textarea
                                                        className="form-control"
                                                        name="find_us_response"
                                                        value = {this.state.find_us_response}
                                                        onChange = {this.onChange}
                                                        rows="7"
                                                        placeholder="Enter your response here..."
                                                    />
                                                </div>
                                            </div>
                                            <p>Q3 — WHAT PRODUCT CATEGORIES DOES YOUR BUSINESS OFFER?</p>
                                            <div className='row mt-0'>
                                                <div className="form-group mt-1 col-md-18">
                                                    <textarea
                                                        className="form-control"
                                                        rows="7"
                                                        value = {this.state.business_offer_response}
                                                        onChange = {this.onChange}
                                                        name="business_offer_response"
                                                        placeholder="Enter your answer here..."
                                                    />
                                                </div>
                                            </div>
                                            <p>Q4 — WHAT ARE YOUR MOST UTILIZED ECOMMERCE ACCOUNT(S)? PLEASE PROVIDE STOREFRONT URL(S).</p>
                                            <div className='row mt-0'>
                                                <div className="form-group mt-1 col-md-18">
                                                    <textarea
                                                        className="form-control"
                                                        rows="7"
                                                        name="most_utilized_ecommerce_response"
                                                        value = {this.state.most_utilized_ecommerce_response}
                                                        onChange = {this.onChange}
                                                        placeholder="Enter your answer here..."
                                                    />
                                                </div>
                                            </div>
                                             */}

                                                    {/* <div className="form-group">
                                                <input type="password"
                                                    value={this.state.password}
                                                    onChange={this.onChange}
                                                    name="password"
                                                    id="password"
                                                    className="form-control" placeholder="Password" />
                                                <InputValidationErrorMessage
                                                    validation_errors={this.state.validation_errors}
                                                    input_field={this.state.password}
                                                    error_field="password"
                                                />

                                            </div>
                                            <div className="form-group">
                                                <input type="password"
                                                    value={this.state.password_confirmation}
                                                    onChange={this.onChange}
                                                    name="password_confirmation"
                                                    id="password_confirmation"
                                                    className="form-control" placeholder="Confirm Password" />
                                                <InputValidationErrorMessage
                                                    validation_errors={this.state.validation_errors}
                                                    input_field={this.state.password_confirmation}
                                                    error_field="password_confirmation"
                                                />
                                            </div>
                                            <div className="clearfix"> 
                                                <input id="checkbox1"
                                                    name="terms_and_conditions"
                                                    type="checkbox"
                                                    checked={this.state.terms_and_conditions}
                                                    onChange={this.onChange}
                                                />
                                                <label htmlFor="checkbox1">By registering your details you agree to our <a href="#" className="custom-color" data-fancybox data-src="#modalTerms">Terms and Conditions</a> and <a href="#" className="custom-color" data-fancybox data-src="#modalCookies">Cookie Policy</a></label>
                                            </div>
                                            */}

                                                    <div className="text-center">
                                                        <button className="btn"
                                                        // disabled={
                                                        //     this.state.terms_and_conditions ? false : true
                                                        // }
                                                        >Register Now</button>
                                                    </div>
                                                </form>

                                            </>
                                        }
                                        {
                                            this.state.registration_step === 2
                                            &&
                                            <>
                                                <p className="form-title">You have successfully applied for registration</p>
                                                <p>Thank you for registering with the BITGOFULFILL MARKETPLACE.</p>
                                                <p>A business representative will be reaching out to you within 5 working days via the contact information you provided, please check your messages regularly.</p>
                                                <div className="d-flex justify-content-center mt-2">
                                                    <Link to="/" className="btn btn-primary">
                                                        Home
                                                    </Link>

                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div id="modalTerms" style={{ display: 'none' }} className="modal-info-content modal-info-content-lg">
                        <div className="modal-info-heading">
                            <h2>Terms and Conditions</h2>
                        </div>
                        <p>This website is operated by a.season. Throughout the site, the terms “we”, “us” and “our” refer to a.season. a.season offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                        <p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
                        <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                        <p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
                        <p>Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.</p>
                    </div>
                    <div id="modalCookies" style={{ display: 'none' }} className="modal-info-content modal-info-content-lg">
                        <div className="modal-info-heading">
                            <h2>Cookie Policy</h2>
                        </div>
                        <p>This website is operated by a.season. Throughout the site, the terms “we”, “us” and “our” refer to a.season. a.season offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                        <p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
                        <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                        <p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
                        <p>Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.</p>
                    </div>
                </div>

            </>
        )
    }
}
