import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist } from '../actions'

import { handleError } from '../helper_funcitons'
import axiosConfig from '../axiosConfig'


class WishListButton extends Component {
    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.state = {
            isLoggedIn: appState?.isLoggedIn || false,
            redirect_to_login: false,
        }
        this.toLogin = this.toLogin.bind(this)
    }

    addToWishlist(el) {

        if (this.state.isLoggedIn) {
            let obj = {
                product_id: el.id,
                // name: el.name,
                // price: el.price,
                // quantity: 1,
                // image: el.image,
            }
            axiosConfig.post("/api/wishlists/create/" + el.id)
                .then(res => {
                    if (res.data.success) {
                        this.props.addToWishlist(obj);
                    }
                })
                .catch(err => {
                    handleError(err, this.toLogin)
                })

        } else {
            this.setState({
                redirect_to_login: true,
            })
        }

    }

    removeFromWishlist(id) {
        if (this.state.isLoggedIn) {
            axiosConfig.delete("/api/wishlists/delete/" + id)
                .then(res => {
                    if (res.data.success) {
                        this.props.removeFromWishlist(id);
                    }
                })
                .catch(err => {
                    handleError(err, this.toLogin)
                })
        } else {
            this.setState({
                redirect_to_login: true,
            })
        }
    }

    toLogin() {
        this.setState({
            redirect_to_login: true
        })
    }


    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }}/>
        }

        let wishlist_reducer = [...this.props.wishlist_reducer]

        let wishlist_reduce_ids = wishlist_reducer.map(el => (el.product_id))

        return (
            <>
                {
                    wishlist_reduce_ids.includes(this.props.product_data.id)
                        ?
                        <span
                            style={{
                                cursor: "pointer"
                            }}
                            // href="#"
                            className="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0"
                            onClick={() => { this.removeFromWishlist(this.props.product_data.id) }}
                            title="Remove From Wishlist"
                        >
                            <i className="icon-heart-hover" />
                        </span>
                        :
                        <span
                            style={{
                                cursor: "pointer"
                            }}
                            // href="#"
                            className="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0"
                            onClick={() => { this.addToWishlist(this.props.product_data) }}
                            title="Add To Wishlist"
                        >
                            <i className="icon-heart-stroke" />
                        </span>
                }
            </>
        )
    }
}


const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(WishListButton);

