import React, { Component } from 'react'
import axiosConfig from '../axiosConfig'
import Select from 'react-select'
import $ from 'jquery'
import { withRouter } from 'react-router'

// redux
import { connect } from 'react-redux'
import { incrementCart, decrementCart, incrementCartQuantity, decrementCartQuantity, clearCart, replacePoints } from '../actions'
import { Link, Redirect } from 'react-router-dom'

import { getUrlParameters, getPosition, inputValidation, handleError } from '../helper_funcitons'
import InputValidationErrorMessage from '../inputValidationErrorMessage'
import BreadCrumb from './BreadCrumb'
import AlertMessage from '../AlertMessage'
import { scrollToTop } from '../helper_funcitons'
import Spinner from '../Spinner'
import HelmetComponent from '../HelmetComponent'

class Checkout extends Component {
    constructor(props) {
        super(props)

        var appState = JSON.parse(localStorage.getItem('appState'))

        this.state = {
            isLoggedIn: appState?.isLoggedIn || false,
            validation_errors: [],
            alert_message: "",
            alert_status: "",

            delivery_options: [],
            payment_options: [],
            selected_payment_option: '',
            selected_delivery_option: "",
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,

            account_details: [{
                account_name: "",
                account_number: "",
                bank_name: "",
                sort_code: "",
                iban: "",
                bic_swift: '',
            }],

            countries: [],
            states: [],
            billing_states: [],

            // shipping-address
            first_name: "",
            last_name: "",
            country: "",
            state: "",
            postal_code: "",
            address_1: "",
            city: "",
            phone: "",
            save_address: false,


            // biiling-address
            same_as_shipping: false,
            billing_first_name: "",
            billing_last_name: "",
            billing_country: "",
            billing_state: "",
            billing_postal_code: "",
            billing_address_1: "",
            billing_city: "",
            billing_phone: "",


            //credit-card
            cart_number: "",
            month: "",
            year: "",
            cvv_code: "",
            order_comment: "",

            in_process: false,

            addresses: [],
            show_spinner: true,
        }

        this.getPaymentOptions = this.getPaymentOptions.bind(this)
        this.getDeliveryOptions = this.getDeliveryOptions.bind(this)

        this.onChange = this.onChange.bind(this)

        // bank
        this.addAccountDetail = this.addAccountDetail.bind(this)
        this.removeAccountDetail = this.removeAccountDetail.bind(this)
        this.onChangeAccountDetails = this.onChangeAccountDetails.bind(this)

        this.onChangeReactSelect = this.onChangeReactSelect.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toLogin = this.toLogin.bind(this)

        this.onChangeAddress = this.onChangeAddress.bind(this)
        this.setAddressValues = this.setAddressValues.bind(this)
    }


    async getPaymentOptions() {
        await axiosConfig.get("/api/payment-active")
            .then(async (res) => {

                if (res.data.success) {
                    await this.setState({
                        payment_options: res.data.data,
                    })
                }
            })
            .catch(err => {
                handleError(err, this.toLogin)
            })
    }

    async getDeliveryOptions() {
        await axiosConfig.get("/api/delivery-options")
            .then(async (res) => {

                if (res.data.success) {
                    await this.setState({
                        delivery_options: res.data.data,
                    })
                }
            })
            .catch(err => {
                handleError(err, this.toLogin)
            })
    }

    async getCountries() {
        await axiosConfig.get("api/countries")
            .then(async (res) => {
                if (res.data.success) {
                    await this.setState({
                        countries: res.data.data,
                    })
                }
            })
            .catch(err => {

            })
    }

    // async getStates(country_id) {
    //     axiosConfig.get("api/states/" + country_id)
    //         .then(async (res) => {
    //             if (res.data.success) {
    //                 await this.setState({
    //                     states: res.data.data,
    //                 })
    //             }
    //         })
    //         .catch(err => {

    //         })

    // }

    async componentDidMount() {

        scrollToTop(0, 0);

        let url_parameters = getUrlParameters(this.props.location);
        if (url_parameters) {
            let { delivery, payment_type } = url_parameters
            this.setState({
                selected_delivery_option: delivery || "",
                selected_payment_option: payment_type || ""
            })
        }

        await this.getPaymentOptions();
        await this.getDeliveryOptions();

        await this.getCountries();
        await this.getAddresses();

        // this.getStates();
    }


    async setAddressValues(default_address, type) {

        let { id, first_name, last_name, country_id, state_id, postal_code, city, address_line_1, phone } = default_address

        if (type === "shipping_addresses") {
            await this.setState({
                first_name: "",
                last_name: "",
                country: "",
                state: "",
                postal_code: "",
                address_1: "",
                city: "",
                phone: "",
            })

            await this.setState({
                selected_shipping_address: id,
                first_name,
                last_name,
                country: country_id,
                state: state_id,
                postal_code,
                address_1: address_line_1,
                city,
                phone
            }, async () => {
                if (this.state.country) {
                    await this.getStates(this.state.country, "states")
                }

            })
        } else {
            await this.setState({
                selected_billing_address: "",
                billing_first_name: "",
                billing_last_name: "",
                billing_country: "",
                billing_state: "",
                billing_postal_code: "",
                billing_address_1: "",
                billing_city: "",
                billing_phone: ""
            })
            await this.setState({
                selected_billing_address: id,
                billing_first_name: first_name,
                billing_last_name: last_name,
                billing_country: country_id,
                billing_state: state_id,
                billing_postal_code: postal_code,
                billing_address_1: address_line_1,
                billing_city: city,
                billing_phone: phone

            }, async () => {
                if (this.state.country) {
                    await this.getStates(this.state.billing_country, "billing_states")
                }

            })
        }

    }


    getAddresses() {
        axiosConfig.get("/api/account-address")
            .then(res => {
                this.setState({
                    show_spinner: false
                })
                if (res.data.success) {
                    this.setState({
                        addresses: res.data.data,
                    }, () => {
                        let default_address = this.state.addresses.find(el => el.as_default === "Yes");
                        if (default_address) {
                            this.setAddressValues(default_address, "shipping_addresses")
                        }

                    })
                }
            })
            .catch(err => {
                handleError(err, this.toLogin)
            })
    }

    onChange(e) {
        if (e.target.type === "checkbox" || e.target.type === 'radio') {
            if (e.target.name === "selected_payment_option" || e.target.name === "selected_delivery_option") {
                this.setState({
                    [e.target.name]: e.target.value
                }, () => {
                    // this.props.history.push("?delivery=" + this.state.selected_delivery_option + "&payment_type=" + this.state.selected_payment_option)
                })
            } else {
                this.setState({
                    [e.target.name]: e.target.checked
                })
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })

            let arr = ["first_name", "phone", "billing_phone", "postal_code", "billing_postal_code", "city", "billing_city", "address_1", "billing_address_1", "billing_first_name"];
            if (arr.includes(e.target.name)) {
                if (e.target.value.length > 0) {
                    e.target.parentElement.classList.remove("has-error");
                }
                else {
                    // e.target.name != "phone" &&
                    e.target.parentElement.classList.add("has-error");
                }
            }
        }
    }

    paymentOptionsFields() {
        if (this.state.selected_payment_option === "Bank") {
            return <>
            </>
        }
        return <>
            <label className="text-uppercase">Cart Number</label>
            <div className="form-group">
                <input type="text"
                    onChange={this.onChange}
                    className="form-control form-control--sm" />
            </div>
            <div className="row">
                <div className="col-sm-9">
                    <label className="text-uppercase">Month:</label>
                    <div className="form-group select-wrapper">
                        <select className="form-control form-control--sm"
                            defaultValue={1}
                            onChange={this.onChange}
                            name="month"
                        >
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-9">
                    <label className="text-uppercase">Year:</label>
                    <div className="form-group select-wrapper">
                        <select className="form-control form-control--sm">
                            <option value={2019}>2019</option>
                            <option value={2020}>2020</option>
                            <option value={2021}>2021</option>
                            <option value={2022}>2022</option>
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <label className="text-uppercase">CVV Code</label>
                <div className="form-group">
                    <input type="text"
                        onChange={this.onChange}
                        className="form-control form-control--sm" />
                </div>
            </div>
        </>

    }

    removeAccountDetail(index) {

        let temp = [...this.state.account_details];
        let removed = temp.filter((el, t_index) => t_index !== index)
        this.setState({
            account_details: removed
        })

    }

    addAccountDetail() {
        let temp = [...this.state.account_details];
        temp.push({
            account_name: "",
            account_number: "",
            bank_name: "",
            sort_code: "",
            iban: "",
            bic_swift: '',
        })
        this.setState({
            account_details: temp
        })
    }

    onChangeAccountDetails(e, index) {

        let temp = [...this.state.account_details]
        if (e) {
            temp[index][e.target.name] = e.target.value;
        }
        this.setState({
            account_details: temp

        })
    }

    async handleSubmit(e) {
        e.preventDefault();
        // let validation_fields = ["first_name", "country", "state", "selected_delivery_option", "selected_payment_option"];
        let validation_fields = ["first_name", 'phone', "country", "state", "postal_code", "address_1", "city"];


        if (!this.state.same_as_shipping) {
            validation_fields.push("billing_first_name")
            validation_fields.push("billing_country")
            validation_fields.push("billing_state")
            validation_fields.push("billing_postal_code")
            validation_fields.push("billing_address_1")
            validation_fields.push("billing_phone")
            validation_fields.push("billing_city")

        }

        let arr = []
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })
        let validation_errors = inputValidation(arr);

        this.setState({
            validation_errors: validation_errors
        }, async () => {
            if (this.state.validation_errors.length !== 0) {
                let name = this.state.validation_errors[0].error_field
                let element = document.getElementsByName(name);
                if (element) {
                    let closest_from_element = element[0].closest(".form-group")
                    if (closest_from_element) {
                        let top_postion = getPosition(closest_from_element);
                        scrollToTop(top_postion)
                    }
                }
            }

            if ((!this.state.selected_delivery_option || !this.state.selected_payment_option) && (validation_errors.length === 0)) {
                // if (this.state.payment_options.length > 0 && this.state.delivery_options.length > 0) {
                if (!this.state.selected_delivery_option || (this.state.delivery_options.length === 0)) {

                    let element = document.getElementById("selected_delivery_option");
                    if (element) {
                        // let closest_from_element = element[0].closest(".form-group")
                        // if (closest_from_element) {
                        let top_postion = getPosition(element);
                        scrollToTop(top_postion)

                        if (validation_errors.length === 0) {
                            validation_errors.push(
                                { "error_field": "selected_delivery_option", "error": "The field is required" }

                            )
                            this.setState({
                                validation_errors
                            })
                        }
                        // }
                    }
                } else if (!this.state.selected_payment_option || (this.state.payment_options.length === 0)) {
                    let element = document.getElementById("selected_payment_option");
                    if (element) {
                        // let closest_from_element = element[0].closest(".form-group")
                        // if (closest_from_element) {
                        let top_postion = getPosition(element);
                        scrollToTop(top_postion)
                        // }
                    }

                    if (validation_errors.length === 0) {
                        validation_errors.push({ "error_field": "selected_payment_option", "error": "The field is required" })
                    }
                    this.setState({
                        validation_errors
                    })
                }
                // }else{
                //     if(this.state.selected_delivery_option.length === 0){
                //         alert('Delivery Option')
                //     }
                // }
            }

            if (validation_errors.length === 0) {
                let cart_items = [...this.props.cart_reducer]

                let total_cart_item_price = 0;

                cart_items.forEach(el => {
                    total_cart_item_price += (el.price * el.quantity)
                })

                if (this.state.same_as_shipping) {
                    let { first_name: billing_first_name, phone: billing_phone, last_name: billing_last_name, country: billing_country, state: billing_state, postal_code: billing_postal_code, address_1: billing_address_1, city: billing_city } = this.state
                    await this.setState({
                        billing_first_name,
                        billing_last_name,
                        billing_country,
                        billing_state,
                        billing_postal_code,
                        billing_address_1,
                        billing_city,
                        billing_phone,

                    })
                }

                let { first_name, last_name, country, state, postal_code, address_1, city,
                    save_address, same_as_shipping,

                    phone,
                    billing_phone,
                    billing_first_name,
                    billing_last_name,
                    billing_country, billing_state,
                    billing_postal_code,
                    billing_address_1,
                    billing_city,
                    selected_delivery_option,
                    // selected_payment_option,
                    cart_number,
                    cvv_code,
                    order_comment,
                } = this.state



                let data = {
                    shipping_first_name: first_name,
                    shipping_last_name: last_name,
                    shipping_country_id: country,
                    shipping_state_id: state,
                    shipping_postal_code: postal_code,
                    shipping_address_1: address_1,
                    city,
                    save_address,
                    phone,

                    // billing
                    same_as_shipping,
                    billing_first_name,
                    billing_last_name,
                    billing_country_id: billing_country,
                    billing_state_id: billing_state,
                    billing_postal_code,
                    billing_address_1,
                    billing_city,
                    billing_phone,

                    // 
                    delivery_id: selected_delivery_option,
                    payment_id: this.state.payment_options.find(el => el.payment_type === this.state.selected_payment_option)?.id || "",
                    cart_number,
                    month: document.getElementById("credit_card_month")?.value || "",
                    year: document.getElementById("credit_card_year")?.value || "",
                    cvv_code,
                    order_comment,
                    total_price: total_cart_item_price,

                    order_items: [...this.props.cart_reducer]
                }


                let url = "/api/checkout"

                this.setState({
                    in_process: true,
                })

                axiosConfig.post(url, data)
                    .then(res => {
                        this.setState({
                            in_process: false,
                        })
                        if (res.data.success) {
                            // this.setState({
                            //     alert_message: res.data.data + "" ,
                            //     alert_status: "success"
                            // })
                            // clear
                            this.getPointsSummary();
                            this.props.clearCart();
                            this.props.history.replace("/invoice?id=" + res.data.order_id);

                            setTimeout(() => {
                            }, 1000)
                        } else {
                            let messages = null;
                            try {
                                messages = JSON.parse(res.data.data);
                            }
                            catch {
                            }

                            let count = 0;
                            if (messages instanceof Object) {
                                var _message = '';

                                for (const [key, value] of Object.entries(messages)) {
                                    _message += `${value}`;
                                    count++;
                                    $("#" + key).closest('.form-group').addClass('has-error');
                                    $("#" + key).after(`<p class="invalid-feedback text-left"> ${value} </p>`);

                                }
                                if (count <= 1) {
                                    this.setState({
                                        alert_status: "error",
                                        alert_message: _message,
                                    })
                                }
                            }
                            else {
                                this.setState({
                                    alert_status: "danger",
                                    alert_message: res.data.data,
                                })
                            }
                        }

                    })
                    .catch(err => {
                        this.setState({
                            in_process: false,
                        })
                        handleError(err, this.toLogin)
                    })
                scrollToTop();
            }


        })



    }

    toLogin() {
        this.setState({
            redirect_to_login: true
        })
    }

    onChangeReactSelect(e, event) {
        if (e) {
            this.setState({
                [event.name]: e.value
            }, () => {
                if (event.name === "country") {
                    this.getStates(this.state.country, "states")
                } else if (event.name === "billing_country") {
                    this.getStates(this.state.billing_country, "billing_states")
                }


                if (e.value) {
                    document.getElementsByName([event.name])[0].parentElement.classList.remove("has-error")
                }
            })
        } else {
            this.setState({
                [event.name]: ""
            })
        }
    }

    async getStates(country_id, states) {
        axiosConfig.get("api/states/" + country_id)
            .then(async (res) => {
                if (res.data.success) {
                    await this.setState({
                        [states]: res.data.data,
                    })
                }
            })
            .catch(err => {

            })

    }

    getCreditCardYear() {
        let current_year = new Date().getFullYear();
        let j = 20;
        let temp = [];
        for (let i = 0; i < j; i++) {
            if (i < 10) {
                temp.push(current_year - (10 - i))
            } else {
                temp.push(current_year + (i - 10))
            }
        }
        return temp.map(el => {
            return <option value={el}>{el}</option>
        })

    }

    async getPointsSummary() {

        await axiosConfig.get(`/api/points-summary`)
            .then(res => {
                if (res.data.success) {
                    this.props.replacePoints(res.data.data);
                }
            })
            .catch(err => {
            })
    }

    async onChangeAddress(e, event) {
        if (e) {
            let selected_address = this.state.addresses.find(el => el.id === e.value);
            // if (event.name === "shipping_addresses") {
            if (selected_address) {
                await this.setAddressValues(selected_address, event.name)
                this.state.validation_errors.forEach(el => {
                    if (this.state[el.error_field]) {
                        document.getElementsByName(el.error_field)[0].parentElement.classList.remove("has-error")
                    }
                })
            }


        }

    }

    render() {


        if (!this.state.isLoggedIn) return <Redirect to="/cart" />


        if (this.state.show_spinner) {
            return <Spinner />
        }

        let cart_items = [...this.props.cart_reducer]


        if (cart_items.length === 0) {
            return <Redirect to="/cart" />
        }

        let total_cart_item_price = 0;

        cart_items.forEach(el => {
            total_cart_item_price += (el.price * el.quantity)
        })

        let cart_items_mapping = cart_items.map((el, index) => {
            return <div className="cart-table-prd" key={el.id}>
                <div className="cart-table-prd-image">

                    {/* <a href="#" className="prd-img">
                        <img className="lazyload fade-up" 
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" 
                        data-src="/images/skins/fashion/products/product-01-1.jpg" alt="" /></a> */}

                    <Link
                        to={`/product?id=${el.id}`}
                        className="prd-img pb-0" >
                        <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${el.image}')` }}>
                            <img src="/images/skins/fashion/products/product-01-1.jpg"
                                alt={el.name}
                                className="fade-up" />
                        </div>
                        {/* <div className="foxic-loader" /> */}
                        {/* <div className="prd-big-circle-labels">
                            <div className="label-new"><span>New</span></div>
                        </div> */}
                    </Link>

                </div>
                <div className="cart-table-prd-content-wrap">
                    <div className="cart-table-prd-info">
                        <h2 className="cart-table-prd-name">
                            <Link to={`/product?id=${el.id}`}>
                                {el.name}
                            </Link>
                        </h2>
                    </div>
                    <div className="cart-table-prd-qty">
                        <div className="qty qty-changer">
                            <input type="text" className="qty-input disabled"
                                value={el.quantity}
                                defaultValue={2}
                                data-min={0}
                                data-max={1000}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="cart-table-prd-price-total">
                        ${el.price}
                    </div>
                </div>
            </div>
        })

        let payment_options_mapping = this.state.payment_options.map(el => {
            return <div className="clearfix" key={el.id}>
                <input id={`payment-option-${el.id}`}
                    onChange={this.onChange}
                    name="selected_payment_option"
                    type="radio"
                    className="radio"
                    checked={this.state.selected_payment_option === el.payment_type}
                    value={el.payment_type}
                />
                <label style={{
                }} htmlFor={`payment-option-${el.id}`}>{el.payment_type}</label>
            </div>
        })

        let delivery_options_mapping = this.state.delivery_options.map(el => {
            return <div className="clearfix" key={el.id}>
                <input id={`delivery-option-${el.id}`}
                    onChange={this.onChange}
                    name="selected_delivery_option"
                    type="radio"
                    className="radio"
                    checked={this.state.selected_delivery_option === el.id}
                    value={el.id}
                />
                <label style={{
                }} htmlFor={`delivery-option-${el.id}`}>{el.name}&nbsp;${el.price}&nbsp;({el.delivery_time})</label>
            </div>
        })

        let country_options = this.state.countries.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })

        let country_options_value = country_options.filter(el => el.value === this.state.country)
        let billing_country_options_value = country_options.filter(el => el.value === this.state.billing_country)


        let state_options = this.state.states.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })
        let state_options_value = state_options.filter(el => el.value === this.state.state)


        let billing_state_options = this.state.billing_states.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })

        let billing_state_options_value = billing_state_options.filter(el => el.value === this.state.billing_state)

        let addresses_options = this.state.addresses.map(el => {
            return {

                label: el.first_name,
                value: el.id,
            }
        })
        let shipping_addresses_options_value = addresses_options.filter(el => el.value === this.state.selected_shipping_address);
        let billing_addresses_options_value = addresses_options.filter(el => el.value === this.state.selected_billing_address);

        return (
            <div className="page-content">
                <HelmetComponent
                    title="Checkout"
                />

                <BreadCrumb
                    title="Checkout"
                    parents={[
                        {
                            title: "Home",
                            route: "/",
                        },
                        // {
                        //     title: "Custom",
                        //     route: "/",
                        // },
                    ]}
                />
                <div className="holder">
                    <div className="container">
                        <h1 className="text-center">Checkout page</h1>
                        {
                            (this.state.alert_status !== '' && this.state.alert_message !== '')
                            &&
                            <AlertMessage
                                status={this.state.alert_status}
                                messages={this.state.alert_message}
                            />
                        }
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">

                                                <h2>Shipping Address</h2>
                                                <div className="col-md-9 px-0">
                                                    <Select
                                                        options={addresses_options}
                                                        value={shipping_addresses_options_value}
                                                        onChange={this.onChangeAddress}
                                                        name="shipping_addresses"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-sm-9">
                                                    <label className=" text-uppercase  required-input_star">First Name: </label>
                                                    <div className="form-group">
                                                        <input
                                                            id="shipping_first_name"
                                                            type="text"
                                                            className="form-control form-control--sm"
                                                            value={this.state.first_name}
                                                            name="first_name"
                                                            onChange={this.onChange}
                                                        />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.first_name}
                                                            error_field="first_name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className="text-uppercase">Last Name: </label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            onChange={this.onChange}
                                                            value={this.state.last_name}
                                                            name="last_name"
                                                            className="form-control form-control--sm" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2" />

                                            <label className=" text-uppercase  text-uppercase input_star required-input_star">Country: </label>
                                            <div className="form-group select-wrapper">
                                                <div className="validate_react_select country">
                                                    <Select
                                                        options={country_options}
                                                        value={country_options_value}
                                                        name="country"
                                                        onChange={this.onChangeReactSelect}
                                                    />
                                                    <InputValidationErrorMessage
                                                        validation_errors={this.state.validation_errors}
                                                        input_field={this.state.country}
                                                        error_field="country"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <label className=" text-uppercase  text-uppercase input_star required-input_star">State: </label>
                                                    <div className="form-group select-wrapper">
                                                        <div className="validate_react_select state">
                                                            <Select
                                                                options={state_options}
                                                                value={state_options_value}
                                                                name="state"
                                                                onChange={this.onChangeReactSelect}
                                                            />
                                                            <InputValidationErrorMessage
                                                                validation_errors={this.state.validation_errors}
                                                                input_field={this.state.state}
                                                                error_field="state"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className=" text-uppercase  required-input_star">Phone: </label>
                                                    <div className="form-group">
                                                        <input
                                                            onChange={this.onChange}
                                                            value={this.state.phone}
                                                            name="phone"
                                                            type="text" className="form-control form-control--sm" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.phone}
                                                            error_field="phone"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className=" text-uppercase  required-input_star">Zip / Postal Code: </label>
                                                    <div className="form-group">
                                                        <input
                                                            onChange={this.onChange}
                                                            value={this.state.postal_code}
                                                            name="postal_code"
                                                            type="text" className="form-control form-control--sm" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.postal_code}
                                                            error_field="postal_code"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className="text-uppercase required-input_star">City: </label>
                                                    <div className="form-group">
                                                        <input type="text"
                                                            onChange={this.onChange}
                                                            value={this.state.city}
                                                            name="city"
                                                            className="form-control form-control--sm" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.city}
                                                            error_field="city"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className=" text-uppercase  required-input_star ">Address 1: </label>
                                                    <div className="form-group">
                                                        <input type="text"
                                                            onChange={this.onChange}
                                                            value={this.state.address_1}
                                                            name="address_1"
                                                            className="form-control form-control--sm" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.address_1}
                                                            error_field="address_1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2" />

                                            <div className="clearfix">
                                                <input id="save_address"
                                                    onChange={this.onChange}
                                                    name="save_address" type="checkbox" />
                                                <label htmlFor="save_address">Save address to my account</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-body">
                                            {/* <h2 style={{
                                                zIndex: 0
                                            }}>Billing Address</h2> */}

                                            <div className="d-flex justify-content-between" style={{
                                                zIndex: 0,
                                            }}>

                                                <h2 style={{
                                                    zIndex: 0
                                                }}>Billing Address</h2>
                                                {
                                                    !this.state.same_as_shipping
                                                    &&
                                                    <div className="col-md-9 px-0">
                                                        <Select
                                                            options={addresses_options}
                                                            value={billing_addresses_options_value}
                                                            onChange={this.onChangeAddress}
                                                            name="billing_addresses"
                                                        />
                                                    </div>
                                                }
                                            </div>

                                            <div className="clearfix">
                                                <input id="same_as_shipping"
                                                    onChange={this.onChange}
                                                    checked={this.state.same_as_shipping}
                                                    name="same_as_shipping"
                                                    type="checkbox" />
                                                <label htmlFor="same_as_shipping">The same as shipping address</label>
                                            </div>
                                            {
                                                !this.state.same_as_shipping
                                                &&
                                                <>

                                                    <div className="mt-2" />
                                                    <div className="row">
                                                        <div className="col-sm-9 form-group">
                                                            <label className=" text-uppercase  required-input_star">First Name:</label>
                                                            <div className="">
                                                                <input
                                                                    id="billing_first_name"
                                                                    type="text"
                                                                    className="form-control form-control--sm"
                                                                    value={this.state.billing_first_name}
                                                                    name="billing_first_name"
                                                                    onChange={this.onChange}
                                                                />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.billing_first_name}
                                                                    error_field="billing_first_name"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-9 form-group">
                                                            <label className="text-uppercase">Last Name:</label>
                                                            <div className="">
                                                                <input
                                                                    type="text"
                                                                    onChange={this.onChange}
                                                                    value={this.state.billing_last_name}
                                                                    name="billing_last_name"
                                                                    className="form-control form-control--sm" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <label className=" text-uppercase  text-uppercase input_star required-input_star ">Country:</label>
                                                    <div className="form-group select-wrapper">
                                                        <div className="validate_react_select billing_country">
                                                            <Select
                                                                options={country_options}
                                                                value={billing_country_options_value}
                                                                name="billing_country"
                                                                onChange={this.onChangeReactSelect}
                                                            />
                                                            <InputValidationErrorMessage
                                                                validation_errors={this.state.validation_errors}
                                                                input_field={this.state.billing_country}
                                                                error_field="billing_country"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-9">
                                                            <label className=" text-uppercase  text-uppercase input_star required-input_star">State:</label>
                                                            <div className="form-group select-wrapper">
                                                                <div className="validate_react_select billing_state">
                                                                    <Select
                                                                        options={billing_state_options}
                                                                        value={billing_state_options_value}
                                                                        name="billing_state"
                                                                        onChange={this.onChangeReactSelect}
                                                                    />
                                                                    <InputValidationErrorMessage
                                                                        validation_errors={this.state.validation_errors}
                                                                        input_field={this.state.billing_state}
                                                                        error_field="billing_state"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <label className=" text-uppercase  required-input_star">Phone:</label>
                                                            <div className="form-group">
                                                                <input type="text"
                                                                    onChange={this.onChange}
                                                                    value={this.state.billing_phone}
                                                                    name="billing_phone"
                                                                    className="form-control form-control--sm" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.billing_phone}
                                                                    error_field="billing_phone" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <label className=" text-uppercase  required-input_star">Zip / Postal Code:</label>
                                                            <div className="form-group">
                                                                <input type="text"
                                                                    onChange={this.onChange}
                                                                    value={this.state.billing_postal_code}
                                                                    name="billing_postal_code"
                                                                    className="form-control form-control--sm" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.billing_postal_code}
                                                                    error_field="billing_postal_code" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <label className="text-uppercase required-input_star">City:</label>
                                                            <div className="form-group">
                                                                <input type="text"
                                                                    value={this.state.billing_city}
                                                                    name="billing_city"
                                                                    onChange={this.onChange}
                                                                    className="form-control form-control--sm" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.billing_city}
                                                                    error_field="billing_city"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <label className=" text-uppercase  required-input_star">Address 1:</label>
                                                            <div className="form-group">
                                                                <input type="text"
                                                                    value={this.state.billing_address_1}
                                                                    name="billing_address_1"
                                                                    onChange={this.onChange}
                                                                    className="form-control form-control--sm" />
                                                                <InputValidationErrorMessage
                                                                    validation_errors={this.state.validation_errors}
                                                                    input_field={this.state.billing_address_1}
                                                                    error_field="billing_address_1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2" />

                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 mt-2 mt-md-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <h2 className="required-input_star" id="selected_delivery_option">Delivery Methods {
                                                (this.state.validation_errors.find(el => el.error_field === "selected_delivery_option") && !this.state.selected_delivery_option)
                                                &&
                                                <small className="text-danger">{`( The field is required )`}</small>
                                            }</h2>
                                            {
                                                delivery_options_mapping
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-2" />
                                    <div className="card">
                                        <div className="card-body">
                                            <h2 className="required-input_star" id="selected_payment_option">Payment Methods {
                                                (this.state.validation_errors.find(el => el.error_field === "selected_payment_option") && !this.state.selected_payment_option)
                                                &&
                                                <small className="text-danger">{`( The field is required )`}</small>
                                            }</h2>
                                            {
                                                payment_options_mapping
                                            }

                                            <div className="mt-2" />
                                            {
                                                this.state.selected_payment_option === "Bank"
                                                &&
                                                <div className="">
                                                    <form>
                                                        <div className="form-group ">
                                                            {/* <label className=" text-uppercase  control-label "> Title</label> */}
                                                            <h3>
                                                                {
                                                                    this.state.payment_options.find(el => el.payment_type === "Bank")?.credentials?.title
                                                                }
                                                            </h3>
                                                            {/* <div className="">
                                                            <input type="text" className="form-control"
                                                                placeholder="Title"
                                                                name="title"
                                                                value={this.state.title}
                                                                onChange={this.onChange}
                                                            />
                                                        </div> */}
                                                        </div>
                                                        <div className="form-group ">
                                                            {/* <label className=" text-uppercase  control-label "> Description</label> */}
                                                            <p>
                                                                {
                                                                    this.state.payment_options.find(el => el.payment_type === "Bank")?.credentials?.bank_description
                                                                }
                                                            </p>
                                                            {/* <div className="">
                                                            <textarea className="form-control"
                                                                placeholder=""
                                                                rows="5"
                                                                name="bank_description"
                                                                value={this.state.bank_description}
                                                                onChange={this.onChange}
                                                            >
                                                            </textarea>
                                                        </div> */}
                                                        </div>
                                                        {/* <div className="form-group ">
                                                        <label className=" text-uppercase  control-label "> Instructions</label>
                                                        <div className="">
                                                            <textarea className="form-control"
                                                                placeholder=""
                                                                rows="5"
                                                                name="instructions"
                                                                value={this.state.instructions}
                                                                onChange={this.onChange}
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div> */}
                                                        {/* <div className="form-group ">
                                                        <label className=" text-uppercase  control-label "> Account Details</label>
                                                        <div className="">
                                                            <div className="table-responsiveee" >
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr className="">
                                                                            <th className="" style={{ width: "16%" }}>
                                                                                <div className="row justify-content-between">
                                                                                    <span>
                                                                                        Contents
                                                                                    </span>
                                                                                    <button type="button" className="btn btn-secondary"
                                                                                        onClick={this.addAccountDetail}
                                                                                    >
                                                                                        Add
                                                                                    </button>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            account_details
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    </form>
                                                </div>
                                            }
                                            {
                                                this.state.selected_payment_option === "Credit Card"
                                                &&
                                                <>
                                                    <label className="text-uppercase">Cart Number</label>
                                                    <div className="form-group">
                                                        <input type="text"
                                                            onChange={this.onChange}
                                                            className="form-control form-control--sm" />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-9">
                                                            <label className="text-uppercase">Month: </label>
                                                            <div className="form-group select-wrapper">
                                                                <select className="form-control form-control--sm"
                                                                    id="credit_card_month"
                                                                    defaultValue={1}
                                                                >
                                                                    <option value={1}>January</option>
                                                                    <option value={2}>February</option>
                                                                    <option value={3}>March</option>
                                                                    <option value={4}>April</option>
                                                                    <option value={5}>May</option>
                                                                    <option value={6}>June</option>
                                                                    <option value={7}>July</option>
                                                                    <option value={8}>August</option>
                                                                    <option value={9}>September</option>
                                                                    <option value={10}>October</option>
                                                                    <option value={11}>November</option>
                                                                    <option value={12}>December</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <label className="text-uppercase">Year: </label>
                                                            <div className="form-group select-wrapper">
                                                                <select className="form-control form-control--sm"
                                                                    id="credit_card_year"
                                                                    defaultValue={new Date().getFullYear()}
                                                                >
                                                                    {
                                                                        this.getCreditCardYear()
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <label className="text-uppercase">CVV Code</label>
                                                        <div className="form-group">
                                                            <input type="text"
                                                                name="cvv_code"
                                                                value={this.state.cvv_code}
                                                                onChange={this.onChange}
                                                                className="form-control form-control--sm" />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-2" />
                                    <div className="card">
                                        <div className="card-body">
                                            <h3>Order Comment</h3>
                                            <textarea
                                                className="form-control form-control--sm textarea--height-200"
                                                placeholder="Place your comment here"
                                                value={this.state.order_comment}
                                                name="order_comment"
                                                onChange={this.onChange}
                                            />
                                            <div className="card-text-info mt-2">
                                                <p>*Savings include promotions, coupons, rueBUCKS, and shipping (if applicable).</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3" />
                            <h2 className="custom-color" style={{
                                zIndex: 0,
                            }}>Order Summary</h2>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cart-table cart-table--sm pt-3 pt-md-0">
                                        <div className="cart-table-prd cart-table-prd--head py-1 d-none d-md-flex">
                                            <div className="cart-table-prd-image text-center">
                                                Image
                                            </div>
                                            <div className="cart-table-prd-content-wrap">
                                                <div className="cart-table-prd-info">Name</div>
                                                <div className="cart-table-prd-qty">Qty</div>
                                                <div className="cart-table-prd-price">Price</div>
                                            </div>
                                        </div>
                                        {
                                            cart_items_mapping
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 mt-2 mt-md-0">
                                    <div className="mt-2" />
                                    <div className="cart-total-sm">
                                        <span>Subtotal</span>
                                        <span className="card-total-price">$ {total_cart_item_price}</span>
                                    </div>
                                    <div className="clearfix mt-2">
                                        <button type="submit"
                                            // onClick={this.handleSubmit}
                                            disabled={this.state.in_process}
                                            className="btn btn--lg w-100">Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </div >

        )
    }
}



const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, incrementCartQuantity, decrementCartQuantity, clearCart, replacePoints
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(Checkout));
