import React, { Component } from 'react'
import _ from 'lodash';
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'


import { addToWishlist, removeFromWishlist } from '../../actions'
import axiosConfig from '../../axiosConfig';
import ProductVertical from '../ProductVertical';
import Spinner from '../../Spinner';

import QuickViewModal from '../../QuickViewModal';
import { handleError, scrollToTop } from '../../helper_funcitons';
class AccountWishlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            wishlisted_products: [],
            show_spinner: true,

            // modal
            show_quickview_modal: false,
            product_id: "",
        }
        this.getWishlistProduct = this.getWishlistProduct.bind(this)
        this.toggleQuickViewModal = this.toggleQuickViewModal.bind(this)
        this.toLogin = this.toLogin.bind(this)

    }

    toggleQuickViewModal(id, quick_view_product = {}) {
        this.setState({
            product_id: id,
            quick_view_product

        }, () => {
            this.setState((prev) => ({
                show_quickview_modal: !prev.show_quickview_modal
            }))
        })
    }
    removeFromWishlist(data) {
        this.props.removeFromWishlist(data)
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.wishlist_reducer, this.props.wishlist_reducer)) {
            this.getWishlistProduct(false);
        }
    }

    getWishlistProduct(show_spinner = true) {
        let url = "/api/product-wishlist"

        this.setState({
            show_spinner: show_spinner,
        })

        axiosConfig.get(url)
            .then(res => {

                this.setState({
                    show_spinner: false,
                })

                if (res.data.success) {
                    this.setState({
                        wishlisted_products: res.data.data,
                    })
                }
            })
            .catch(err => {
                handleError(err, this.toLogin)
            })
    }

    toLogin() {
        this.setState({
            redirect_to_login: true
        })
    }

    componentDidMount() {
        scrollToTop(0,0)
        this.getWishlistProduct();
    }

    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }}/>
        }

        let wishtlist_mapping = this.state.wishlisted_products.map(el => {
            return <ProductVertical key={el?.product_id || el?.id}
                toggleQuickViewModal={this.toggleQuickViewModal}
                product_data={el}
            />
        })

        return (
            <div className="col-md-18 aside">
                {
                    this.state.wishlisted_products.length > 0
                    &&
                    <h1 className="mb-3">My Wishlist</h1>
                }
                {/* <div className="empty-Wishlist js-empty-Wishlist text-center py-3 py-sm-5" >
                    <h3>Your Wishlist is empty</h3>
                    <div className="mt-5">
                        <a href="index.html" className="btn">Continue shopping</a>
                    </div>
                </div> */}
                <div className="prd-grid-wrap position-relative">
                    <div className="prd-grid prd-grid--Wishlist data-to-show-3 data-to-show-lg-3 data-to-show-md-2 data-to-show-sm-2 data-to-show-xs-1">
                        {
                            this.state.show_spinner ?
                                <Spinner />
                                :
                                wishtlist_mapping
                        }
                    </div>
                </div>
                {
                    (this.state.wishlisted_products.length === 0 && !this.state.show_spinner)
                    &&

                    <div className="col-lg aside">
                        <div className="prd-grid-wrap">
                            <div className="page404-bg">
                                <div className="page404-text">
                                    <div className="txt3"><i className="icon-shopping-bag" /></div>
                                    <div className="txt4">Your Wishlist is empty<br /><Link to="./">
                                        <span className="text-primary">
                                            Continue Shopping
                                        </span>
                                    </Link></div>
                                </div>
                                <svg className="blob" id="morphing" xmlns="http://www.w3.org/2000/svg" width={600} height={600} viewBox="0 0 600 600">
                                    <g transform="translate(50,50)">
                                        <path className="p" d="M93.5441 2.30824C127.414 -1.02781 167.142 -4.63212 188.625 21.7114C210.22 48.1931 199.088 86.5178 188.761 119.068C179.736 147.517 162.617 171.844 136.426 186.243C108.079 201.828 73.804 212.713 44.915 198.152C16.4428 183.802 6.66731 149.747 1.64848 118.312C-2.87856 89.9563 1.56309 60.9032 19.4066 38.3787C37.3451 15.7342 64.7587 5.14348 93.5441 2.30824Z" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>

                }
                {
                    this.state.show_quickview_modal
                    &&
                    <QuickViewModal
                        product_id={this.state.product_id}
                        toggleQuickViewModal={this.toggleQuickViewModal}
                        product_data={this.state.quick_view_product}

                    />
                }
            </div>


        )
    }
}




const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});


const mapDispatchToProps = () => {
    return {
        addToWishlist, removeFromWishlist
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(AccountWishlist);