import CartReducer from "./cart_reducer";
import WishlistReducer from "./wishlist_reducer";
import PointsReducer from "./points_reducer";

import { combineReducers } from "redux";

const allReducers = combineReducers({
    cart_reducer: CartReducer,
    wishlist_reducer: WishlistReducer,
    points_reducer: PointsReducer
})

export default allReducers;