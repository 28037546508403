import React, { Component } from 'react'
import BreadCrumb from '../BreadCrumb'
import { Link, Redirect, withRouter } from 'react-router-dom'
import QuickViewModal from '../../QuickViewModal'

// redux
import { connect } from 'react-redux'
import { incrementCart, decrementCart, incrementCartQuantity, decrementCartQuantity, clearCart } from '../../actions'
import ProductsRecommended from '../../ProductsRecommended'
import Spinner from '../../Spinner'
import HelmetComponent from '../../HelmetComponent'

class Cart extends Component {
    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.state = {
            isLoggedIn: appState ? appState.isLoggedIn : false,
            cart_items: [],
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,
            show_quickview_modal: false,
            quick_product_id: "",
            show_spinner: this.props.history.action === "POP",
        }

        // const { cart_items, updateValue } = this.props.context
        // this.cart_items = cart_items
        // this.updateValue = updateValue;

        this.increaseCartItem = this.increaseCartItem.bind(this)
        this.decreaseCartItem = this.decreaseCartItem.bind(this)
        this.clearCart = this.clearCart.bind(this)
        this.addToCart = this.addToCart.bind(this)
        this.toggleQuickViewModal = this.toggleQuickViewModal.bind(this)
    }

    toggleQuickViewModal(id, quick_view_product = {}) {
        this.setState({
            product_id: id,
            quick_view_product

        }, () => {
            this.setState((prev) => ({
                show_quickview_modal: !prev.show_quickview_modal
            }))
        })
    }

    addToCart(index) {
        let obj = {
            id: index,
            name: "custom name " + index,
            price: index * 100,
            quantity: 1,
        }
        this.props.incrementCart(obj);
    }
    increaseCartItem(data) {
        this.props.incrementCartQuantity(data);
    }

    decreaseCartItem(data) {
        this.props.decrementCartQuantity(data);
    }

    removeCartItem(data) {
        this.props.decrementCart(data);
    }

    clearCart() {
        this.props.clearCart();
    }

    componentDidMount() {
        setTimeout(() => {
            //to fix recommendation sliders from stacking 
            this.setState({
                show_spinner: false,
            })
        }, 1000)
    }

    render() {

        if (!this.state.isLoggedIn) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }} />
        }

        if (this.state.show_spinner) {
            return <Spinner />
        }

        let total_cart_item_price = 0;

        let cart_items = [...this.props.cart_reducer]

        cart_items.forEach(el => {
            total_cart_item_price += (el.price * el.quantity)
        })

        let cart_items_mapping = cart_items.map((el, index) => {
            return <div className="cart-table-prd">
                <div className="cart-table-prd-image">
                    {/* <a href="product.html" className="prd-img">
                        <img
                            className="lazyload fade-up"
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src="/images/skins/fashion/products/product-01-1.jpg"
                            alt="" />
                    </a> */}

                    <Link
                        to={`/product?id=${el.id}`}
                        className="prd-img pb-0" >
                        <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${el.image}')` }}>
                            <img src="/images/skins/fashion/products/product-01-1.jpg"
                                alt={el.name}
                                className="fade-up" />
                        </div>
                        {/* <div className="foxic-loader" /> */}
                        {/* <div className="prd-big-circle-labels">
                            <div className="label-new"><span>New</span></div>
                        </div> */}
                    </Link>


                </div>
                <div className="cart-table-prd-content-wrap">
                    <div className="cart-table-prd-info">
                        <div className="cart-table-prd-price">
                            {/* <div className="price-old">$ {el.price + 20}</div> */}
                            <div className="price-new">$ {this.state.isLoggedIn ? (el.price) : "**"}</div>
                        </div>
                        <h2 className="cart-table-prd-name">{el.name}</h2>
                    </div>
                    <div className="cart-table-prd-qty">
                        <div className="qty qty-changer">
                            <button className="decrease"
                                onClick={
                                    () => this.decreaseCartItem(index)
                                }
                            />
                            <input type="text" className="qty-input"
                                // defaultValue={2}
                                value={el.quantity}
                                data-min={0} data-max={1000} />
                            <button
                                type="button"
                                className="increase"
                                onClick={() => this.increaseCartItem(index)}

                            />
                        </div>
                    </div>
                    <div className="cart-table-prd-price-total">
                        $ {this.state.isLoggedIn ? (el.price * el.quantity) : "**"}
                    </div>
                </div>
                <div className="cart-table-prd-action">
                    {/* <a href="#" className="cart-table-prd-remove" data-tooltip="Remove Product"></a> */}
                    <button
                        type="button"
                        onClick={() => {
                            this.removeCartItem(index)
                        }}
                        className="cart-table-prd-remove p-0 border-0 bg-transparent"
                        data-tooltip="Remove Product"
                    >
                        <i className="icon-recycle" />
                    </button>
                </div>
            </div>
        })

        return (
            <div className="page-content">
                <HelmetComponent
                    title="Cart"
                />
                <BreadCrumb
                    title="Cart"
                    parents={[
                        {
                            title: "Home",
                            route: "/",
                        },
                    ]}
                />
                <div className="holder">
                    <div className="container">
                        {
                            cart_items.length > 0
                            &&
                            <div className="page-title text-center">
                                <h1>Shopping Cart </h1>
                            </div>
                        }
                        <div className="row">
                            <div className={`col-lg-11 col-xl-13 ${cart_items.length <= 0 && "mx-auto"} `}>
                                {
                                    cart_items.length > 0
                                        ?
                                        <>
                                            <div className="cart-table">

                                                <div className="cart-table-prd cart-table-prd--head py-1 d-none d-md-flex">
                                                    <div className="cart-table-prd-image text-center">
                                                        Image
                                                    </div>
                                                    <div className="cart-table-prd-content-wrap">
                                                        <div className="cart-table-prd-info">Name</div>
                                                        <div className="cart-table-prd-qty">Qty</div>
                                                        <div className="cart-table-prd-price">Price</div>
                                                        <div className="cart-table-prd-action">&nbsp;</div>
                                                    </div>
                                                </div>
                                                {
                                                    cart_items_mapping
                                                }
                                            </div>
                                        </>
                                        :
                                        <div className="minicart-empty js-minicart-empty">
                                            <div className="minicart-empty-text">Your cart is empty</div>
                                            <div className="minicart-empty-icon">
                                                <i className="icon-shopping-bag" />
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 306 262" style={{ enableBackground: 'new 0 0 306 262' }} xmlSpace="preserve">
                                                    <path className="st0" d="M78.1,59.5c0,0-37.3,22-26.7,85s59.7,237,142.7,283s193,56,313-84s21-206-69-240s-249.4-67-309-60C94.6,47.6,78.1,59.5,78.1,59.5z" />
                                                </svg>
                                            </div>
                                        </div>
                                }
                                {
                                    cart_items.length > 0
                                    &&

                                    <div className="text-center mt-1">
                                        <button
                                            type="button"
                                            className="btn btn--grey"
                                            onClick={() => this.clearCart()}
                                        >
                                            Clear All
                                        </button>
                                    </div>

                                }

                                {/* <div className="d-lg-blockkkkkk"> */}
                                {/* {
                                    this.state.show_products_recommended
                                    &&
                                    <> */}
                                <div className="mt-4" />
                                <div className="holder">
                                    <div className="container">
                                        <div className="title-wrap text-center">
                                            <h2 className="h1-style">You may also like</h2>
                                            <div className="carousel-arrows carousel-arrows--center" />
                                        </div>
                                        <ProductsRecommended
                                            toggleQuickViewModal={this.toggleQuickViewModal}
                                            showProductsRecommended={function () {
                                                this.setState({
                                                    show_products_recommended: true,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* </>
                                } */}
                                {/* </div> */}
                            </div>
                            {
                                cart_items.length > 0
                                &&
                                <div className="col-lg-7 col-xl-5 mt-3 mt-md-0">

                                    <div className="card-total">
                                        {/* <div className="text-right">
                                            <button className="btn btn--grey"><span>UPDATE CART</span><i className="icon-refresh" /></button>
                                        </div> */}
                                        <div className="row d-flex">
                                            <div className="col card-total-txt">Total</div>
                                            <div className="col-auto card-total-price text-right">$ {this.state.isLoggedIn ? total_cart_item_price : "**"}</div>
                                        </div>
                                        <Link to="/checkout">
                                            <button type='button' className="btn btn--full btn--lg mt-1"><span>Checkout</span></button>
                                        </Link>
                                        <div className="card-text-info text-right">
                                            <h5>Standart shipping</h5>
                                            <p><b>10 - 11 business days</b><br />1 item ships from the U.S. and will be delivered in 10 - 11 business days</p>
                                        </div>
                                    </div>
                                    <div className="mt-2" />
                                    <div className="panel-group panel-group--style1 prd-block_accordion" id="productAccordion">
                                        {/* <div className="panel">
                                            <div className="panel-heading active">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#productAccordion" href="#collapse1">Shipping options</a>
                                                    <span className="toggle-arrow"><span /><span /></span>
                                                </h4>
                                            </div>
                                            <div id="collapse1" className="panel-collapse collapse show">
                                                <div className="panel-body">
                                                    <label>Country:</label>
                                                    <div className="form-group select-wrapper select-wrapper-sm">
                                                        <select className="form-control form-control--sm">
                                                            <option value="United States">United States</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="China">China</option>
                                                            <option value="India">India</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Mexico">Mexico</option>
                                                        </select>
                                                    </div>
                                                    <label>State:</label>
                                                    <div className="form-group select-wrapper select-wrapper-sm">
                                                        <select className="form-control form-control--sm">
                                                            <option value="AL">Alabama</option>
                                                            <option value="AK">Alaska</option>
                                                            <option value="AZ">Arizona</option>
                                                            <option value="AR">Arkansas</option>
                                                            <option value="CA">California</option>
                                                            <option value="CO">Colorado</option>
                                                            <option value="CT">Connecticut</option>
                                                            <option value="DE">Delaware</option>
                                                            <option value="DC">District Of Columbia</option>
                                                            <option value="FL">Florida</option>
                                                            <option value="GA">Georgia</option>
                                                            <option value="HI">Hawaii</option>
                                                            <option value="ID">Idaho</option>
                                                            <option value="IL">Illinois</option>
                                                            <option value="IN">Indiana</option>
                                                            <option value="IA">Iowa</option>
                                                            <option value="KS">Kansas</option>
                                                            <option value="KY">Kentucky</option>
                                                            <option value="LA">Louisiana</option>
                                                        </select>
                                                    </div>
                                                    <label>Zip/Postal code:</label>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control form-control--sm" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="panel">
                                       <div className="panel-heading active">
                                           <h4 className="panel-title">
                                               <a data-toggle="collapse" data-parent="#productAccordion" href="#collapse2">Discount Code</a>
                                               <span className="toggle-arrow"><span /><span /></span>
                                           </h4>
                                       </div>
                                       <div id="collapse2" className="panel-collapse collapse show">
                                           <div className="panel-body">
                                               <p>Got a promo code? Then you're a few randomly combined numbers &amp; letters away from fab savings!</p>
                                               <div className="form-inline mt-2">
                                                   <input type="text" className="form-control form-control--sm" placeholder="Promotion/Discount Code" />
                                                   <button type="submit" className="btn">Apply</button>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="panel">
                                       <div className="panel-heading active">
                                           <h4 className="panel-title">
                                               <a data-toggle="collapse" data-parent="#productAccordion" href="#collapse3">Note for the seller</a>
                                               <span className="toggle-arrow"><span /><span /></span>
                                           </h4>
                                       </div>
                                       <div id="collapse3" className="panel-collapse collapse show">
                                           <div className="panel-body">
                                               <textarea className="form-control form-control--sm textarea--height-100" placeholder="Text here" defaultValue={""} />
                                               <div className="card-text-info mt-2">
                                                   <p>*Savings include promotions, coupons, rueBUCKS, and shipping (if applicable).</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div> */}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.show_quickview_modal
                    &&
                    <QuickViewModal
                        product_id={this.state.quick_product_id}
                        toggleQuickViewModal={this.toggleQuickViewModal}
                        product_data={this.state.quick_view_product}

                    />
                }
            </div >

        )
    }
}


const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, incrementCartQuantity, decrementCartQuantity, clearCart
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(Cart));

// export default WithUserConsumer(Cart);


