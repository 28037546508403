import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
// import { login, getProfile } from './UserFunctions.js';
// import Dashboard from './Dashboard/Index'
// import AlertMessage from './AlertMessage'
import AlertMessage from '../../AlertMessage';
import { inputValidation } from '../../helper_funcitons';
import InputValidationErrorMessage from '../../inputValidationErrorMessage';

import axiosConfig from '../../axiosConfig';

import HelmetComponent from '../../HelmetComponent'
import BreadCrumb from '../BreadCrumb';


class ForgotPasswordConfirm extends Component {
    constructor(props) {

        super(props);

        var appState = JSON.parse(localStorage.getItem('appState'))

        this.state = {
            // error_in: [],
            validation_errors: [],

            isLoggedIn: appState.isLoggedIn,
            alert_status: '',
            alert_message: '',
            redirect: props.location,
            email: '',
            password: '',
            confirm_password: ''
        }

        if (this.state.isLoggedIn === true) {
            this.props.history.push('/dashboard');
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })

        if (e.target.value.length > 0) {
            e.target.parentElement.classList.remove("has-error");
        }
        else {
            e.target.parentElement.classList.add("has-error");
        }
    }

    onSubmit(e) {
        e.preventDefault();

        this.setState({
            alert_message: '',
            alert_status: '',
        })

        // var validation_status = true;

        // var empty_fields = [];

        // function check_input_validation(input_state, target, error_field, error_message) {
        //     if (input_state === "") {
        //         empty_fields.push({ "error_field": error_field, "error": error_message });
        //         target.parentElement.classList.add("has-error");
        //         validation_status = false;
        //     }
        // }
        // check_input_validation(this.state.password, e.target.password, "password", "The password filed is required.");
        // check_input_validation(this.state.email, e.target.email, "email", "The email filed is required.");
        // check_input_validation(this.state.confirm_password, e.target.confirm_password, "confirm_password", "The confirm password filed is required.");

        // this.setState({
        //     error_in: empty_fields
        // });

        let validation_fields = ["email", "password", "confirm_password"];

        let arr = []
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })

        let validation_errors = inputValidation(arr);

        this.setState({
            validation_errors
        })


        let passwords_match = true;
        if (this.state.password !== this.state.confirm_password) {
            passwords_match = false;
            alert("Passwords do not match");
        }


        if ((validation_errors.length === 0) && passwords_match) {
            axiosConfig.post(`/api/resetPassword?resetToken=${this.props.location.search.substring(7)}&email=${e.target.email.value}&password=${e.target.password.value}&
                password_confirmation=${e.target.confirm_password.value}`)
                .then(res => {
                    if (res.data.success) {
                        this.setState({
                            redirect_to_login: true,
                        })
                    }
                })
        }
    }

    render() {
        if (this.state.isLoggedIn === true || this.state.redirect_to_login) {

            // window.location = '/dashboard'
            // die;
            return <Redirect to="/login" />
        }

        return (
            <div>
                <HelmetComponent
                    title="Reset Password"
                />

                <BreadCrumb
                    title="Reset Password"
                    parents={[
                        {
                            title: "Home",
                            route: "/",
                        },
                    ]}
                />

                <div className="holder">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-18 col-lg-12">

                                <h2 className="text-center">Reset Account Password</h2>
                                {
                                    (this.state.alert_status !== '' && this.state.alert_message !== '')
                                    &&
                                    <AlertMessage
                                        status={this.state.alert_status}
                                        messages={this.state.alert_message}
                                    />
                                }
                                <div className="form-wrapper">
                                    <form onSubmit={this.onSubmit}>
                                        <div>
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="email" placeholder="Email" value={this.state.email} onChange={this.onChange} />
                                                <InputValidationErrorMessage
                                                    validation_errors={this.state.validation_errors}
                                                    input_field={this.state.email}
                                                    error_field="email"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <input type="password" className="form-control" name="password" placeholder="Password" value={this.state.password} onChange={this.onChange} />
                                                <InputValidationErrorMessage
                                                    validation_errors={this.state.validation_errors}
                                                    input_field={this.state.password}
                                                    error_field="password"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <input type="password" className="form-control" name="confirm_password" placeholder="Confirm Password" value={this.state.confirm_password} onChange={this.onChange} />
                                                <InputValidationErrorMessage
                                                    validation_errors={this.state.validation_errors}
                                                    input_field={this.state.confirm_password}
                                                    error_field="confirm_password"
                                                />
                                            </div>
                                        </div>


                                        <div className="clearfix" />
                                        <div className="separator">

                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button type="submit" className="btn btn-outline-secondary submit">Reset Password</button>
                                            {/* <button type="button" className="btn btn-outline-primary ml-2" onClick={() => this.props.history.goBack()}>Back</button> */}
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default withRouter(ForgotPasswordConfirm);