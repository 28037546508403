import React, { Component } from 'react'
import BreadCrumb from '../BreadCrumb'
import { withRouter, Link } from 'react-router-dom'

import { inputValidation, scrollToTop } from '../../helper_funcitons';
import InputValidationErrorMessage from '../../inputValidationErrorMessage';
import AlertMessage from '../../AlertMessage';

import axiosConfig from '../../axiosConfig';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist, replaceWishlist, replaceCart, replacePoints } from '../../actions'
import HelmetComponent from '../../HelmetComponent';
class Login extends Component {
    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.state = {
            isLoggedIn: appState?.isLoggedIn || false,
            email: "",
            // email: "testing@testing.com",
            password: "",
            // password: "password",
            validation_errors: [],
            alert_message: "",
            alert_status: "",
            redirect_to_account_details: false,
            login_spinner: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value.length > 0) {
            e.target.parentElement.classList.remove("has-error");
        }
        else {
            e.target.parentElement.classList.add("has-error");
        }
    }

    async getWishList() {
        axiosConfig.defaults.headers.common = {
            'Authorization': 'Bearer ' + localStorage.userToken
        };
        await axiosConfig.get("/api/wishlists?token=" + localStorage.getItem('userToken'))
            .then(res => {
                if (res.data.success) {
                    this.props.replaceWishlist(res.data.data);
                }
            })
            .catch(err => {

            })
    }

    handleSubmit(e) {
        e.preventDefault()

        // pass the name from e.targe.name here. 
        let validation_fields = ["email", "password"];

        let arr = []
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })
        let validation_errors = inputValidation(arr);

        this.setState({
            validation_errors: validation_errors
        })

        if (validation_errors.length === 0) {

            this.setState({
                login_spinner: true,
            })

            let data = {
                email: this.state.email,
                password: this.state.password,
            }
            axiosConfig.post("/api/login", data)
                .then(res => {
                    this.setState({
                        login_spinner: false,
                    })

                    if (res.data.success) {
                        localStorage.setItem('userToken', res.data.token);
                        localStorage.setItem('appState', JSON.stringify({ isLoggedIn: true }));
                        this.props.updateParentUserDataState({ isLoggedIn: true })
                        this.setState({
                            redirect_to_account_details: true
                        }, () => {
                            this.getWishList();
                            this.getPointsSummary();
                        })
                    } else {
                        this.setState({
                            alert_message: res.data.message,
                            alert_status: "danger",
                        })
                    }

                })
                .catch(err => {
                    this.setState({
                        login_spinner: false,
                    })

                })
        }
    }
    async getPointsSummary() {

        await axiosConfig.get(`/api/points-summary`)
            .then(res => {
                if (res.data.success) {
                    this.props.replacePoints(res.data.data);
                }
            })
            .catch(err => {
            })
    }

    componentDidMount() {
        scrollToTop(0, 0)
        if (!this.state.isLoggedIn) {
            this.props.updateParentUserDataState({ isLoggedIn: false })
        }
    }

    render() {
        if (this.state.redirect_to_account_details || this.state.isLoggedIn) {
            if (this.props.location.state?.from && this.props.location.state?.from !== "/logout") {
                return <Redirect to={this.props.location.state?.from} />
            }
            else {
                return <Redirect to="/account-details" />
            }
        }

        return (
            <div className="page-content">
                <HelmetComponent
                    title="Login"
                />

                <BreadCrumb
                    title="Login"
                    parents={[
                        {
                            title: "Home",
                            route: "/",
                        },
                    ]}
                />
                <div className="holder">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-18 col-lg-12">

                                <h2 className="text-center">Login</h2>
                                {
                                    (this.state.alert_status !== '' && this.state.alert_message !== '')
                                    &&
                                    <AlertMessage
                                        status={this.state.alert_status}
                                        messages={this.state.alert_message}
                                    />
                                }
                                <div className="form-wrapper">
                                    {/* <p>To access your whishlist, address book and contact preferences and to take advantage of our speedy checkout, create an account with us now.</p> */}
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.onChange}
                                                className="form-control"
                                                placeholder="E-mail"
                                            />
                                            <InputValidationErrorMessage
                                                validation_errors={this.state.validation_errors}
                                                input_field={this.state.email}
                                                error_field="email"
                                            />

                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.onChange}
                                                className="form-control"
                                                placeholder="Password"
                                            />
                                            <InputValidationErrorMessage
                                                validation_errors={this.state.validation_errors}
                                                input_field={this.state.password}
                                                error_field="password"
                                            />
                                        </div>

                                        {/* <div className="clearfix">
                                        <input id="checkbox1" name="checkbox1" type="checkbox" defaultChecked="checked" />
                                        <label htmlFor="checkbox1">By registering your details you agree to our <a href="#" className="custom-color" data-fancybox data-src="#modalTerms">Terms and Conditions</a> and <a href="#" className="custom-color" data-fancybox data-src="#modalCookies">Cookie Policy</a></label>
                                    </div> */}
                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                className="btn"
                                                disabled={this.state.login_spinner}
                                            >
                                                Login</button>
                                        </div>
                                    </form>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Link to="/reset_password">
                                            Forgot Password?
                                        </Link>
                                        <p> Not a Member?  <Link to="/register">Sign Up</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}



const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});

const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist, replaceCart, replaceWishlist, replacePoints
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(Login));