import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom';
import axiosConfig from '../../axiosConfig';
import { handleError, scrollToTop, inputValidation } from '../../helper_funcitons';

class EditAccountRecharge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation_errors: [],
            redirect_to_login: false,

            linked_account: "",
            total_funds: "",
            files: [],

            
            merchant_app_site_url: process.env.REACT_APP_MERCHANT_API,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,

        }

        this.toLogin = this.toLogin.bind(this)
        this.onChange = this.onChange.bind(this)
        this.uploadVoucherFile = this.uploadVoucherFile.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.getAccountFundDetails = this.getAccountFundDetails.bind(this)
    }

    toLogin() {
        this.setState({
            redirect_to_login: true
        })
    }


    componentDidMount() {
        scrollToTop(0, 0)
        this.getAccountFundDetails()
    }

    getAccountFundDetails() {
        let url = "/api/points-data/edit/" + this.props.match.params.id
        axiosConfig.get(url)
            .then(res => {
                if (res.data.success) {
                    let { transaction_id, description, balance, transferred_balance, linked_account, files, credit_line_flow, created_at, approved_at } = res.data.data;
                    this.setState({
                        linked_account,
                        total_funds: transferred_balance,
                        files: files ? JSON.parse(files) : [],
                        credit_line_flow,
                        transaction_id,
                        balance,
                        description,
                        created_at: created_at,
                        completed_at: approved_at
                    })
                }

            })
            .catch(err => {
                handleError(err)
            })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === "total_funds") {
            if (e.target.value.length > 0) {
                e.target.parentElement.classList.remove("has-error");
            }
            else {
                e.target.parentElement.classList.add("has-error");
            }
        }

    }

    handleSave(e) {
        e.preventDefault();

        let validation_fields = [
            "linked_account",
            'total_funds',
        ];

        let arr = [];
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })

        let validation_errors = inputValidation(arr);

        if (this.state.files?.length === 0) {
            validation_errors.push({
                error: "",
                error_field: "files"
            })
        }

        this.setState({
            validation_errors: validation_errors
        })

        if (validation_errors.length === 0) {

            let url = "/api/points-request"

            var bodyFormData = new FormData();
            bodyFormData.append('description', this.state.description || "")
            bodyFormData.append('transferred_balance', this.state.total_funds || "")
            bodyFormData.append('linked_account', this.state.linked_account || "")

            if (this.state.files?.length > 0) {
                this.state.files?.forEach((el, index) => {
                    bodyFormData.append(`image_${index}`, this.state.files[index]);
                })
            }

            axiosConfig.post(url, bodyFormData)
                .then(res => {

                }).catch(err => {
                    handleError(err, this.toLogin)
                })
        }

    }

    uploadVoucherFile(e) {
        let new_files = [...e.target.files]
        let temp = [...this.state.files];
        let max_length = 5;
        new_files.some(el => {
            if (temp.length < max_length) {
                temp.push(el)
            } else {
                alert("Only 5 files allowed ")
            }
            return true;
        })

        this.setState({
            files: temp
        })
    }

    getLocalTime(db_date) {
        if (db_date) {
            //  2021-09-07 07:44:30 
            // 2021-09-07T07:42:44.000000Z  => UTC time

            if(!db_date.includes("Z")){
                db_date += " UTC";
            }
            
            let date = new Date(db_date);
            let result = date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear() +
                " " + (date.getHours().toString().length === 1 ? "0" + date.getHours().toString() : date.getHours()) +
                ":" + (date.getMinutes().toString().length === 1 ? "0" + date.getMinutes().toString() : date.getMinutes()) +
                ":" + (date.getSeconds().toString().length === 1 ? "0" + date.getSeconds().toString() : date.getSeconds());

            return result;
        }
    }

    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }}/>
        }

        let linked_account_validation = ''
        if (this.state.validation_errors.filter(el => el.error_field === "linked_account").length > 0 && (!this.state.linked_account)) {
            linked_account_validation = <span className="text-danger"> ( The field is required ) </span>
        }


        // let files_validation = ''
        // if (this.state.validation_errors.filter(el => el.error_field === "files").length > 0 && (this.state.files?.length === 0)) {
        //     files_validation = <span className="text-danger"> ( The field is required ) </span>
        // }

        return (
            <div className="col-md-18 aside">
                <h2 className="mb-2" id="account-balance-title">Account Funds Detail</h2>

                <div className="tab-content p-2" id="accountBalanceTabContent">
                    <div className="tab-pane fade show active p-sm-5" role="tabpanel" aria-labelledby="account-balance-tab">
                        <div className="row justify-content-between align-items-top  px-sm-5">
                            <div className="d-flex flex-column align-items-center" style={{
                                width: "60px"
                            }} >
                                <button type="button" className="btn-primary rounded-circle border-0"
                                    style={{
                                        height: "60px",
                                        width: "60px",
                                        background: "#5378f4",
                                    }}>
                                    <i className="icon-filter"></i>
                                </button>
                                <span className=" text-nowrap font-weight-bold" style={{
                                    color: "#5378f4",
                                }}>Submit</span>
                                <span className="d-none d-sm-block text-nowrap">{this.getLocalTime(this.state.created_at)}</span>
                                <span className="d-sm-none ">{this.getLocalTime(this.state.created_at)}</span>

                            </div>
                            <span className=""
                                style={{
                                    height: "3px",
                                    flexGrow: "1",
                                    backgroundColor: "#5378f4",
                                    marginTop: "30px",
                                }}>
                            </span>
                            <div className="d-flex flex-column align-items-center" style={{
                                width: "60px"
                            }}>
                                <button type="button" className="btn-primary rounded-circle border-0"
                                    style={{
                                        height: "60px",
                                        width: "60px",
                                        background: "#5378f4",
                                    }}>
                                    <i className="icon-filter"></i>
                                </button>
                                <span className="text-nowrap font-weight-bold" style={{
                                    color: "#5378f4",
                                }}>Processing</span>
                            </div>
                            <span className=""
                                style={{
                                    height: "3px",
                                    flexGrow: "1",
                                    marginTop: "30px",
                                    backgroundColor: this.state.completed_at ? "#5378f4" : "#6c757d",
                                }}>
                            </span>
                            <div className="d-flex flex-column align-items-center" style={{
                                width: "60px"
                            }}>
                                <button type="button" className="btn-primary rounded-circle border-0 -0"
                                    style={{
                                        height: "60px",
                                        width: "60px",
                                        background: this.state.completed_at ? "#5378f4" : "#6c757d",
                                    }}>
                                    <i className="icon-filter"></i>
                                </button>
                                <span className="text-nowrap font-weight-bold" style={{
                                    color: this.state.completed_at ? "#5378f4" : "#6c757d",
                                }}>Completed</span>
                                <span className="d-none d-sm-block text-nowrap">{this.getLocalTime(this.state.completed_at)}</span>
                                <span className="d-sm-none">{this.getLocalTime(this.state.completed_at)}</span>

                            </div>

                        </div>

                        <div className="d-flex justify-content-between mt-3 ">
                            <div className="text-center" style={{
                                borderBottom: "2px solid #5378f4",
                                width: "33%",
                            }}
                            >
                                <span className=" font-weight-bold text-nowrap" style={{
                                    color: "#5378f4"
                                }}>
                                    {this.state.completed_at ? "Account Funding Details" : "Processing"}
                                </span>

                            </div>
                            <span>
                            </span>
                            <span>
                            </span>

                        </div>
                        <div className="card mb-4 add-account-funds mt-0" style={{
                        }}>
                            <div className="card-body ">
                                <form onSubmit={this.handleSave} onReset={() => {
                                    this.setState({
                                        linked_account: "",
                                        total_funds: "",
                                        validation_errors: [],
                                        files: [],
                                    })
                                }}>
                                    <div className="col-md--">
                                        <div className="form-group row">
                                            <label htmlFor="linked_account" className="col-sm-6 col-form-label ">Linked Account {linked_account_validation}

                                            </label>
                                            <input type="text"
                                                className="form-control col-sm-12"
                                                value={this.state.linked_account}
                                            />

                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="linked_account" className="col-sm-6 col-form-label ">Transaction ID

                                            </label>
                                            <input type="text"
                                                className="form-control col-sm-12"
                                                value={this.state.transaction_id}
                                            />

                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="total_funds" className="col-sm-6 col-form-label ">Total Account Funds to be added</label>
                                            <input type="number"
                                                className="form-control col-sm-12"
                                                id="total_funds" name="total_funds"
                                                value={this.state.total_funds}
                                                onChange={this.onChange}
                                                placeholder="" step=".01" />
                                        </div>
                                        <div className="form-group row ">
                                            <label htmlFor="uploads" className="col-form-label col-sm-6 ">Bank Voucher Upload</label>
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    {
                                                        this.state.files.map((el, index) => {
                                                            // return <img src="https://picsum.photos/200/700"
                                                            return <img src={`${this.state.app_site_storage_path}${el}`}

                                                                style={{
                                                                    // width: "33%",
                                                                    height: "150px",
                                                                    width: "150px",

                                                                }}
                                                                className={`m-1`}
                                                                alt=''
                                                            />
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="upload-tip mb-5">5 pictures maximum, cannot exceed 30MB, supported files: .JPG, .PNG</div> */}
                                    </div>

                                    {/* <hr /> */}
                                    {/* 
                                    <div className="text-center mt-5">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button type="reset" className="btn btn-secondary ml-2">Clear</button>
                                    </div> */}

                                </form>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}


export default withRouter(EditAccountRecharge)
