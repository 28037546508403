import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getUrlParameters, scrollToTop } from '../../helper_funcitons'
import StoreHeader from './StoreHeader'
import StoreHome from './StoreHome'
import StoreProfile from './StoreProfile'
import axiosConfig from '../../axiosConfig'

class Store extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_storefront_id: this.props.match.params.id,
            selected_tab: "",

            // home
            sliders: [],
            profile_store_images: [],
            profile_contacts: [],
            new_arrivals: [],
            featured_products: [],
            featured_categories: [],
            profile_featured_categories: [],

            choose_us: [],
            home_choose_us_description: "",

            profile_description: "",



        }
        this.setSelectedTab = this.setSelectedTab.bind(this)
    }

    async setSelectedTab(selected_tab) {
        selected_tab = selected_tab || ""
        switch (selected_tab) {
            case "home": {
                this.setState({
                    selected_tab: "home"
                })
                break;
            }
            case "products": {
                this.setState({
                    selected_tab: "products"
                })
                break;
            }
            case "profile": {
                this.setState({
                    selected_tab: "profile"
                })
                break;
            }
            default:
                this.setState({
                    selected_tab: "home"
                })
        }
    }

    async componentDidMount() {
        scrollToTop()

        let url_parameters = getUrlParameters(this.props.location)
        console.log({ url_parameters })
        await this.setSelectedTab(url_parameters?.tab)

        this.getStorefrontData();

    }

    async getStorefrontData() {
        let url = "/api/storefront/edit/" + this.props.match.params.id
        await axiosConfig.get(url)
            .then(async (res) => {
                if (res.data.success) {
                    let { sliders, profile_store_images, new_arrivals, featured_products, featured_categories, profile_featured_categories, choose_us, home_choose_us_description, profile_description, profile_contacts } = res.data.page_value;
                    await this.setState({
                        sliders, profile_store_images, new_arrivals, featured_products, featured_categories, profile_featured_categories, choose_us, home_choose_us_description: home_choose_us_description, profile_description, profile_contacts
                    })
                }
            })
    }


    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {

            let old_url_parameters = getUrlParameters(prevProps.location)
            let new_url_parameters = getUrlParameters(this.props.location)

            if (old_url_parameters && new_url_parameters) {

                if (old_url_parameters.tab !== new_url_parameters.tab) {

                    this.setSelectedTab(new_url_parameters.tab)
                }


            }
        }
    }

    render() {
        return (
            <div className="holder mt-0">
                <StoreHeader
                    selected_storefront_id={this.state.selected_storefront_id}
                    selected_tab={this.state.selected_tab}
                />
                {
                    this.state.selected_tab === "home"
                    &&
                    <StoreHome
                        sliders={this.state.sliders}
                        new_arrivals={this.state.new_arrivals}
                        featured_products={this.state.featured_products}
                        featured_categories={this.state.featured_categories}
                        choose_us={this.state.choose_us}
                        choose_us_description={this.state.home_choose_us_description}
                        selected_storefront_id={this.state.selected_storefront_id}
                    />
                }
                {
                    this.state.selected_tab === "profile"
                    &&
                    <StoreProfile
                        profile_store_images={this.state.profile_store_images}
                        profile_featured_categories={this.state.profile_featured_categories}
                        profile_description={this.state.profile_description}
                        profile_contacts={this.state.profile_contacts}
                        selected_storefront_id={this.state.selected_storefront_id}
                    />
                }

            </div>
        )
    }
}

export default withRouter(Store)
