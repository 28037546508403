import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import Select from 'react-select'
import axiosConfig from '../../axiosConfig';

import InputValidationErrorMessage from '../../inputValidationErrorMessage';
import { handleError, inputValidation, scrollToTop, getPosition } from '../../helper_funcitons';
import Spinner from '../../Spinner';


export default class AccountAddresses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // in arry {countries_id}
            validation_errors: [],
            addresses: [
                // {
                //     country_id: "",
                //     state_id: "",
                //     city: "",
                //     postal_code: "",
                //     is_default: "",
                // }
            ],

            // add
            first_name: "",
            last_name: "",
            countries: [],
            country_id: "",
            states: [],
            state_id: "",
            city: "",
            postal_code: "",
            address_line_1: "",
            is_default: "",
            phone: "",

            address_mode: "",
            show_spinner: true,
        }

        this.getCountries = this.getCountries.bind(this)
        this.getStates = this.getStates.bind(this)
        this.alterAddress = this.alterAddress.bind(this)
        this.editAddress = this.editAddress.bind(this)
        this.getAddresses = this.getAddresses.bind(this)
        this.onChangeReactSelect = this.onChangeReactSelect.bind(this)
        this.onChange = this.onChange.bind(this)
        this.toLogin = this.toLogin.bind(this)

    }

    onChange(e) {

        if (e.target.type === "checkbox") {
            this.setState({
                [e.target.name]: e.target.checked
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })

            if (e.target.value.length > 0) {
                e.target.parentElement.classList.remove("has-error");
            }
            else {
                e.target.parentElement.classList.add("has-error");
            }
        }
    }

    getCountries() {
        axiosConfig.get("api/countries")
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        countries: res.data.data,
                    })
                }
            })
            .catch(err => {

            })
    }

    getStates(country_id) {
        axiosConfig.get("api/states/" + country_id)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        states: res.data.data,
                    })
                }
            })
            .catch(err => {

            })

    }

    getAddresses() {

        this.setState({
            show_spinner: true,
        })

        axiosConfig.get("/api/account-address")
            .then(res => {
                this.setState({
                    show_spinner: false
                })
                if (res.data.success) {
                    this.setState({
                        addresses: res.data.data,
                    })
                }
            })
            .catch(err => {
                handleError(err, this.toLogin)
            })
    }

    toLogin() {
        this.setState({
            redirect_to_login: true
        })
    }

    componentDidMount() {

        scrollToTop(0, 0)
        this.getCountries();
        this.getAddresses();
    }

    removeAddress(address_id) {

        let url = "/api/account-address/delete/" + address_id;

        axiosConfig.delete(url)
            .then(res => {
                if (res.data.success) {
                    this.getAddresses();
                }
            })
            .catch(err => {
                if (err.response.status === 500
                    && (err.response.data.message === 'Token has expired'
                        || err.response.data.message === 'Token Signature could not be verified.'
                        || err.response.data.message === 'Wrong number of segments')) {
                    let appState = {
                        isLoggedIn: false,
                    };
                    localStorage["appState"] = JSON.stringify(appState);

                    this.setState({
                        isLoggedIn: false
                    })

                    localStorage.removeItem('userToken')
                }

                if (err.response.status === 401 && err.response.data === "Unauthorized.") {
                    this.setState({
                        isLoggedIn: false,
                    })
                }

            })

    }

    alterAddress(e, alter_type) {
        e.preventDefault();

        let validation_fields = ["first_name", "phone", "country_id", "state_id", "city", "postal_code"];

        let arr = []
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })
        let validation_errors = inputValidation(arr);

        this.setState({
            validation_errors: validation_errors
        })

        if (validation_errors.length === 0) {

            let data = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                country_id: this.state.country_id,
                state_id: this.state.state_id,
                city: this.state.city,
                postal_code: this.state.postal_code,
                address_line_1: this.state.address_line_1,
                is_default: this.state.is_default ? true : false,
                phone: this.state.phone,
            }
            let url = "";
            let method = "get";

            if (alter_type === "edit_address") {
                url = "/api/account-address/update/" + this.state.edit_address_id;
                method = "put";
            } else if (alter_type === "add_address") {
                url = "/api/account-address/store";
                method = "post"
            }

            this.setState({
                show_spinner: true,
            })

            axiosConfig[method](url, data)
                .then(res => {
                    this.setState({
                        show_spinner: false,
                    })

                    if (res.data.success) {
                        if (alter_type === "edit_address") {
                            this.getAddresses();
                        } else {
                            this.setState({
                                first_name: "",
                                last_name: "",
                                country_id: "",
                                state_id: "",
                                city: "",
                                postal_code: "",
                                address_line_1: "",
                                is_default: "",
                                address_mode: "",
                                phone: "",
                            }, () => {
                                this.getAddresses();
                            })
                        }
                    }
                })
                .catch(err => {
                    if (err.response.status === 500
                        && (err.response.data.message === 'Token has expired'
                            || err.response.data.message === 'Token Signature could not be verified.'
                            || err.response.data.message === 'Wrong number of segments')) {
                        let appState = {
                            isLoggedIn: false,
                        };
                        localStorage["appState"] = JSON.stringify(appState);

                        this.setState({
                            isLoggedIn: false
                        })

                        localStorage.removeItem('userToken')
                    }

                    if (err.response.status === 401 && err.response.data === "Unauthorized.") {
                        this.setState({
                            // redirect_to_login: true,
                            isLoggedIn: false,
                        })
                    }

                })
        }
    }

    editAddress(e) {
        e.preventDefault();

        let validation_fields = ["first_name", "last_name", "phone", "country_id", "state_id", "city", "postal_code"];

        let arr = []
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })
        let validation_errors = inputValidation(arr);

        this.setState({
            validation_errors: validation_errors
        })

        if (validation_errors.length === 0) {

            let data = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                country_id: this.state.country_id,
                state_id: this.state.state_id,
                city: this.state.city,
                postal_code: this.state.postal_code,
                address_line_1: this.state.address_line_1,
                is_default: this.state.is_default ? true : false,
                phone: this.state.phone,
            }
            axiosConfig.put("/api/account-address/update/" + this.state.edit_address_id, data)
                .then(res => {
                    if (res.data.success) {
                        this.setState({
                            first_name: "",
                            last_name: "",
                            country_id: "",
                            state_id: "",
                            city: "",
                            postal_code: "",
                            address_line_1: "",
                            is_default: "",
                            address_mode: "",
                            phone: "",
                        }, () => {
                            this.getAddresses();
                        })
                    }
                })
                .catch(err => {
                    if (err.response.status === 500
                        && (err.response.data.message === 'Token has expired'
                            || err.response.data.message === 'Token Signature could not be verified.'
                            || err.response.data.message === 'Wrong number of segments')) {
                        let appState = {
                            isLoggedIn: false,
                        };
                        localStorage["appState"] = JSON.stringify(appState);

                        this.setState({
                            isLoggedIn: false
                        })

                        localStorage.removeItem('userToken')
                    }

                    if (err.response.status === 401 && err.response.data === "Unauthorized.") {
                        this.setState({
                            // redirect_to_login: true,
                            isLoggedIn: false,
                        })
                    }

                })
        }
    }

    onChangeReactSelect(e, event) {

        if (e) {
            this.setState({
                [event.name]: e.value
            }, () => {

                if (event.name === "country_id") {
                    this.getStates(this.state.country_id)
                }

                let group_select_grand_parent = document.querySelector(`.${[event.name]}`);
                group_select_grand_parent.querySelector(".has-error")?.classList.remove("has-error")

                // if (e.value.length > 0) {

                //     document.getElementsByName([event.name])[0].parentElement.classList.remove("has-error")
                //     // e.target.parentElement.classList.remove("has-error");

                // }
                // else {
                //     // e.target.parentElement.classList.add("has-error");
                //     // document.getElementsByName([event.name])[0].add("has-error")

                // }
            })
        } else {
            this.setState({
                [event.name]: ""
            })
        }
    }

    async changeEditAddress(address_id) {

        await this.setState({

            first_name: "",
            last_name: "",
            country_id: "",
            state_id: "",
            city: "",
            postal_code: "",
            address_line_1: "",
            is_default: "",
            phone: "",
        })

        let target = this.state.addresses.find(el => el.id === address_id);

        await this.setState({
            first_name: target.first_name,
            last_name: target.last_name,
            country_id: target.country_id,
            state_id: target.state_id,
            city: target.city,
            postal_code: target.postal_code,
            address_line_1: target.address_line_1,
            is_default: target.as_default === "Yes" ? true : false,
            address_mode: "edit_address",
            edit_address_id: address_id,
            phone: target.phone,
        }, () => {
            this.getStates(this.state.country_id);

            let elem = document.getElementById("edit_address_form")
            if (elem) {
                let position = getPosition(elem)
                scrollToTop(position)
            }

        })
    }



    render() {

        if (this.state.isLoggedIn === false || this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }} />
        }

        let addresses_mapping = this.state.addresses.map((el, index) => {
            return <div className="col-lg-9" key={el.id}>
                <div className="card mt-3">
                    <div className="card-body">
                        <h3>Address  {el.as_default === "Yes" && "(Default)"}</h3>
                        <p>{el.city}
                            <br /> {el.postal_code} {el.state_name}
                            <br /> {el.country_name}
                            <br /> {el.address_line_1}
                        </p>
                        <div className="mt-2 clearfix">
                            {/* <a href="#" className="link-icn js-show-form" data-form="#updateAddress"><i className="icon-pencil" />Edit</a> */}
                            {/* <a href="#" className="link-icn js-show-form" data-form="#updateAddress"></a> */}

                            <div className="row justify-content-between">
                                <button
                                    type="button"
                                    className="link-icn js-show-form border-0 bg-transparent"
                                    onClick={

                                        () => this.changeEditAddress(el.id)
                                    }
                                >
                                    <i className="icon-pencil" />Edit
                                </button>

                                {/* <a href="#" className="link-icn ml-1 float-right"> */}
                                <button
                                    type="button"
                                    className=" border-0 bg-transparent"
                                    onClick={
                                        () => this.removeAddress(el.id)
                                    }
                                >
                                    <i className="icon-cross" />Delete
                                </button>
                            </div>

                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </div >
        })

        let country_options = this.state.countries.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })

        let country_options_value = country_options.filter(el => el.value === this.state.country_id)

        let state_options = this.state.states.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })

        let state_options_value = state_options.filter(el => el.value === this.state.state_id)
        return (
            <div className="col-md-18 aside">
                <div className="row">
                    <h1 className="mb-0">My Addresses</h1>
                    <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        // onClick={this.alterAddress}
                        onClick={() => {
                            this.setState({
                                address_mode: "add_address",
                                first_name: "",
                                last_name: "",
                                country_id: "",
                                state_id: "",
                                city: "",
                                postal_code: "",
                                address_line_1: "",
                                is_default: "",
                            }, () => {
                                let elem = document.getElementById("add_address_form")
                                if (elem) {
                                    let position = getPosition(elem)
                                    scrollToTop(position)
                                }
                            })
                        }}
                    >
                        Add
                    </button>
                </div>
                {/* <Spinner /> */}

                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>

                {

                    this.state.show_spinner
                        ?
                        <Spinner />
                        :
                        <>
                            <div className="row">
                                {
                                    addresses_mapping
                                }
                                {/* <div className="col-sm-9 mt-2 mt-sm-0">
                        <div className="card">
                            <div className="card-body">
                                <h3>Address 2</h3>
                                <p>Yuto Murase 42 1
                                    <br /> Motohakone Hakonemaci Ashigarashimo
                                    <br /> Gun Kanagawa 250 05 JAPAN
                                </p>
                                <div className="mt-2 clearfix">
                                    <a href="#" className="link-icn js-show-form" data-form="#updateAddress"><i className="icon-pencil" />Edit</a>
                                    <a href="#" className="link-icn ml-1 float-right"><i className="icon-cross" />Delete</a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                            </div>
                            {
                                this.state.address_mode === "add_address"
                                &&
                                <div className="card mt-3" id="add_address_form">
                                    <form onSubmit={(e) => this.alterAddress(e, "add_address")}>
                                        <div className="card-body">
                                            <h3>Add Address</h3>
                                            <div className="row">
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase required-input_star">First Name:</label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="first_name"
                                                            value={this.state.first_name}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.first_name}
                                                            error_field="first_name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase">Last Name</label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="last_name"
                                                            value={this.state.last_name}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        {/* <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.last_name}
                                                            error_field="last_name"
                                                        /> */}
                                                    </div>
                                                </div>
                                                <div className="form-group col-sm-9">
                                                    <label className="text-uppercase input_star required-input_star">Country:</label>
                                                    <div className="form-group select-wrapper">
                                                        <div className="validate_react_select country_id">
                                                            <Select
                                                                options={country_options}
                                                                value={country_options_value}
                                                                name="country_id"
                                                                onChange={this.onChangeReactSelect}
                                                            />
                                                            <InputValidationErrorMessage
                                                                validation_errors={this.state.validation_errors}
                                                                input_field={this.state.country_id}
                                                                error_field="country_id"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-sm-9">
                                                    <label className="text-uppercase input_star required-input_star">State:</label>
                                                    <div className="form-group select-wrapper">
                                                        <div className="validate_react_select state_id">
                                                            <Select
                                                                options={state_options}
                                                                value={state_options_value}
                                                                name="state_id"
                                                                onChange={this.onChangeReactSelect}
                                                            />
                                                            <InputValidationErrorMessage
                                                                validation_errors={this.state.validation_errors}
                                                                input_field={this.state.state_id}
                                                                error_field="state_id"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase required-input_star">City:</label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="city"
                                                            value={this.state.city}
                                                            onChange={this.onChange}
                                                            className="form-control"
                                                        />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.city}
                                                            error_field="city"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase required-input_star">Phone: </label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="phone"
                                                            value={this.state.phone}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.phone}
                                                            error_field="phone"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase required-input_star">Zip / Postal Code: </label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="postal_code"
                                                            value={this.state.postal_code}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.postal_code}
                                                            error_field="postal_code"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase">Address Line 1</label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="address_line_1"
                                                            value={this.state.address_line_1}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="clearfix mt-1">
                                                <input
                                                    id="formCheckbox1"
                                                    name="is_default"
                                                    // value={this.state.is_default}
                                                    onChange={this.onChange}
                                                    type="checkbox"
                                                    checked={this.state.is_default ? true : false}
                                                />
                                                <label htmlFor="formCheckbox1">Set address as default</label>
                                            </div>
                                            <div className="mt-2 row col">
                                                <button type="reset" className="btn btn--alt js-close-form" data-form="#updateAddress">Cancel</button>
                                                <button type="submit" className="btn ml-1 ">Add Address</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                            {
                                this.state.address_mode === "edit_address"
                                &&
                                <div className="card mt-3 " id="edit_address_form"
                                >
                                    <form onSubmit={(e) => this.alterAddress(e, "edit_address")}>
                                        <div className="card-body">
                                            <h3>Edit Address</h3>
                                            <div className="row">
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase">First Name:</label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="first_name"
                                                            value={this.state.first_name}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.first_name}
                                                            error_field="first_name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase">Last Name</label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="last_name"
                                                            value={this.state.last_name}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        {/* <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.last_name}
                                                            error_field="last_name"
                                                        /> */}
                                                    </div>
                                                </div>
                                                <div className="form-group col-sm-9">

                                                    <label className="text-uppercase input_star">Country:</label>
                                                    <div className="form-group select-wrapper">
                                                        <div className="validate_react_select country_id">
                                                            <Select
                                                                options={country_options}
                                                                value={country_options_value}
                                                                name="country_id"
                                                                onChange={this.onChangeReactSelect}
                                                            />
                                                            <InputValidationErrorMessage
                                                                validation_errors={this.state.validation_errors}
                                                                input_field={this.state.country_id}
                                                                error_field="country_id"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase input_star">State:</label>
                                                    <div className="form-group select-wrapper">
                                                        <div className="validate_react_select state_id">
                                                            <Select
                                                                options={state_options}
                                                                value={state_options_value}
                                                                name="state_id"
                                                                onChange={this.onChangeReactSelect}
                                                            />
                                                            <InputValidationErrorMessage
                                                                validation_errors={this.state.validation_errors}
                                                                input_field={this.state.state_id}
                                                                error_field="state_id"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase">City:</label>
                                                    <div className="">
                                                        <input
                                                            type="text"
                                                            name="city"
                                                            value={this.state.city}
                                                            onChange={this.onChange}
                                                            className="form-control"
                                                        />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.city}
                                                            error_field="city"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase">Phone:</label>
                                                    <div className="">
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            value={this.state.phone}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.phone}
                                                            error_field="phone"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase">zip/postal code:</label>
                                                    <div className="">
                                                        <input
                                                            type="text"
                                                            name="postal_code"
                                                            value={this.state.postal_code}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.postal_code}
                                                            error_field="postal_code"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 form-group">
                                                    <label className="text-uppercase">Address Line 1</label>
                                                    <div className="">
                                                        <input type="text"
                                                            name="address_line_1"
                                                            value={this.state.address_line_1}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="clearfix mt-1">
                                                <input
                                                    id="formCheckbox1"
                                                    name="is_default"
                                                    value={this.state.is_default}
                                                    onChange={this.onChange}
                                                    type="checkbox"
                                                    checked={this.state.is_default ? true : false}
                                                />
                                                <label htmlFor="formCheckbox1">Set address as default</label>
                                            </div>
                                            <div className="mt-2">
                                                <button type="reset" className="btn btn--alt js-close-form" data-form="#updateAddress">Cancel</button>
                                                <button type="submit" className="btn ml-1">Edit Address</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </>
                }
            </div>

        )
    }
}
