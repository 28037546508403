import React, { Component } from 'react'
import Helmet from 'react-helmet'

class HelmetComponent extends Component {

    render() {
        return (
            <Helmet>
                <meta name="content" content={this.props.content} />
                <meta name="description" content={this.props.description} />
                <meta name="keywords" content={this.props.keywords} />
                {
                    this.props.title
                    &&
                    <title>{this.props.title}</title>
                }
            </Helmet>
        )
    }
}

export default HelmetComponent