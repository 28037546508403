import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist } from '../actions'

import { getMainImage } from '../helper_funcitons'


class AddToCartButton extends Component {
    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.state = {
            isLoggedIn: appState?.isLoggedIn || false,
            redirect_to_login: false,
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,

        }
        this.addToCart = this.addToCart.bind(this)
    }

    addToCart(el) {

        if (this.state.isLoggedIn) {
            let obj = {
                id: el.id,
                name: el.name,
                price: el.price,
                quantity: 1,
                image: getMainImage(el.image)
            }
            this.props.incrementCart(obj);
        } else {
            this.setState({
                redirect_to_login: true,
            })
        }
    }

    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }} />
        }



        return (
            <button className="btn js-prd-addtocart"
                type="button"
                // data-product="{&quot;name&quot;: &quot;Window Air Conditioner&quot;, &quot;path&quot;:&quot;images/skins/electronics/products/product-07-1.jpg&quot;, &quot;url&quot;:&quot;product.html&quot;, &quot;aspect_ratio&quot;:0.778}"
                data-product={this.state.isLoggedIn ? `{"name": "${this.props.name ? this.props.name.replaceAll('"', " ").replaceAll(",", " ") : ""}", "path":"${this.state.app_site_storage_path}${this.props.image}", "url":"/products?id=${this.props.id}", "aspect_ratio":0.778}` : undefined}

                onClick={() => {
                    this.addToCart(this.props.product_data)
                }}
            >
                Add To Cart
            </button>
        )
    }
}


const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(AddToCartButton));

