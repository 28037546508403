import React, { Component } from 'react'
import axiosConfig from './axiosConfig'
import { Link, withRouter, Redirect } from 'react-router-dom'


import { connect } from 'react-redux'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist } from './actions'
import WishListButton from './components/WishListButton'
import Spinner from './Spinner'
import { getMainImage, getParsedDiscription } from './helper_funcitons'

class QuickViewModal extends Component {

    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }


        this.state = {
            isLoggedIn: appState ? appState.isLoggedIn : false,

            // modal
            position: "fixed",
            display: "",
            // show_spinner: true,
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,
            sku: "",
            name: "",
            description: "",
            price: "",
            product_quantity: "",
            quantity: 1, // quantity to be added to cart
            image: "",
            product_data: {},

            redirect_to_login: false,

        }
        this.closeModal = this.closeModal.bind(this)
        this.changeQuantity = this.changeQuantity.bind(this)
        this.addToCart = this.addToCart.bind(this)
    }

    async componentDidMount() {
        // this.getProductData();/

        let { name, description, short_description, price, image, sku, total_qty } = this.props.product_data

        let main_image = getMainImage(image)
        await this.setState({
            product_data: this.props.product_data,
            product_quantity: total_qty,
            sku,
            name,
            description: getParsedDiscription(description),
            short_description: getParsedDiscription(short_description),
            price,
            image: main_image
        })
    }
    changeQuantity(type) {
        if (type === 'add') {
            this.setState((prev) => ({
                quantity: parseInt(prev.quantity) + 1
            }))
        } else {
            if (this.state.quantity !== 1) {

                this.setState((prev) => ({
                    quantity: parseInt(prev.quantity) - 1
                }))
            }
        }
    }

    addToCart(el) {
        if (this.state.isLoggedIn) {

            let obj = {
                id: el.id,
                name: el.name,
                price: el.price,
                quantity: this.state.quantity,
                image: getMainImage(el.image)
            }
            this.props.incrementCart(obj);
            this.setState({
                quantity: 1,
            })
        } else {
            this.setState({
                redirect_to_login: true,
            })
        }
    }

    getProductData() {
        axiosConfig.get("/api/product/edit/" + this.props.product_id)
            .then(async (res) => {

                if (res.data.success) {
                    let { name, description, short_description, price, image, sku, total_qty } = res.data.data;

                    let main_image = getMainImage(image)
                    await this.setState({
                        product_data: res.data.data,
                        product_quantity: total_qty,
                        sku,
                        name,
                        description: getParsedDiscription(description),
                        short_description: getParsedDiscription(short_description),
                        price,
                        image: main_image
                    })
                }

                this.setState({
                    show_spinner: false
                })
            })
            .catch(err => {
                this.setState({
                    show_spinner: false
                })
            })
    }

    closeModal(e, force = false) {
        if (!e.target.closest("#prdGalleryModal") || force) {
            this.setState({
                position: "unset",
                display: "none",
                show_spinner: true,
            }, () => {
                this.props.toggleQuickViewModal();
            })
        }
    }

    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }} />
        }


        if (this.state.show_spinner) {
            return <Spinner />
        }

        return (
            <div className="fancybox-container fancybox-is-open" id="fancybox-container-2"
                style={{
                    position: this.state.position,
                    transitionDuration: '366ms'
                }}
                onClick={this.closeModal}>
                <div className="fancybox-bg" />
                <div className="fancybox-inner">
                    <div className="fancybox-stage">
                        <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete" style={{}}>
                            <div className="fancybox-container fancybox-is-closed" id="fancybox-container-6" style={{ transitionDuration: '366ms' }}>
                                <div className="fancybox-bg" /><div className="fancybox-inner"><div className="fancybox-stage">
                                    <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete" style={{}}>


                                        <div className="modal--quickview modal-quickview--classic fancybox-content" id="modalQuickView"
                                            style={{
                                                display: this.state.display
                                            }}
                                        >
                                            <div className="modal-content">
                                                <div className="prd-block prd-block--prv-bottom" id="prdGalleryModal">
                                                    <div className="row no-gutters" style={{
                                                        // minHeight: "95vh",
                                                        overflowY: "scroll"
                                                    }}>
                                                        <div className="col-lg-9 quickview-gallery">
                                                            <div className="prd-block_main-image mt-0">
                                                                <div className="prd-block_main-image-holder">
                                                                    <div className="product-main-carousel js-product-main-carousel-qw js-product-main-zoom-container" data-zoom-position="inner">
                                                                        <div data-value="Beige">
                                                                            <span className="prd-img" style={{
                                                                                paddingBottom: 0,
                                                                                paddingTop: "30px"
                                                                            }} >
                                                                                {/* <img
                                                                                    src={`${this.state.app_site_url}${this.state.app_site_storage_path}${this.state.image}`}
                                                                                    // data-src="/images/skins/fashion/product-page/product-01.jpg"
                                                                                    className="lazyload fade-up elzoom"
                                                                                    alt=""
                                                                                    data-zoom-image="/images/skins/fashion/product-page/product-01.jpg"
                                                                                /> */}
                                                                                <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${this.state.image}')` }}>
                                                                                    <img src="/images/skins/electronics/products/product-07-1.jpg"
                                                                                        alt={this.state.name}
                                                                                        className="js-prd-img" />
                                                                                </div>
                                                                            </span>



                                                                            {/* <span className="prd-img">
                                                                                <img data-src="/images/skins/fashion/product-page/product-01.jpg" className="lazyload fade-up elzoom"
                                                                                    alt="" data-zoom-image="/images/skins/fashion/product-page/product-01.jpg" />
                                                                            </span> */}
                                                                        </div>
                                                                        <div data-value="Beige"><span className="prd-img"><img data-src="/images/skins/fashion/product-page/product-02.jpg" className="lazyload fade-up elzoom" alt="" data-zoom-image="/images/skins/fashion/product-page/product-02.jpg" /></span></div>
                                                                        <div className="inner-video js-inner-video">
                                                                            <video preload="metadata" controls="controls" playsInline="playsinline">
                                                                                <source src="/images/skins/fashion/product-page/product-video.mp4" type="video/mp4" />
                                                                            </video>
                                                                        </div>
                                                                        <div data-value="Beige"><span className="prd-img"><img data-src="/images/skins/fashion/product-page/product-03.jpg" className="lazyload fade-up elzoom" alt="" data-zoom-image="/images/skins/fashion/product-page/product-03.jpg" /></span></div>
                                                                        <div data-value="Black"><span className="prd-img"><img data-src="/images/skins/fashion/product-page/product-04.jpg" className="lazyload fade-up elzoom" alt="" data-zoom-image="/images/skins/fashion/product-page/product-04.jpg" /></span></div>
                                                                        <div data-value="Black"><span className="prd-img"><img data-src="/images/skins/fashion/product-page/product-05.jpg" className="lazyload fade-up elzoom" alt="" data-zoom-image="/images/skins/fashion/product-page/product-05.jpg" /></span></div>
                                                                        <div data-value="Black"><span className="prd-img"><img data-src="/images/skins/fashion/product-page/product-06.jpg" className="lazyload fade-up elzoom" alt="" data-zoom-image="/images/skins/fashion/product-page/product-06.jpg" /></span></div>
                                                                        <div data-value="Red"><span className="prd-img"><img data-src="/images/skins/fashion/product-page/product-07.jpg" className="lazyload fade-up elzoom" alt="" data-zoom-image="/images/skins/fashion/product-page/product-07.jpg" /></span></div>
                                                                        <div data-value="Red"><span className="prd-img"><img data-src="/images/skins/fashion/product-page/product-08.jpg" className="lazyload fade-up elzoom" alt="" data-zoom-image="/images/skins/fashion/product-page/product-08.jpg" /></span></div>
                                                                        <div data-value="Red"><span className="prd-img"><img data-src="/images/skins/fashion/product-page/product-09.jpg" className="lazyload fade-up elzoom" alt="" data-zoom-image="/images/skins/fashion/product-page/product-09.jpg" /></span></div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="prd-block_viewed-wrap">
                                                                    <div className="prd-block_viewed">
                                                                        <i className="icon-watch" />
                                                                        <span><span className="quickview-hidden">This product was</span>Viewed 25 times within 24 hours</span>
                                                                    </div>
                                                                    <div className="prd-block_viewed prd-block_viewed--real-time">
                                                                        <div className="prd-block_visitiors">Real time <span className="prd-block_visitiors-count js-visitors-now" data-vmax={100} data-vmin={10}>21</span> visitor right now!</div>
                                                                        <i className="icon-user" />
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <div className="product-previews-wrapper d-none">
                                                                <div className="product-previews-carousel js-product-previews-carousel-qw" data-slick="{&quot;slidesToShow&quot;: 3, &quot;responsive&quot;: [{&quot;breakpoint&quot;: 992,&quot;settings&quot;: {&quot;slidesToShow&quot;: 3 }},{&quot;breakpoint&quot;: 480,&quot;settings&quot;: {&quot;slidesToShow&quot;: 3 }}]}">
                                                                    {/* <a href="#" data-value="Beige"><img src="/images/skins/fashion/product-page/product-01.jpg" alt="" /></a>
                                                                    <a href="#" data-value="Beige"><img src="/images/skins/fashion/product-page/product-02.jpg" alt="" /></a>
                                                                    <a href="#" className="prd-block_video-link video-slide">
                                                                        <span><span><i className="icon icon-play" /><img src="/images/skins/fashion/product-page/product-video.jpg" alt="" /></span></span>
                                                                    </a>
                                                                    <a href="#" data-value="Beige"><img src="/images/skins/fashion/product-page/product-03.jpg" alt="" /></a>
                                                                    <a href="#" data-value="Black"><img src="/images/skins/fashion/product-page/product-04.jpg" alt="" /></a>
                                                                    <a href="#" data-value="Black"><img src="/images/skins/fashion/product-page/product-05.jpg" alt="" /></a>
                                                                    <a href="#" data-value="Black"><img src="/images/skins/fashion/product-page/product-06.jpg" alt="" /></a>
                                                                    <a href="#" data-value="Red"><img src="/images/skins/fashion/product-page/product-07.jpg" alt="" /></a>
                                                                    <a href="#" data-value="Red"><img src="/images/skins/fashion/product-page/product-08.jpg" alt="" /></a>
                                                                    <a href="#" data-value="Red"><img src="/images/skins/fashion/product-page/product-09.jpg" alt="" /></a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 quickview-info custom " style={{
                                                            // height:"auto"
                                                        }}>
                                                            <div className="prd-block_info prd-block_info--style2" style={{
                                                            }}>
                                                                <div className="prd-block_title-wrap">
                                                                    <h1 className="prd-block_title">{this.state.name}</h1>
                                                                </div>
                                                                <div className="prd-block_price">
                                                                    <div className="prd-block_price--actual">${this.state.price}</div>
                                                                    {/* <div className="prd-block_price-old-wrap"> */}
                                                                    {/* <span className="prd-block_price--old">$184</span> */}
                                                                    {/* <span className="prd-block_price--text">You Save: <span>$30</span> (<span>16</span>%)</span> */}
                                                                    {/* </div> */}
                                                                </div>
                                                                <div className="prd-block_description prd-block_info_item">

                                                                    <p id="" dangerouslySetInnerHTML={{ __html: this.state.short_description }}>
                                                                    </p>
                                                                    <div className="mt-1" />
                                                                    {/* <div className="row vert-margin-less">
                                                                        <div className="col-sm">
                                                                            <ul className="list-marker">
                                                                                <li>100% Polyester</li>
                                                                                <li>Lining:100% Viscose</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="col-sm">
                                                                            <ul className="list-marker">
                                                                                <li>Do not dry clean</li>
                                                                                <li>Only non-chlorine</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                                <div className="prd-block_options mt-0">
                                                                    {/* <div className="prd-color swatches">
                                                                        <div className="option-label">Color:</div>
                                                                        <select className="form-control hidden single-option-selector-modalQuickView" id="SingleOptionSelector-2" data-index="option1">
                                                                            <option value="Beige" selected="selected">Beige</option>
                                                                            <option value="Black">Black</option>
                                                                            <option value="Red">Red</option>
                                                                        </select>
                                                                        <ul className="/images-list js-size-list" data-select-id="SingleOptionSelector-2">
                                                                            <li className="active">
                                                                                <a href="#" data-value="Beige" data-toggle="tooltip" data-placement="top" data-original-title="Beige"><span className="image-container image-container--product"><img src="/images/skins/fashion/product-page/product-01.jpg" alt=""/></span></a>
                                                                            </li><li>
                                                                            </li><li>
                                                                                <a href="#" data-value="Black" data-toggle="tooltip" data-placement="top" data-original-title="Black"><span className="image-container image-container--product"><img src="/images/skins/fashion/product-page/product-04.jpg" alt=""/></span></a>
                                                                            </li><li>
                                                                            </li><li>
                                                                                <a href="#" data-value="Red" data-toggle="tooltip" data-placement="top" data-original-title="Red"><span className="image-container image-container--product"><img src="/images/skins/fashion/product-page/product-07.jpg" alt=""/></span></a>
                                                                            </li>
                                                                        </ul>
                                                                    </div> */}
                                                                    <div className="prd-size swatches">
                                                                        <div className="option-label">Size:</div>
                                                                        <select className="form-control hidden single-option-selector-modalQuickView" id="SingleOptionSelector-3" data-index="option2">
                                                                            <option value="Small" selected="selected">Small</option>
                                                                            <option value="Medium">Medium</option>
                                                                            <option value="Large">Large</option>
                                                                        </select>
                                                                        <ul className="size-list js-size-list" data-select-id="SingleOptionSelector-3">
                                                                            <li className="active"><a href="#" data-value="Small"><span className="value">Small</span></a></li>
                                                                            <li><a href="#" data-value="Medium"><span className="value">Medium</span></a></li>
                                                                            <li><a href="#" data-value="Large"><span className="value">Large</span></a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="prd-block_actions prd-block_actions--wishlist">
                                                                    <div className="prd-block_qty">
                                                                        <div className="qty qty-changer">
                                                                            <button type="button" className="decrease"
                                                                                onClick={() => this.changeQuantity("subt")}
                                                                            />
                                                                            <input type="number" id="product-quantity" className="qty-input" name="quantity"
                                                                                // defaultValue={1}
                                                                                data-min={1}
                                                                                // data-max={1000}
                                                                                value={this.state.quantity}
                                                                            />
                                                                            <button type="button" className="increase"
                                                                                onClick={() => this.changeQuantity("add")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-wrap">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn--add-to-cart js-trigger-addtocart js-prd-addtocart"
                                                                            data-product={this.state.isLoggedIn ? `{"name": "${this.state.name ? this.state.name.replaceAll('"', " ").replaceAll(",", " ") : ""}", "path":"${this.state.app_site_storage_path}${this.state.image}", "url":"/products?id=${this.state.product_id}", "aspect_ratio":0.778}` : undefined}
                                                                            onClick={() => this.addToCart(this.state.product_data)}
                                                                        >
                                                                            Add to cart</button>
                                                                    </div>
                                                                    <div className="btn-wishlist-wrap heart--big pt-2 pl-1">
                                                                        {/* <a href="#" className="btn-add-to-wishlist ml-auto btn-add-to-wishlist--add js-add-wishlist" title="Add To Wishlist"><i className="icon-heart-stroke" /></a> */}
                                                                        {/* <a href="#" className="btn-add-to-wishlist ml-auto btn-add-to-wishlist--off js-remove-wishlist" title="Remove From Wishlist"><i className="icon-heart-hover" /></a> */}
                                                                        <WishListButton
                                                                            product_data={this.state.product_data}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {/* <div className="prd-block_actions prd-block_actions--wishlist">
                                                                    <div className="prd-block_qty">
                                                                        <div className="qty qty-changer">
                                                                            <button className="decrease js-qty-button" />
                                                                            <input type="number" className="qty-input" name="quantity" defaultValue={1} data-min={1} data-max={1000} />
                                                                            <button className="increase js-qty-button" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-wrap">
                                                                        <button className="btn btn--add-to-cart js-prd-addtocart" data-product="{&quot;name&quot;:&quot;Leather Pegged Pants &quot;, &quot;url&quot;: &quot;product.html&quot;, &quot;path&quot;: &quot;images/skins/fashion/products/product-01-1.jpg&quot;, &quot;aspect_ratio &quot;: &quot;0.78&quot;}">Add to cart</button>
                                                                    </div>
                                                                    <div className="btn-wishlist-wrap">
                                                                        <a href="#" className="btn-add-to-wishlist ml-auto btn-add-to-wishlist--add js-add-wishlist" title="Add To Wishlist"><i className="icon-heart-stroke" /></a>
                                                                        <a href="#" className="btn-add-to-wishlist ml-auto btn-add-to-wishlist--off js-remove-wishlist" title="Remove From Wishlist"><i className="icon-heart-hover" /></a>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="prd-block_shopping-info-wrap-compact">
                                                                    <div className="prd-block_shopping-info-compact"><i className="icon-delivery-truck" /><span>Fast<br />Shipping</span></div>
                                                                    <div className="prd-block_shopping-info-compact"><i className="icon-return" /><span>Easy<br />Return</span></div>
                                                                    <div className="prd-block_shopping-info-compact"><i className="icon-call-center" /><span>24/7<br />Support</span></div>
                                                                </div> */}
                                                                <div className="prd-block_info_item mt-3 row row--sm-pad vert-margin-middle">
                                                                    <div className="col">
                                                                        <span className="btn btn--grey w-100"
                                                                            onClick={(e) => {
                                                                                this.props.history.push(`/product?id=${this.props.product_id}`)
                                                                                this.closeModal(e, true)
                                                                            }}>
                                                                            View Full Info
                                                                        </span>
                                                                        {/* <Link to={`/product?id=${this.props.product_id}`} className="btn btn--grey w-100"> */}
                                                                        {/* </Link> */}
                                                                    </div>
                                                                    <div
                                                                        className="col">
                                                                        <span
                                                                            className="btn btn--grey w-100"
                                                                            data-fancybox-close
                                                                            onClick={(e) => this.closeModal(e, true)}>
                                                                            Close
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="prd-block_info_item mt-0 row row--sm-pad vert-margin-middle"
                                                                    style={{
                                                                        visibility: "hidden"
                                                                    }}>
                                                                    <div className="col">
                                                                        <Link to={`/product?id=${this.props.product_id}`} className="btn btn--grey w-100">
                                                                            View Full Info
                                                                        </Link>
                                                                    </div>
                                                                    <div
                                                                        className="col">
                                                                        <span
                                                                            className="btn btn--grey w-100"
                                                                            data-fancybox-close
                                                                            onClick={(e) => this.closeModal(e, true)}>
                                                                            Close
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}




const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist,
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(QuickViewModal));

