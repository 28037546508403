import React, { Component } from 'react'
import BreadCrumb from '../BreadCrumb'

export default class EmptyCart extends Component {
    render() {
        return (
            <div className="page-content">
                <BreadCrumb
                    title="Custom Title"
                    parents={[
                        {
                            title: "Custom",
                            route: "/",
                        },
                        {
                            title: "Custom",
                            route: "/",
                        },
                    ]}
                />
                <div className="holder mt-0">
                    <div className="container">
                        <div className="page404-bg">
                            <div className="page404-text">
                                <div className="txt1"><img src="/images/pages/tumbleweed.gif" alt='' /></div>
                                <div className="txt2">Your shopping cart is empty!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
