export const incrementCart = (cart_obj) => {

    return {
        type: "INCREMENT",
        payload: cart_obj,
    }
}

export const decrementCart = (index) => {
    return {
        type: "DECREMENT",
        payload: index, // index || ID
    }
}

export const incrementCartQuantity = (index) => {
    return {
        type: "INCREMENTQUANTITY",
        payload: index,
    }
}

export const decrementCartQuantity = (index) => {
    return {
        type: "DECREMENTQUANTITY",
        payload: index, // index || ID
    }
}

export const clearCart = () => {
    return {
        type: "CLEARCART",
        // payload:index, // index || ID
    }
}

export const replaceCart = (cart_obj) => {
    return {
        type: "REPLACECART",
        payload: cart_obj,
    }
}