// import axios from "axios";

// const httpClient = axios.create({
//     baseURL: process.env.REACT_APP_MERCHANT_API
//     // baseURL: process.env.APP_API_BASE_URL,
// });

// httpClient.interceptors.request.use(function (config) {
//     const token = localStorage.getItem('token');
//     config.headers.Authorization =  token ? `Bearer ${token}` : '';
//     return config;
// });
// 
// export httpClient;

// First we need to import axios.js
import axios from 'axios';
// Next we make an 'instance' of it

const axiosConfig = axios.create({
    // .. where we make our configurations
    baseURL: process.env.REACT_APP_MERCHANT_API
});

// Where you would set stuff like your 'Authorization' header, etc ...
const token = localStorage.getItem('userToken');

axiosConfig.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
axiosConfig.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

// Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...

export default axiosConfig;