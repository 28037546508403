import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axiosConfig from '../axiosConfig';

// redux
import { connect } from 'react-redux'
import { incrementCart, decrementCart } from '../actions'

import InputValidationErrorMessage from '../inputValidationErrorMessage';
import { inputValidation, getUrlParameters } from '../helper_funcitons';

import AlertMessage from '../AlertMessage';

class Header extends React.Component {

    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.state = {
            isLoggedIn: appState ? appState.isLoggedIn : false,
            validation_errors: [],
            email: "",
            password: "",
            login_spinner: false,

            // header-contents
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,
            site_logo: "",
            header_top: [],
            banners: [],
            merchant_header_menus: [],
            merchant_header_social_sites: [],

            // right-sidebar
            cart_opened: false,
            account_opened: false,

            alert_status: "",
            alert_message: "",

            search_text: "",

            header_search: [false, false]

        }

        this.handleLogin = this.handleLogin.bind(this)
        this.onChange = this.onChange.bind(this)

        // header-contents
        this.getHeaderContents = this.getHeaderContents.bind(this)
        this.closeHeaderMenu = this.closeHeaderMenu.bind(this)

        this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
        this.toggleSearchDropdn = this.toggleSearchDropdn.bind(this)
    }

    removeCartItem(data) {
        this.props.decrementCart(data)
    }

    handleLogin(e) {
        e.preventDefault()

        // pass the name from e.targe.name here. 
        let validation_fields = ["email", "password"];

        let arr = []
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })

        let validation_errors = inputValidation(arr);

        this.setState({
            validation_errors: validation_errors
        })

        if (validation_errors.length === 0) {

            this.setState({
                login_spinner: true
            })

            let data = {
                email: this.state.email,
                password: this.state.password,
            }
            axiosConfig.post("/api/login", data)
                .then(res => {
                    this.setState({
                        login_spinner: false
                    })

                    if (res.data.success) {
                        localStorage.setItem('userToken', res.data.token);
                        localStorage.setItem('appState', JSON.stringify({ isLoggedIn: true }));
                        this.props.updateParentUserDataState({ isLoggedIn: true })
                        this.setState({
                            redirect_to_account_details: true
                        })
                        this.props.history.push("/account-details")
                    } else {
                        this.setState({
                            alert_message: res.data.message,
                            alert_status: "danger",
                        })
                    }

                })
                .catch(err => {
                    this.setState({
                        login_spinner: false
                    })
                })
        }


        // errors.elgnth = 0 


    }

    async getHeaderContents() {
        await axiosConfig.get("/api/merchant-header-contents")
            .then(res => {
                if (res.data.success) {
                    let { header_menus: merchant_header_menus,
                        header_social_sites: merchant_header_social_sites,
                        logo: site_logo,
                        header_top,
                        banners,
                    } = res.data.data;
                    this.setState({
                        site_logo,
                        header_top,
                        banners,
                        merchant_header_menus,
                        merchant_header_social_sites,
                    }, () => {
                        if (document.getElementById("header_top_carousel")) {
                            document.getElementById("header_top_carousel").remove()
                        }
                        var script = document.createElement('script');
                        script.id = "header_top_carousel"
                        script.defer = true
                        script.text = `
                            // for header - top - carousel 
                            $('.js-custom-text-carousel').each(function () {
                                var $this = $(this);
                                if ($this.hasClass('slick-initialized')) return false;
                                $this.slick({
                                    autoplay: true,
                                    arrows: false,
                                    dots: false,
                                    slidesToShow: 1,
                                    draggable: false,
                                    infinite: true,
                                    pauseOnHover: false,
                                    swipe: false,
                                    touchMove: false,
                                    vertical: true,
                                    speed: 1000,
                                    autoplaySpeed: 2000,
                                    useTransform: true,
                                    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)'
                                });
                            });

                            // for sticky header
                            function calcScrollWidth() {
                                var html = $('<div style="width:100px;height:100px;overflow:scroll;visibility: hidden;"><div style="height:200px;"></div>');
                                $body.append(html);
                                var w = html[0].offsetWidth - html[0].clientWidth;
                                $(html).remove();
                                return w;
                            }
                            var $body = $('body'),
                            $window = $(window),
                            $document = $(document),
                            w = window.innerWidth || $window.width(),
                            h = window.innerHeight || $window.height(),
                            resizeTimer = void 0,
                            scrollWidth = calcScrollWidth(),
                            promoToplineHeight = 0,
                            swipemode = false,
                            maxXS = 480,
                            maxSM = 768,
                            maxMD = 992,
                            maxLG = 1200,
                            mobileMenuBreikpoint = 1025,
                            productPreviewHorBreikpoint = 1025,
                            isMobile = w < mobileMenuBreikpoint,
                            productPage = $body.hasClass('template-product'),
                            catalogPage = $body.hasClass('template-collection');
                            function stickyHeaderInit(el) {
                                if ($(el).length) {
                                    THEME.stickyheader = {
                                        defaults: {
                                            header: '.hdr',
                                            headerSticky: '.hdr-content-sticky',
                                            headerM: '.hdr-mobile',
                                            headerD: '.hdr-desktop',
                                            hdrNav: '.nav-holder',
                                            stickyNav: '.nav-holder-s',
                                            mobileMenu: '.mmenu',
                                            promoTopline: '.promo-topline',
                                            drops: '.hdr-content-sticky .dropdn-content',
                                            stickyCollision: '.js-sticky-collision',
                                            offset: 500
                                        },
                                        init: function init(options) {
                                            $.extend(this.defaults, options);
                                            if ($(this.defaults.headerSticky).length) {
                                                $body.addClass('has-hdr_sticky');
                                            }
                                            if (!isMobile && !$body.hasClass('has-sticky')) {
                                                this._setHeigth();
                                            }
                                            this._setScroll();
                                            if ($(this.defaults.stickyNav).length) {
                                                // $(this.defaults.hdrNav).children().clone(true).appendTo(this.defaults.stickyNav);
                                            }
                                            if (!isMobile) {
                                                this._multirow();
                                                this._multirowS();
                                            }
                                            return this;
                                        },
                                        reinit: function reinit() {
                                            if (!$(this.defaults.header).length) return false;
                                            $window.off('scroll.stickyHeader');
                                            if (!isMobile && !$body.hasClass('has-sticky')) {
                                                this._setHeigth();
                                            }
                                            if (!isMobile) {
                                                this._multirow();
                                                this._multirowS();
                                            }
                                            this._setScroll();
                                            return this;
                                        },
                                        _multirow: function _multirow() {
                                            if ($(this.defaults.hdrNav).height() > 60) {
                                                $(this.defaults.header).addClass('mmenu-multirow');
                                            } else $(this.defaults.header).removeClass('mmenu-multirow');
                                        },
                                        _multirowS: function _multirowS() {
                                            if ($body.hasClass('has-hdr_sticky')) {
                                                if ($(this.defaults.stickyNav).height() > 60) {
                                                    $(this.defaults.headerSticky).addClass('mmenu-multirow-s');
                                                } else $(this.defaults.headerSticky).removeClass('mmenu-multirow-s');
                                            }
                                        },
                                        destroySticky: function destroySticky() {
                                            this._removeSticky();
                                        },
                                        _setScroll: function _setScroll() {
                                            var that = this,
                                                offset = that.defaults.offset + $(that.defaults.headerSticky).outerHeight(),
                                                checkColission = !isMobile || $(that.defaults.stickyCollision).length ? true : false;
                    
                                            function isCollapsed(el1, el2) {
                                                if (!$body.hasClass('has-sticky')) return false;
                                                if (el1.offset().top < el2.offset().top + el2.height()) {
                                                    return true;
                                                } else return false;
                                            }
                    
                                            function scrollEvents() {
                                                if ($body.hasClass('blockSticky')) return false;
                                                var st = $window.scrollTop();
                                                if (st > offset) {
                                                    if (!$body.hasClass('has-sticky')) {
                                                        that._setSticky();
                                                    }
                                                } else {
                                                    if ($body.hasClass('has-sticky')) {
                                                        that._removeSticky();
                                                    }
                                                }
                                            }
                    
                                            $window.on('scroll.stickyHeader', function () {
                                                if ($body.hasClass('blockSticky')) return false;
                                                scrollEvents();
                                                if (isMobile) return false;
                                                if (checkColission) {
                                                    $(that.defaults.stickyCollision).each(function () {
                                                        var $this = $(this),
                                                            h = parseInt($(that.defaults.headerSticky).outerHeight(), 10);
                                                        if (isCollapsed($this, $(that.defaults.headerSticky))) {
                                                            $this.addClass('is-collision');
                                                            if ($this.hasClass('js-filter-col')) {
                                                                h = h + 40;
                                                                $this.css({
                                                                    "-webkit-transform": "translate3d(0," + h + "px,0)",
                                                                    "-ms-transform": "translate3d(0," + h + "px,0)",
                                                                    "transform": "translate3d(0," + h + "px,0)",
                                                                    "padding-bottom": h + "px"
                                                                });
                                                            } else {
                                                                $this.children().css({
                                                                    "-webkit-transform": "translate3d(0," + h + "px,0)",
                                                                    "-ms-transform": "translate3d(0," + h + "px,0)",
                                                                    "transform": "translate3d(0," + h + "px,0)",
                                                                    "padding-bottom": h + "px"
                                                                });
                                                            }
                                                        } else {
                                                            $this.removeClass('is-collision');
                                                            if ($this.hasClass('js-filter-col')) {
                                                                $this.css({
                                                                    "-webkit-transform": "translate3d(0,0,0)",
                                                                    "-ms-transform": "translate3d(0,0,0)",
                                                                    "transform": "translate3d(0,0,0)",
                                                                    "padding-bottom": ""
                                                                });
                                                            } else {
                                                                $this.children().css({
                                                                    "-webkit-transform": "translate3d(0,0,0)",
                                                                    "-ms-transform": "translate3d(0,0,0)",
                                                                    "transform": "translate3d(0,0,0)",
                                                                    "padding-bottom": ""
                                                                });
                                                            }
                                                        }
                                                    });
                                                }
                                            });
                                            scrollEvents();
                                            return this;
                                        },
                                        _setTop: function _setTop() {
                                            var that = this,
                                                top = $('.js-hdr-top:visible').outerHeight();
                                            $(that.defaults.header).css({
                                                'top': 0 - top
                                            });
                                            $(that.defaults.drops).css({
                                                'top': top
                                            });
                                        },
                                        _setSticky: function _setSticky() {
                                            var that = this;
                                            $body.addClass('has-sticky');
                                            $(that.defaults.header).find('.is-hovered .dropdn-link').each(function () {
                                                if (!$(this).closest('.dropdn_fullheight').length) {
                                                    $(this).trigger('click');
                                                }
                                            });
                                        },
                                        _removeSticky: function _removeSticky() {
                                            $body.removeClass('has-sticky');
                                        },
                                        _setHeigth: function _setHeigth() {
                                            var $header = $(this.defaults.header),
                                                $hdrNav = $(this.defaults.hdrNav);
                                            $hdrNav.css({
                                                'height': ''
                                            });
                                            $header.css({
                                                'height': ''
                                            });
                                            if (!$body.hasClass('has-sticky')) {
                                                $hdrNav.css({
                                                    'height': $hdrNav.outerHeight()
                                                });
                                            } else {
                                                $body.removeClass('has-sticky');
                                            }
                                            return this;
                                        }
                                    };
                                    THEME.stickyheader.init();
                                } else {
                                    THEME.stickyheader = {
                                        defaults: {
                                            header: '.hdr',
                                            hdrNav: '.hdr-nav'
                                        },
                                        init: function init(options) {
                                            $.extend(this.defaults, options);
                                            this._multirow();
                                            return this;
                                        },
                                        reinit: function reinit() {
                                            this._multirow();
                                            return this;
                                        },
                                        _multirow: function _multirow() {
                                            if (isMobile) return false;
                                            if ($(this.defaults.hdrNav).outerHeight() > 60) {
                                                $(this.defaults.header).addClass('mmenu-multirow');
                                            } else $(this.defaults.header).removeClass('mmenu-multirow');
                                        }
                                    };
                                    THEME.stickyheader.init();
                                }
                            }
                            setTimeout(()=>{
                                stickyHeaderInit(".hdr-content-sticky")
                            },500)
                        `
                        // document.getElementsByTagName('body')[0].appendChild(script);
                    })
                }
            })
            .catch(err => {

            })
    }

    onChange(e) {

        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.value.length > 0) {
            e.target.parentElement.classList.remove("has-error");
        }
        else {
            if (e.target.name !== "search_text") {
                e.target.parentElement.classList.add("has-error");
            }
        }
    }

    async componentDidMount() {

        await this.getHeaderContents();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.location.pathname !== this.props.location.pathname) || (prevProps.location.search !== this.props.location.search)) {
            this.closeHeaderMenu();
        }

        if (prevState.search_text && this.state.search_text === "") {
            this.handleSearchSubmit()
        }
    }

    closeHeaderMenu() {
        let mmenu = document.getElementsByClassName("mmenu-item--mega")
        if (mmenu) {
            let spreaded = [...mmenu]
            spreaded.forEach(element => {
                element.classList.remove("hovered")
            });
        }

        let hoverred = document.getElementsByClassName("dropdn_fullheight is-hovered")
        if (hoverred) {
            let spreaded = [...hoverred]
            spreaded.forEach(element => {
                element.classList.remove("is-hovered")
            });
        }

        this.toggleRightSidebar("dropdnMinicart", true);
        // this.toggleRightSidebar("dropdnAccount", true);
        // let is_opened_right_sidebar = document.getElementsByClassName("account-drop is-opened")
        // if (is_opened_right_sidebar) {
        //     let spreaded = [...is_opened_right_sidebar]
        //     spreaded.forEach(element => {
        //         element.classList.remove("is-opened")
        //     });
        // }

        this.setState({
            cart_opened: false,
            account_opened: false,
        })
    }

    toggleSideMenu() {
        let target = document.getElementsByClassName("mobilemenu js-push-mbmenu")
        if (target) {
            if (target[0].classList.contains("active")) {
                target[0].classList.remove("active")
            } else {
                target[0].classList.add("active")
            }
        }
    }

    toggleRightSidebar(id, close = false) {
        if (close) {
            document.getElementById(id).classList.remove("is-opened")
        } else {
            setTimeout(() => {
                document.getElementById(id).classList.add("is-opened")
            }, 100)
        }


    }

    handleSearchSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        let changes = "?q=" + this.state.search_text;

        let temp_changes = getUrlParameters({ search: changes })


        let old_temp = getUrlParameters({ search: window.location.search });

        let temp = { ...temp_changes, ...old_temp };

        let str = "?";

        Object.entries(temp).forEach(([key, value], index) => {
            if (index === 0) {
                str += key + "=" + (temp_changes[key] === undefined ? temp[key] : temp_changes[key])
            } else {
                str += "&" + key + "=" + (temp_changes[key] === undefined ? temp[key] : temp_changes[key])
            }
        });

        this.props.history.push(`/product-listing${Object.entries(temp).length > 0 ? str : changes}`)

        this.setState({
            // search_text: "",
        })

        // this.toggleSearchDropdn(0, false)
        // this.toggleSearchDropdn(1, false)
        // document.getElementsByClassName("header_search is-hovered")[0]?.classList.remove("is-hovered")
        // document.getElementsByClassName("header_search_dropdn is-opened")[0]?.classList.remove("is-opened")
    }

    toggleSearchDropdn(index, bool) {
        let temp = [...this.state.header_search]
        temp[index] = !temp[index];
        if (bool === false) {
            temp[index] = false
        }

        if (document.getElementsByClassName("header_search")[index]?.classList.contains("is-hovered")) {
            document.getElementsByClassName("header_search")[index]?.classList.remove("is-hovered")
        } else {
            document.getElementsByClassName("header_search")[index]?.classList.add("is-hovered")
        }

        if (document.getElementsByClassName("header_search_dropdn")[index]?.classList.contains("is-opened")) {
            document.getElementsByClassName("header_search_dropdn")[index]?.classList.remove("is-opened")
        } else {
            document.getElementsByClassName("header_search_dropdn")[index]?.classList.add("is-opened")
        }
    }

    render() {

        let cart_items = [...this.props.cart_reducer];

        let total_cart_item_price = 0;

        let total_cart_quantity = 0;

        cart_items.forEach(el => {
            total_cart_item_price += (el.price * el.quantity);
            total_cart_quantity += el.quantity;
        })

        let cart_items_mapping = cart_items.map((el, index) => {

            return <div className="minicart-prd row" key={el.id}>
                <div className="minicart-prd-image image-hover-scale-circle col">
                    {/* <a href="product.html">
                        <img className="lazyload fade-up" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src="/images/skins/fashion/products/product-01-1.jpg"
                            alt=""
                        />
                    </a> */}
                    <Link
                        to={`/product?id=${el.id}`}
                        className="prd-img pb-0" >
                        <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${el.image}')` }}>
                            <img src="/images/skins/fashion/products/product-01-1.jpg"
                                alt={el.name}
                                className="fade-up" />
                        </div>
                        {/* <div className="foxic-loader" /> */}
                        {/* <div className="prd-big-circle-labels">
                            <div className="label-new"><span>New</span></div>
                        </div> */}
                    </Link>
                </div>
                <div className="minicart-prd-info col">
                    <div className="minicart-prd-tag">FOXic</div>
                    <h2 className="minicart-prd-name"><a href="#">{el.name}</a></h2>
                    <div className="minicart-prd-qty">
                        <span className="minicart-prd-qty-label">Quantity:</span><span className="minicart-prd-qty-value">
                            {el.quantity}
                        </span>
                    </div>
                    <div className="minicart-prd-price prd-price mt-0">
                        {/* <div className="price-old">${(el.price + 20) * el.quantity} </div> */}
                        <div className="price-new">${this.props.isLoggedIn ? ((el.price) * el.quantity) : "**"}</div>
                        {/* <div className="price-new">${this.props.isLoggedIn ? price : "**"}</div> */}
                    </div>
                </div>
                <div className="minicart-prd-action">
                    {/* <a href="#" className="js-product-remove" data-line-number={1}><i className="icon-recycle" /></a> */}
                    <button
                        className="js-product-remove border-0 padding-0 bg-transparent" data-line-number={1}
                        type="button"
                        onClick={() => this.removeCartItem(index)}
                    >
                        <i className="icon-recycle" />
                    </button>
                </div>
            </div>

        })

        let header_top_mapping = this.state.header_top.map((el, index) => {
            return <div className="custom-text-item" key={index}><i className={el.icon} />
                <span style={{
                    color: el.front_color || undefined,
                    fontWeight: el.front_weight || undefined,
                }}>{el.front_text}</span>
                &nbsp;
                &nbsp;
                <span style={{
                    color: el.middle_color || undefined,
                    fontWeight: el.middle_weight || undefined,
                }}>{el.middle_text}</span>
                &nbsp;
                &nbsp;
                <span style={{
                    color: el.end_color || undefined,
                    fontWeight: el.end_weight || undefined,
                }}>{el.end_text}</span>
            </div>
        })

        // let temp_banners = ["/images/header-banner.png", "/images/header-banner.png",]

        // let banners_mapping = this.state.banners.map((el, index) => {
        //     return <div className="bnr-caption-carousel-item" key={index}>
        //         <div className="bnr-imgff"><img
        //             src={`${this.state.app_site_storage_path}${el.image}`}
        //             alt="banner iamge" />
        //         </div>
        //     </div>
        // })

        let header_menus_mapping = this.state.merchant_header_menus.map(el => {
            return <li className="mmenu-item--mega" key={el.id}>
                <Link to={el.url}>
                    <span className="text-capitalize">{el.label}
                    </span>
                </Link>
                {
                    (el.second_level && el.second_level.length > 0)
                    &&
                    <div className="mmenu-submenu mmenu-submenu--has-bottom">
                        <div className="mmenu-submenu-inside">
                            <div className="container">
                                <div className="mmenu-cols row">
                                    {
                                        el.second_level.map(second_level => {
                                            return <div className={`mmenu-col pb-2 col`} key={second_level.id}>
                                                <h3 className="submenu-title text-capitalize">
                                                    <Link to={second_level.url}>
                                                        {second_level.label}
                                                    </Link>
                                                </h3>
                                                <ul className="submenu-list">
                                                    {
                                                        (second_level.third_level && second_level.third_level.length > 0)
                                                        &&
                                                        second_level.third_level.map(third_level => {
                                                            return <li className="text-capitalize" key={third_level.id}>
                                                                <Link to={third_level.url}>
                                                                    {third_level.label}
                                                                </Link>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        })

                                    }
                                    {/* <div className="mmenu-bottom justify-content-center">
                                    <a href="#"><i className="icon-fox icon--lg" /><b>FOXshop
                                        News</b><i className="icon-arrow-right" /></a>
                                </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </li>
        })

        let header_social_sites_mapping = this.state.merchant_header_social_sites.map((el, index) => {
            return <li key={index}>
                <a href={el.url}><i className={el.icon} /></a>
            </li>

        })

        let header_account = <> </>

        if (this.props.isLoggedIn) {
            header_account = <div className="dropdnn dropdn_accountt dropdn_fullheightt is-hoveredd is-loggedin account-wrapper">
                <span to="/account-details" className="dropdn-link js-dropdn-link js-dropdn-link only-icon account-link">
                    <i className="icon-user" /><span className="dropdn-link-txt">Account</span>
                </span>

                <div className="account-details">
                    <div className="account-placeholder"></div>
                    <ul className="loggedin-userdata">
                        <li>
                            <Link to="/account-details">
                                <span className={`dropdn-link-txt ${this.props.location.pathname.includes("/account-details") ? "text-primary" : ""}`}>Account Details</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/account-addresses">
                                <span className={`dropdn-link-txt ${this.props.location.pathname.includes("/account-addresses") ? "text-primary" : ""}`}>My Addresses</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/account-wishlist">
                                <span className={`dropdn-link-txt ${this.props.location.pathname.includes("/account-wishlist") ? "text-primary" : ""}`}>My Wishlist</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/account-history">
                                <span className={`dropdn-link-txt ${this.props.location.pathname.includes("/account-history") ? "text-primary" : ""}`}>My Order History</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">Purchase Order Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">Sales Order Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">Seller Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">Complex Transaction List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">Billing Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">RMA Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">Inventory Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">Buyer Protection Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">External Platform Mapping</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">Sales and Puchase Agreement</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <span className="dropdn-link-txt">My Coupon</span>
                            </Link>
                        </li>

                        <li className="separator"></li>
                        <li>
                            <Link to="/logout">
                                <span className="dropdn-link-txt">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        } else {
            header_account = <div className="dropdn dropdn_account dropdn_fullheightt account-wrapper">

                <span className="dropdn-link js-dropdn-link js-dropdn-link only-icon account-link">
                    <i className="icon-user" /><span className="dropdn-link-txt">Account</span>
                </span>

                <div className="account-details">
                    <div className="account-placeholder"></div>
                    <ul className="loggedin-userdata">
                        <li>
                            <p className="welcome-back">Welcome back</p>
                            <Link to="/login" className="loginBtn">
                                Login
                            </Link>
                        </li>
                        <li>
                            <p className="new-customer">New Customer ?</p>
                            <Link to="/register" className="registerBtn">
                                Register
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        }

        // let arr = ['Car', 'Sofa',
        //     'Chair',
        //     'Bed',
        //     'Desk',
        //     'Table',
        //     'Cabinet',
        //     'Trampoline',
        //     'Treadmill',
        //     'Stand',
        //     'Chair',
        //     'Desk',
        //     'Bed',
        //     'Chair',]
        // let popular_searches_mapping = arr.map((el, index) => {
        //     return <Link key={index} className="border border-border-light p-1 m-1" to={`/product-listing?q=${el}`}>
        //         {el}
        //     </Link>
        // })

        let header_wallet = <div className="dropdn dropdn_account dropdn_fullheight balance-wrapper">
            <span to="/account-balance" className="dropdn-link only-icon balance-link">
                <div className="dropdn dropdn_fullheight">
                    <span className={`dropdn-link js-dropdn-link minicart-link only-icon`}>
                        <i className="icon-wallet" />
                    </span>
                </div>
            </span>
            <div className="account-details">
                <div className="account-placeholder"></div>
                <ul className="">
                    <li>
                        <span className="current-account-balance">Current Account Balance</span>
                    </li>
                    <li className="balance-account">
                        <span>${this.props.points_reducer.total_points}</span>
                        <Link to="/account-balance">
                            Details
                        </Link>
                    </li>
                    <li className="credit-history">
                        <span>Credit History</span>
                        <span>${this.props.points_reducer.total_credit_history}</span>
                    </li>
                    <li className="payment-history">
                        <span>Payment History</span>
                        <span>${this.props.points_reducer.total_payment_history}</span>
                    </li>
                    <li className="separator"></li>
                    <li>
                        <Link to="/account-balance/recharge" className="add-account-funds">Add Account Funds</Link>
                    </li>
                </ul>
            </div>
        </div>

        return (
            <div id="header" className="no-print">
                <div>
                    <header className="hdr-wrap">

                        <div className="hdr hdr-style4">
                            {
                                this.state.header_top.length > 0
                                &&
                                <div className="hdr-topline hdr-topline--dark js-hdr-top">
                                    <div className="container">
                                        <div className="row flex-nowrap">
                                            <div className="col hdr-topline-center mx-auto">
                                                <div className="custom-text js-custom-text-carousel" data-slick="{&quot;speed&quot;: 1000, &quot;autoplaySpeed&quot;: 3000}">
                                                    {
                                                        header_top_mapping
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="hdr-content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-auto show-mobile menu-2"
                                            onClick={this.toggleSideMenu}>
                                            <div className="menu-toggle">
                                                <a href="#" className="mobilemenu-toggle">
                                                    <i className="icon-menu" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-auto hdr-logo">
                                            <Link to="/" className="logo">
                                                <img srcSet="/images/merchant_bitgofulfill_logo.png" alt="Logo" style={{
                                                    maxWidth: "100%",
                                                    width: "100%"
                                                }} />
                                            </Link>
                                        </div>

                                        <div className="col-lg-8 ml-auto">
                                            <div id="search_whole">
                                                <form onSubmit={this.handleSearchSubmit}>

                                                    <div className="form-groupp input-group" id="">
                                                        <input
                                                            id="search_input_box"
                                                            className="form-control border-primary border p-0 px-2"
                                                            type="search"
                                                            placeholder="What are you looking for?"
                                                            name="search_text"
                                                            autoComplete="false"
                                                            value={this.state.search_text}
                                                            onChange={this.onChange}
                                                            aria-describedby="basic-addon2"
                                                            onFocus={() => {
                                                                this.setState({
                                                                    show_search_options: true
                                                                })
                                                            }}
                                                        />
                                                        <span className="input-group-text btn rounded-0 " id="basic-addon2"
                                                            onClick={this.handleSearchSubmit}
                                                        >
                                                            <i className="icon-search" />
                                                        </span>

                                                    </div>
                                                </form>
                                                {/* {
                                                    this.state.show_search_options
                                                    &&
                                                    <div style={{
                                                        width: document.getElementById("search_input_box")?.offsetWidth,
                                                        position: 'absolute',
                                                        background: "#FFFFFF",
                                                        zIndex: "999999"
                                                    }}
                                                        className="p-2 col"
                                                    >
                                                        <div className="">Popular Searches</div>
                                                        <div className="row">
                                                            {popular_searches_mapping}
                                                        </div>
                                                    </div>
                                                } */}
                                            </div>

                                        </div>

                                        {/* <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                            <span class ="input-group-text" id="basic-addon2">@example.com</span>
                                        </div> */}


                                        <div className="hdr-links-wrap col-auto ml-auto">
                                            {/* <div className="hdr-group-link hide-mobile">
                                                <div className="hdr-inline-link">
                                                    <div className="dropdn_language">
                                                        <div className="dropdn dropdn_language dropdn_language--noimg dropdn_caret">
                                                            <a href="#" className="dropdn-link js-dropdn-link"><span className="js-dropdn-select-current">English</span><i className="icon-angle-down" /></a>
                                                            <div className="dropdn-content">
                                                                <ul>
                                                                    <li className="active"><a href="#"><img src="/images/flags/en.png" alt=""/>English</a></li>
                                                                    <li><a href="#"><img src="/images/flags/sp.png" alt=""/>Spanish</a>
                                                                    </li>
                                                                    <li><a href="#"><img src="/images/flags/de.png" alt=""/>German</a>
                                                                    </li>
                                                                    <li><a href="#"><img src="/images/flags/fr.png" alt=""/>French</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dropdn_currency">
                                                        <div className="dropdn dropdn_caret">
                                                            <a href="#" className="dropdn-link js-dropdn-link">US dollars<i className="icon-angle-down" /></a>
                                                            <div className="dropdn-content">
                                                                <ul>
                                                                    <li className="active"><a href="#"><span>US dollars</span></a></li>
                                                                    <li><a href="#"><span>Euro</span></a></li>
                                                                    <li><a href="#"><span>UK pounds</span></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                             */}
                                            <div className="hdr-inline-link">
                                                {/* Header Search */}
                                                {/* <div className="search_container_desktop">
                                                    <div className="dropdn dropdn_search dropdn_fullwidth header_search">
                                                        <a href="#" className="dropdn-link  js-dropdn-link only-icon"><i className="icon-search" />
                                                        <span className="dropdn-link-txt">Search</span></a>
                                                        <div className="dropdn-content header_search_dropdn">
                                                            <div className="container">
                                                                <form onSubmit={this.handleSearchSubmit} className="search search-off-popular">
                                                                    <input name="search_text" type="text"
                                                                        value={this.state.search_text}
                                                                        onChange={this.onChange}
                                                                        className="search-input input-empty" placeholder="What are you looking for?" />
                                                                    <button type="submit" className="search-button"><i className="icon-search" /></button>
                                                                    <a href="#" className="search-close js-dropdn-close"><i className="icon-close-thin" /></a>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* /Header Search */}
                                                {/* Header Account */}
                                                {/* <div className="dropdn dropdn_account dropdn_fullheight">
                                                    <a href="#" className="dropdn-link js-dropdn-link js-dropdn-link only-icon" data-panel="#dropdnAccount"><i className="icon-user" /><span className="dropdn-link-txt">Account</span></a>
                                                </div> */}

                                                {/* {
                                                    this.props.isLoggedIn
                                                        ?
                                                        < div className="dropdn dropdn_account dropdn_fullheight">
                                                            <Link to="/account-details" className="dropdn-link js-dropdn-link js-dropdn-link only-icon">
                                                                <i className="icon-user" />
                                                            </Link>
                                                        </div>
                                                        :
                                                        <div className="dropdn dropdn_account dropdn_fullheight">
                                                            <a href="#" className="dropdn-link js-dropdn-link js-dropdn-link only-icon" data-panel="#dropdnAccount"><i className="icon-user" /><span className="dropdn-link-txt">Account</span></a>
                                                        </div>
                                                } */}

                                                {

                                                    header_account
                                                }

                                                {
                                                    this.props.isLoggedIn &&

                                                    header_wallet
                                                }

                                                <div className="dropdn dropdn_wishlist">
                                                    <Link to="/account-wishlist" className="dropdn-link only-icon wishlist-link ">
                                                        <i className="icon-heart" />
                                                        <span className="dropdn-link-txt">
                                                            Wishlist
                                                        </span>
                                                        <span className="wishlist-qty">
                                                            {this.props.isLoggedIn ? this.props.wishlist_reducer.length : 0}
                                                        </span>
                                                    </Link>
                                                </div>
                                                {
                                                    this.props.isLoggedIn ?
                                                        <div className="dropdn dropdn_fullheight minicart custom=-"
                                                            onClick={() => this.toggleRightSidebar("dropdnMinicart")}>
                                                            <span className={`dropdn-link js-dropdn-link minicart-link only-icon`}
                                                            // data-panel="#dropdnMinicart"
                                                            >
                                                                <i className="icon-basket" />
                                                                <span className="minicart-qty">{total_cart_quantity}</span>
                                                                <span className="minicart-total hide-mobile"
                                                                    style={{
                                                                        display: "unset"
                                                                    }}
                                                                >&nbsp;${this.props.isLoggedIn ? total_cart_item_price : "**"}</span>
                                                            </span>
                                                        </div>
                                                        :
                                                        <Link to="/login" className="dropdn-link p-0">
                                                            <span className={`dropdn-link js-dropdn-link minicart-link only-icon`}>
                                                                <i className="icon-basket" />
                                                                <span className="minicart-qty">0</span>
                                                            </span>
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="hdr-navline hdr-navline--light">
                                <div className="container">
                                    <div className="row">
                                        <div className="col hdr-navline-left">
                                            {/*navigation*/}
                                            <div className="hdr-nav hide-mobile nav-holder">
                                                {/*mmenu*/}
                                                <ul className="mmenu mmenu-js">
                                                    {
                                                        this.state.merchant_header_menus.length > 0
                                                            &&
                                                            header_menus_mapping
                                                            // :
                                                            // <li className="mmenu-item--mega">
                                                            //     <a href="/"><span className="text-capitalize">
                                                            //         Home</span></a><div className="mmenu-submenu mmenu-submenu--has-bottom">
                                                            //         <div className="mmenu-submenu-inside">
                                                            //             <div className="container">
                                                            //                 <div className="mmenu-cols row">
                                                            //                     <div className="mmenu-col pb-2 col"
                                                            //                         style={{ opacity: 1, transform: 'none' }}>
                                                            //                         <h3 className="text-capitalize">
                                                            //                             <a href="/product-listing?category_id=37">
                                                            //                                 Cat_one</a></h3><ul className="submenu-list" />
                                                            //                     </div>
                                                            //                 </div>
                                                            //             </div>
                                                            //         </div>
                                                            //     </div>
                                                            // </li>
                                                    }
                                                </ul>
                                                {/*/mmenu*/}

                                            </div>
                                            {/*//navigation*/}
                                        </div>
                                        <div className="col-auto hdr-navline-right">
                                            {/* Header Social */}
                                            <div className="hdr-line-separate">
                                                <ul className="social-list list-unstyled">
                                                    {
                                                        header_social_sites_mapping
                                                    }
                                                </ul>
                                            </div>
                                            {/* /Header Social */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="header-side-panel">
                        {/* Mobile Menu */}
                        <div className="mobilemenu js-push-mbmenu">
                            <div className="mobilemenu-content">
                                <div className="mobilemenu-close mobilemenu-toggle"
                                    onClick={this.toggleSideMenu}>Close</div>
                                <div className="mobilemenu-scroll">
                                    <div className="mobilemenu-search" />
                                    <div className="nav-wrapper show-menu">
                                        <div className="nav-toggle">
                                            <span className="nav-back"><i className="icon-angle-left" /></span>
                                            <span className="nav-title" />
                                            <a href="#" className="nav-viewall">view all</a>
                                        </div>
                                        <ul className="nav nav-level-1">
                                            <li><a href="index.html">Layouts<span className="menu-label menu-label--color1">New</span><span className="arrow"><i className="icon-angle-right" /></span></a>
                                                <ul className="nav-level-2">
                                                    <li><a href="index.html">Fashion (Default) Skin</a></li>
                                                    <li><a href="index-sport.html">Sport Skin</a></li>
                                                    <li><a href="index-helloween.html">Halloween Skin</a></li>
                                                    <li><a href="index-medical.html">Medical Skin</a></li>
                                                    <li><a href="index-food.html">Food Market Skin</a></li>
                                                    <li><a href="index-cosmetics.html">Cosmetics Skin</a></li>
                                                    <li><a href="index-fishing.html">Fishing Skin</a></li>
                                                    <li><a href="#">Books Skin<span className="menu-label menu-label--color1">Coming
                                                        Soon</span></a></li>
                                                    <li><a href="#">Electronics Skin<span className="menu-label menu-label--color2">Coming
                                                        Soon</span></a></li>
                                                    <li><a href="#">Games Skin<span className="menu-label menu-label--color3">Coming
                                                        Soon</span></a></li>
                                                    <li><a href="#">Vaping Skin<span className="menu-label">Coming Soon</span></a></li>
                                                    <li><a href="index-02.html">Home page 2</a></li>
                                                    <li><a href="index-03.html">Home page 3</a></li>
                                                    <li><a href="index-04.html">Home page 4</a></li>
                                                    <li><a href="index-05.html">Home page 5</a></li>
                                                    <li><a href="index-06.html">Home page 6</a></li>
                                                    <li><a href="index-07.html">Home page 7</a></li>
                                                    <li><a href="index-08.html">Home page 8</a></li>
                                                    <li><a href="index-09.html">Home page 9</a></li>
                                                    <li><a href="index-10.html">Home page 10</a></li>
                                                    <li><a href="index-rtl.html">Home page RTL</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="#">Pages<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                <ul className="nav-level-2">
                                                    <li><a href="product.html">Product page<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="product.html">Product page variant 1<span className="menu-label menu-label--color3">Popular</span></a></li>
                                                            <li><a href="product-2.html">Product page variant 2</a></li>
                                                            <li><a href="product-3.html">Product page variant 3</a></li>
                                                            <li><a href="product-4.html">Product page variant 4</a></li>
                                                            <li><a href="product-5.html">Product page variant 5</a></li>
                                                            <li><a href="product-6.html">Product page variant 6</a></li>
                                                            <li><a href="product-7.html">Product page variant 7</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="category.html">Category page<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="category.html">Left sidebar filters</a></li>
                                                            <li><a href="category-closed-filter.html">Closed filters</a></li>
                                                            <li><a href="category-horizontal-filter.html">Horizontal filters</a></li>
                                                            <li><a href="category-listview.html">Listing View</a></li>
                                                            <li><a href="category-empty.html">Empty category</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="cart.html">Cart &amp; Checkout<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="cart.html">Cart Page</a></li>
                                                            <li><a href="cart-empty.html">Empty cart</a></li>
                                                            <li><a href="checkout.html">Checkout variant 1</a></li>
                                                            <li><a href="checkout-2.html">Checkout variant 2</a></li>
                                                            <li><a href="checkout-3.html">Checkout variant 3</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="account-create.html">Account<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="account-create.html">Login</a></li>
                                                            <li><a href="account-create.html">Create account</a></li>
                                                            <li><a href="account-details.html">Account details</a></li>
                                                            <li><a href="account-addresses.html">Account addresses</a></li>
                                                            <li><a href="account-history.html">Order History</a></li>
                                                            <li><a href="account-wishlist.html">Wishlist</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="blog.html">Blog<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="blog.html">Right sidebar</a></li>
                                                            <li><a href="blog-left-sidebar.html">Left sidebar</a></li>
                                                            <li><a href="blog-without-sidebar.html">No sidebar</a></li>
                                                            <li><a href="blog-sticky-sidebar.html">Sticky sidebar</a></li>
                                                            <li><a href="blog-grid.html">Grid</a></li>
                                                            <li><a href="blog-post.html">Blog post</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="gallery.html">Gallery</a></li>
                                                    <li><a href="faq.html">Faq</a></li>
                                                    <li><a href="about.html">About Us</a></li>
                                                    <li><a href="contact.html">Contact Us</a></li>
                                                    <li><a href="404.html">404 Page</a></li>
                                                    <li><a href="typography.html">Typography</a></li>
                                                    <li><a href="coming-soon.html" target="_blank">Coming soon</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="category.html">New Arrivals<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                <ul className="nav-level-2">
                                                    <li><a href="category.html">Shoes<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="category.html">Heels</a></li>
                                                            <li><a href="category.html">Boots</a></li>
                                                            <li><a href="category.html">Flats</a></li>
                                                            <li><a href="category.html">Sneakers &amp; Athletic</a></li>
                                                            <li><a href="category.html">Clogs &amp; Mules</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="category.html">Tops<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="category.html">Dresses</a></li>
                                                            <li><a href="category.html">Shirts &amp; Tops</a></li>
                                                            <li><a href="category.html">Coats &amp; Outerwear</a></li>
                                                            <li><a href="category.html">Sweaters</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="category.html">Shoes<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="category.html">Heels</a></li>
                                                            <li><a href="category.html">Boots</a></li>
                                                            <li><a href="category.html">Flats</a></li>
                                                            <li><a href="category.html">Sneakers &amp; Athletic</a></li>
                                                            <li><a href="category.html">Clogs &amp; Mules</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="category.html">Bottoms<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="category.html">Jeans</a></li>
                                                            <li><a href="category.html">Pants</a></li>
                                                            <li><a href="category.html">slippers</a></li>
                                                            <li><a href="category.html">suits</a></li>
                                                            <li><a href="category.html">socks</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="category.html">Accessories<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="category.html">Sunglasses</a></li>
                                                            <li><a href="category.html">Hats</a></li>
                                                            <li><a href="category.html">Watches</a></li>
                                                            <li><a href="category.html">Jewelry</a></li>
                                                            <li><a href="category.html">Belts</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="category.html">Bags<span className="arrow"><i className="icon-angle-right" /></span></a>
                                                        <ul className="nav-level-3">
                                                            <li><a href="category.html">Handbags</a></li>
                                                            <li><a href="category.html">Backpacks</a></li>
                                                            <li><a href="category.html">Luggage</a></li>
                                                            <li><a href="category.html">wallets</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li><a href="#buynow" target="_blank">Buy Theme<span className="menu-label menu-label--color3">Hurry Up!</span><span className="arrow"><i className="icon-angle-right" /></span></a></li>
                                        </ul>
                                    </div>
                                    <div className="mobilemenu-bottom">
                                        <div className="mobilemenu-currency">
                                            {/* Header Currency */}
                                            <div className="dropdn_currency">
                                                <div className="dropdn dropdn_caret">
                                                    <a href="#" className="dropdn-link js-dropdn-link">US dollars<i className="icon-angle-down" /></a>
                                                    <div className="dropdn-content">
                                                        <ul>
                                                            <li className="active"><a href="#"><span>US dollars</span></a></li>
                                                            <li><a href="#"><span>Euro</span></a></li>
                                                            <li><a href="#"><span>UK pounds</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Header Currency */}
                                        </div>
                                        <div className="mobilemenu-language">
                                            {/* Header Language */}
                                            <div className="dropdn_language">
                                                <div className="dropdn dropdn_language dropdn_language--noimg dropdn_caret">
                                                    <a href="#" className="dropdn-link js-dropdn-link"><span className="js-dropdn-select-current">English</span><i className="icon-angle-down" /></a>
                                                    <div className="dropdn-content">
                                                        <ul>
                                                            <li className="active"><a href="#"><img src="/images/flags/en.png" alt="" />English</a></li>
                                                            <li><a href="#"><img src="/images/flags/sp.png" alt="" />Spanish</a></li>
                                                            <li><a href="#"><img src="/images/flags/de.png" alt="" />German</a></li>
                                                            <li><a href="#"><img src="/images/flags/fr.png" alt="" />French</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Header Language */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Mobile Menu */}

                        {/* to identify if the route has been changed  */}
                        <div className="dropdn-content account-drop is-openeddd " id="dropdnAccount">
                            <div className="dropdn-content-block">
                                <div className="dropdn-close">
                                    <span className="js-dropdn-close"
                                    // onClick={() => { this.toggleRightSidebar("dropdnAccount", true) }}
                                    >Close</span></div>
                                <ul>
                                    <li>
                                        <Link to="/login">
                                            <span>Log In</span><i className="icon-login" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/register">
                                            <span>Register</span><i className="icon-user2" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/checkout">
                                            <span>Checkout</span><i className="icon-card" />
                                        </Link>
                                    </li>
                                </ul>



                                <div className="dropdn-form-wrapper">
                                    <h5>Quick Login</h5>

                                    {
                                        (this.state.alert_status !== '' && this.state.alert_message !== '')
                                        &&
                                        <AlertMessage
                                            status={this.state.alert_status}
                                            messages={this.state.alert_message}
                                        />
                                    }

                                    <form onSubmit={this.handleLogin}>
                                        <div className="form-group">
                                            <input type="email"
                                                className="form-control form-control--sm"
                                                placeholder="Enter your e-mail"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.onChange}
                                            />
                                            <InputValidationErrorMessage
                                                validation_errors={this.state.validation_errors}
                                                input_field={this.state.email}
                                                error_field="email"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="password"
                                                className="form-control form-control--sm"
                                                placeholder="Enter your password"
                                                name="password"
                                                autoComplete="true"
                                                value={this.state.password}
                                                onChange={this.onChange}
                                            />
                                            <InputValidationErrorMessage
                                                validation_errors={this.state.validation_errors}
                                                input_field={this.state.password}
                                                error_field="email"
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn"
                                            disabled={this.state.login_spinner}
                                        >
                                            Enter
                                        </button>
                                    </form>

                                </div>
                            </div>
                            <div className="drop-overlay js-dropdn-close" />
                        </div>
                        {
                            this.state.cart_opened
                            &&
                            <h1>ok</h1>
                        }
                        <div className={`dropdn-content minicart-drop ${this.state.cart_opened && "is-opened"}`} id="dropdnMinicart">
                            <div className="dropdn-content-block">
                                <div className="dropdn-close">
                                    <span className="js-dropdn-close"
                                        onClick={() => { this.toggleRightSidebar("dropdnMinicart", true) }}
                                    >Close</span>
                                </div>
                                <div className="minicart-drop-content js-dropdn-content-scroll">

                                    {
                                        cart_items.length > 0
                                            ?
                                            cart_items_mapping
                                            :
                                            <div className="minicart-empty js-minicart-empty">
                                                <div className="minicart-empty-text">Your cart is empty</div>
                                                <div className="minicart-empty-icon">
                                                    <i className="icon-shopping-bag" />
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 306 262" style={{ enableBackground: 'new 0 0 306 262' }} xmlSpace="preserve">
                                                        <path className="st0" d="M78.1,59.5c0,0-37.3,22-26.7,85s59.7,237,142.7,283s193,56,313-84s21-206-69-240s-249.4-67-309-60C94.6,47.6,78.1,59.5,78.1,59.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                    }

                                    {/* <div className="minicart-prd row">
                                        <div className="minicart-prd-image image-hover-scale-circle col">
                                            <a href="product.html"><img className="lazyload fade-up" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="/images/skins/fashion/products/product-16-1.jpg" alt="" /></a>
                                        </div>
                                        <div className="minicart-prd-info col">
                                            <div className="minicart-prd-tag">FOXic</div>
                                            <h2 className="minicart-prd-name"><a href="#">Cascade Casual Shirt</a></h2>
                                            <div className="minicart-prd-qty"><span className="minicart-prd-qty-label">Quantity:</span><span className="minicart-prd-qty-value">1</span></div>
                                            <div className="minicart-prd-price prd-price">
                                                <div className="price-old">$200.00</div>
                                                <div className="price-new">$180.00</div>
                                            </div>
                                        </div>
                                        <div className="minicart-prd-action">
                                            <a href="#" className="js-product-remove" data-line-number={2}><i className="icon-recycle" /></a>
                                        </div>
                                    </div> */}

                                    {/* <a href="#" className="minicart-drop-countdown mt-3">
                                        <div className="countdown-box-full">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-auto"><i className="icon-gift icon--giftAnimate" /></div>
                                                <div className="col">
                                                    <div className="countdown-txt">WHEN BUYING TWO
                                                        THINGS A THIRD AS A GIFT
                                                    </div>
                                                    <div className="countdown js-countdown" data-countdown="2021/07/01" />
                                                </div>
                                            </div>
                                        </div>
                                    </a> */}
                                    {/* <div className="minicart-drop-info d-none d-md-block">
                                        <div className="shop-feature-single row no-gutters align-items-center">
                                            <div className="shop-feature-icon col-auto"><i className="icon-truck" /></div>
                                            <div className="shop-feature-text col"><b>SHIPPING!</b> Continue shopping to add more products
                                                and receive free shipping</div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="minicart-drop-fixed js-hide-empty">
                                    <div className="loader-horizontal-sm js-loader-horizontal-sm" data-loader-horizontal><span />
                                    </div>
                                    <div className="minicart-drop-total js-minicart-drop-total row no-gutters align-items-center">
                                        <div className="minicart-drop-total-txt col-auto heading-font">Subtotal</div>
                                        <div className="minicart-drop-total-price col" data-header-cart-total>${this.props.isLoggedIn ? total_cart_item_price : "**"}</div>
                                    </div>
                                    <div className="minicart-drop-actions">
                                        <Link to="/cart" className="btn btn--md btn--grey">
                                            <i className="icon-basket" /><span>Cart Page</span>
                                        </Link>
                                        <Link to="/checkout" className="btn btn--md btn--grey">
                                            <i className="icon-checkout" /><span>Checkout</span>
                                        </Link>
                                    </div>
                                    <ul className="payment-link mb-2">
                                        <li><i className="icon-amazon-logo" /></li>
                                        <li><i className="icon-visa-pay-logo" /></li>
                                        <li><i className="icon-skrill-logo" /></li>
                                        <li><i className="icon-klarna-logo" /></li>
                                        <li><i className="icon-paypal-logo" /></li>
                                        <li><i className="icon-apple-pay-logo" /></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="drop-overlay js-dropdn-close" />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

// export default Header;


const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
    points_reducer: state.points_reducer,
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(Header));
