import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class BreadCrumb extends Component {

    render() {

        let parents_mapping = this.props.parents.map(el => {
            return <li key={el.title}>
                <Link to={el.route}>{el.title} </Link>
            </li>
        })

        return (
            <div className="holder breadcrumbs-wrap mt-0 no-print">
                <div className="container">
                    <ul className="breadcrumbs">
                        {parents_mapping}
                        <li><span>{this.props.title}</span></li>
                    </ul>
                </div>
            </div>
        )
    }
}
