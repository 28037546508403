import React from 'react';

export default function InputValidationErrorMessage(props) {
    return (
        <>
            {
                props.validation_errors.filter((error) => error.error_field === props.error_field).length > 0 && (props.input_field === "" || props.input_field === null)
                &&
                <>
                    <p className="invalid-feedback text-left mt-0">
                        {
                            props.validation_errors.filter((error) => error.error_field === props.error_field)[0].error
                        }
                    </p>
                </>
            }
        </>
    );
}