const WishlistReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADDWISHLIST': {
            let temp = [...state];
            temp.push(action.payload)
            return temp;
        }
        case 'REMOVEWISHLIST': {
            let temp = [...state];
            temp = temp.filter((el, index) => el.product_id !== action.payload)
            return temp;
        }
        case 'REPLACEWISHLIST': {
            return action.payload;
        }

        default:
            return state;
    }
}

export default WishlistReducer;