import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import '/css/style.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';

import { createStore } from 'redux';
import allReducers from './reducers/index'
import { Provider } from 'react-redux'

export const loadState = () => {

  let obj = {};
  try {
    const searialized_cart_items = localStorage.getItem('cart_items');
    if (searialized_cart_items === null) {
      obj['cart_items'] = []
    } else {
      obj["cart_items"] = JSON.parse(searialized_cart_items)
    }

    return obj;

  } catch (err) {
    return undefined;
  }
};

let local_data = loadState();

const store = createStore(allReducers, {
  cart_reducer: local_data.cart_items,
  wishlist_reducer: [],
  points_reducer:{
    total_points:0,
    total_credit_history:0,
    total_payment_history:0,
  },
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

store.subscribe(() => {
  saveToLocalStorage(store.getState());
})


export const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem('cart_items', JSON.stringify(state.cart_reducer));

  } catch {
    // ignore write errors
  }
};


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter
      // forceRefresh={true}
    >
      <Provider store={store}> {/* redux  */}
        {/* <UserProvider> react -  use context */}
        <App />
        {/* </UserProvider> */}
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
