import React, { Component } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom';
import Pagination from 'react-js-pagination'
import Spinner from '../../Spinner';
import axiosConfig from '../../axiosConfig';
import { getUrlParameters, handleError, scrollToTop } from '../../helper_funcitons';


class AccountPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            points_data: [],

            // pagination
            _activePage: 1,
            _itemsCountPerPage: 10,
            _totalItemsCount: 1,
            _pageRangeDisplayed: 3,
            _search_term: '',

            total_points: 0,
            total_credit_history: 0,
            total_payment_history: 0,

            credit_line_flow: "all",
            exchange_hour_start: "",
            exchange_hour_end: "",

            selected_tab: "account_balance"
        }

        this.getAccountPoints = this.getAccountPoints.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.changeSearchTerm = this.changeSearchTerm.bind(this)
        this.onChangeItemCount = this.onChangeItemCount.bind(this)
        this.toLogin = this.toLogin.bind(this)
        this.onChange = this.onChange.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
    }

    toLogin() {
        // todo resetFilterHeader too
        this.setState({
            redirect_to_login: true
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            let old_url_parameters = getUrlParameters(prevProps.location)
            let new_url_parameters = getUrlParameters(this.props.location)

            if (old_url_parameters?.tab !== new_url_parameters?.tab) {
                this.setState({
                    selected_tab: new_url_parameters?.tab || "account_balance"
                }, () => {
                    this.getAccountPoints()
                })
            }
        }
    }

    componentDidMount() {
        scrollToTop(0, 0)
        let url_params = getUrlParameters(this.props.location);
        if (url_params.tab) {
            let { tab } = url_params
            if (tab === "account_balance" || tab === "add_account_funds") {
                this.setState({
                    selected_tab: tab
                }, () => {
                    this.getAccountPoints();
                })
            } else {
                this.getAccountPoints();
            }
        } else {
            this.getAccountPoints()
        }
    }

    async getAccountPoints(show_spinner = true) {
        this.setState({
            show_spinner: show_spinner,
        })
        // let url = "";

        // if (this.state.selected_tab == "account_balance") {
        let url = `/api/points-data?page=${this.state._activePage}&per_page=${this.state._itemsCountPerPage}&search_name=${this.state._search_term}&credit_line_flow=${this.state.credit_line_flow}&exchange_hour_start=${this.state.exchange_hour_start}&exchange_hour_end=${this.state.exchange_hour_end}&requested=${this.state.selected_tab === "add_account_funds" ? true : false}`
        // }else{
        //     url = `/api/points-data?page=${this.state._activePage}&per_page=${this.state._itemsCountPerPage}&search_name=${this.state._search_term}&credit_line_flow=${this.state.credit_line_flow}&exchange_hour_start=${this.state.exchange_hour_start}&exchange_hour_end=${this.state.exchange_hour_end}`
        // }

        await axiosConfig.get(url)
            .then(res => {
                this.setState({
                    show_spinner: false
                })

                if (res.data.success) {
                    let { total_points, total_credit_history, total_payment_history } = res.data.summary

                    this.setState({
                        points_data: res.data.data.data,
                        _totalItemsCount: res.data.data.total,
                        total_points,
                        total_credit_history,
                        total_payment_history
                    })
                }
            })
            .catch(err => {
                handleError(err, this.toLogin);
            })
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                _activePage: pageNumber
            }, () => {
                this.getAccountPoints()
            }
        );

        let elDistanceToTop = window.pageYOffset + document.getElementById("manage_table").getBoundingClientRect().top
        scrollToTop(elDistanceToTop);
    }

    changeSearchTerm(e) {
        e.preventDefault();
        this.setState({
            _search_term: e.target.value,
            _activePage: 1,
        }, () => {
            this.getAccountPoints(false)
        })
    }

    onChangeItemCount(e) {
        e.preventDefault();
        this.setState({
            _itemsCountPerPage: parseInt(e.target.value),
        }, () => {
            if (this.state._itemsCountPerPage * this.state._activePage > this.state._totalItemsCount) {
                this.setState({
                    _activePage: 1,
                }, () => {
                    this.getAccountPoints()
                })
            }
            else {
                this.getAccountPoints()
            }
        })
    }
    changeUrlParmas() {
        this.props.history.push("?tab=" + this.state.selected_tab)
    }

    resetFilter() {
        this.setState({
            credit_line_flow: "all",
            exchange_hour_start: "",
            exchange_hour_end: "",
        })
        document.getElementById("credit_line_flow").value = "all";
        document.getElementById("exchange_hour_start").value = "";
        document.getElementById("exchange_hour_end").value = "";
    }

    getLocalTime(db_date) {
        if (db_date) {
            //  2021-09-07 07:44:30 
            // 2021-09-07T07:42:44.000000Z  => UTC time

            if (!db_date.includes("Z")) {
                db_date += " UTC";
            }

            let date = new Date(db_date);
            let result = date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear() +
                " " + (date.getHours().toString().length === 1 ? "0" + date.getHours().toString() : date.getHours()) +
                ":" + (date.getMinutes().toString().length === 1 ? "0" + date.getMinutes().toString() : date.getMinutes()) +
                ":" + (date.getSeconds().toString().length === 1 ? "0" + date.getSeconds().toString() : date.getSeconds());

            return result;
        }
    }
    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }}/>
        }

        let points_data_mapping = this.state.points_data.map((el, index) => {
            return <tr key={el.id}>
                <td>{el.transaction_id}</td>
                <td>{this.getLocalTime(el.created_at)}</td>
                <td>{el.type}</td>
                <td>{el.operation_type === "Minus" ? " - " : " + "}${el.balance}</td>
                <td>{el.operation_type === "Minus" ? " - " : " + "}${el.transferred_balance}</td>
                <td>{el.operation_type === "Minus" ? " - " : " + "}${el.balance}</td>
                <td className="text-left">{el.credit_line_flow}</td>
                {
                    this.state.selected_tab === "add_account_funds"
                    &&
                    <>
                        <td className="text-left">
                            {/* <button type="button" className="btn btn-primary btn-sm"> */}
                            <Link to={`/account-balance/recharge/${el.id}`}>
                                <i className="icon-eye"></i>
                            </Link>
                            {/* </button> */}
                        </td>
                    </>
                }
                {/* <td>${el.total_price}</td> */}
            </tr>
        })

        let credit_line_flow_options = <> </>
        if (this.state.selected_tab === "account_balance") {
            credit_line_flow_options = <>
                <option value="all">All</option>
                <option value="received_credits">Received Credits</option>
                <option value="payments">Payments</option>
            </>
        } else {
            credit_line_flow_options = <>
                <option value="all" selected="">All</option>
                <option value="applied">Applied</option>
                <option value="pending">Pending</option>
                <option value="rejection">Rejection</option>
                <option value="complete">Complete</option>
                <option value="to_audit">ToAudit</option>
                <option value="rejected_audit">RejectedAudit</option>
            </>
        }


        // < th scope = "col" > Transaction #</th>
        //                                         <th scope="col">Date</th>
        //                                         <th scope="col" >Method | Remark</th>
        //                                         <th scope="col" >Received Credits</th>
        //                                         <th scope="col" >Payments</th>
        //                                         <th scope="col" >Balance</th>
        // let points_data_mapping = '';

        return (
            <div className="col-md-18 aside">
                <h2 className="mb-2" id="account-balance-title">Account Balance</h2>

                <h1 className="mb-2 d-none" id="manage_table">Points ( ${this.state.total_points} )</h1>

                <div className="card mb-4 account-balance-header">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="panel panel-account-balance">
                                    <p className="panel-title">Account balance</p>
                                    <div className="row mt-1">
                                        <div className="col-9 text-left">
                                            <span className="account-balance-value">${this.state.total_points}</span>
                                        </div>
                                        <div className="col-9 text-right">
                                            <Link className="add-funds" to="/account-balance/recharge">Add Account Funds</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="panel panel-credit-history">
                                    <p className="panel-title">Credit History</p>
                                    <p className="credit-histroy-value mt-1">${this.state.total_credit_history}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="panel panel-payment-history">
                                    <p className="panel-title">Payment History</p>
                                    <p className="payment-histroy-value mt-1">${this.state.total_payment_history}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="nav nav-tabs" id="accountBalanceTab" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link ${this.state.selected_tab === "account_balance" ? "active" : ""}`}
                            onClick={
                                () => {
                                    this.setState({
                                        selected_tab: "account_balance"
                                    }, () => {
                                        this.changeUrlParmas();
                                        this.getAccountPoints();
                                        this.resetFilter();
                                    })
                                }
                            }
                            id="account-balance-tab" data-toggle="tab" href="#account-balance" role="tab" aria-controls="account-balance" aria-selected="true">Account Balance</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${this.state.selected_tab === "add_account_funds" ? "active" : ""}`}
                            onClick={
                                () => {
                                    this.setState({
                                        selected_tab: "add_account_funds"
                                    }, () => {
                                        this.changeUrlParmas();
                                        this.getAccountPoints();
                                        this.resetFilter();

                                    })
                                }
                            }
                            id="add-account-funds-tab" data-toggle="tab" href="#add-account-funds" role="tab" aria-controls="add-account-funds" aria-selected="false">Add Account Funds</a>
                    </li>
                </ul>

                <div className="tab-content p-2" id="accountBalanceTabContent">
                    <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="account-balance-tab">
                        <div className="row align-items-center">
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="credit_line_flow">CREDIT LINE FLOW</label>
                                    <select className="form-control" id="credit_line_flow" name="credit_line_flow"
                                        onChange={this.onChange}
                                    >
                                        {credit_line_flow_options}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">EXCHANGE HOUR</label>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <input type="date"
                                                onChange={this.onChange}
                                                name="exchange_hour_start"
                                                className="form-control" id="exchange_hour_start" aria-describedby="" placeholder="Pick a date"
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <input type="date"
                                                onChange={this.onChange}
                                                name="exchange_hour_end"
                                                className="form-control" id="exchange_hour_end" aria-describedby="" placeholder="Pick a date"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1">
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => {
                                            this.getAccountPoints()
                                        }}
                                    ><i className="icon-filter"></i></button>
                                </div>
                            </div>
                            <div className="col-1 ml-2">
                                <div className="form-group">
                                    <button type="button"
                                        onClick={() => {
                                            this.resetFilter()
                                        }}
                                        className="btn btn-light"><i className="icon-refresh"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="d-sm-flex justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center">
                                <span>Show </span> <select className="custom-select mx-1" id="" name="itemsCountPerPage" onChange={this.onChangeItemCount} value={this.state._itemsCountPerPage}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select> <span> Entries</span>
                            </div>
                            <div className="d-flex mt-1 mt-sm-0  align-items-center ww-50">
                                <input type="text" className="form-control rounded" name="search_term" value={this.state._search_term} placeholder="Search for ..." onChange={this.changeSearchTerm} />
                            </div>
                        </div>
                        {
                            this.state.show_spinner ?
                                <Spinner />
                                :
                                <>
                                    <div className="table-responsive" >
                                        <table className="table table-striped table-order-history">
                                            <thead>
                                                <tr>
                                                    <th scope="col" >Transaction #</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col" >Method | Remark</th>
                                                    <th scope="col" >Received Credits</th>
                                                    <th scope="col" >Payments</th>
                                                    <th scope="col" >Balance</th>
                                                    <th scope="col" >Status</th>
                                                    {
                                                        this.state.selected_tab === "add_account_funds"
                                                        &&
                                                        <>
                                                            <th scope="col" >Action</th>
                                                        </>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.points_data.length > 0
                                                        ?
                                                        points_data_mapping
                                                        :
                                                        <tr>
                                                            <td colSpan={this.state.selected_tab === "account_balance" ? "7" : "8"}>
                                                                <p className="text-center p-3"> There are no records to display.</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        this.state.points_data.length > 0
                                        &&
                                        <Pagination
                                            activePage={this.state._activePage}
                                            itemsCountPerPage={this.state._itemsCountPerPage}
                                            totalItemsCount={this.state._totalItemsCount}
                                            pageRangeDisplayed={this.state._pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    }
                                    {/* <div className="text-right mt-2">
                                <a href="#" className="btn btn--alt">Clear History</a>
                            </div> */}
                                </>
                        }
                    </div>

                </div>


            </div>
        )
    }
}

export default withRouter(AccountPoints)