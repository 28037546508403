
export const addToWishlist = (wishlist_obj) => {
    return {
        type: "ADDWISHLIST",
        payload: wishlist_obj,
    }
}

export const removeFromWishlist = (index) => {
    return {
        type: "REMOVEWISHLIST",
        payload: index,
    }
}

export const replaceWishlist = (obj) => {
    return {
        type: "REPLACEWISHLIST",
        payload: obj,
    }
}