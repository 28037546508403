import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class Progressbar extends Component {

    render() {
        return <div className="d-flex justify-content-center align-items-center mt-5" >
            <div
                onClick = {this.props.onClick || null}
                style={{
                    width: 150,
                    height: 150,
                    cursor: this.props.cursor || ""
                }}>
                <CircularProgressbar
                    value={this.props.value}
                    text={this.props.title}
                    maxValue={1}
                />
            </div>
        </div>

    }
}

export default withRouter(Progressbar);
