import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist } from '../actions'
import WishListButton from './WishListButton'
import AddToCartButton from './AddToCartButton'
import QuickViewButton from './QuickViewButton'
import { getMainImage, getParsedDiscription } from '../helper_funcitons'

class ProductHorizontal extends Component {

    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.state = {
            isLoggedIn: appState ? appState.isLoggedIn : false,
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,
            redirect_to_login: false,
        }
        this.addToCart = this.addToCart.bind(this)
        // this.addToWishlist = this.addToWishlist.bind(this)
        // this.removeFromWishlist = this.removeFromWishlist.bind(this)
    }

    addToCart(el) {

        if (this.state.isLoggedIn) {
            let obj = {
                id: el.id,
                name: el.name,
                price: el.price,
                quantity: 1,
                image: getMainImage(el.product_images)
            }
            this.props.incrementCart(obj);
        } else {
            this.setState({
                redirect_to_login: true,
            })
        }

    }

    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }} />
        }

        let { id, name, image: res_image, price, short_description, company_name, company_id } = this.props.product_data
        short_description = getParsedDiscription(short_description)

        let image = getMainImage(res_image)

        return <div className="prd prd-hor prd-w-xs " key={id}>
            <div className="prd-inside">
                <div className="prd-img-area">
                    <Link
                        to={`/product?id=${id}`}
                        className="prd-img image-hover-scale image-container">

                        <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${image}')` }}>
                            <img src="/images/skins/electronics/products/product-01-1.jpg"
                                alt={name}
                                className="js-prd-img" />
                        </div>

                        <div className="foxic-loader" />
                        {/* <div className="prd-big-circle-labels">
                            <div className="label-new"><span>New</span></div>
                        </div> */}
                    </Link>
                    <div className="prd-circle-labels">
                        <WishListButton
                            product_data={this.props.product_data}
                        />
                        {/* <a href="#" className="circle-label-qview js-prd-quickview prd-hide-mobile" data-src="ajax/ajax-quickview.html"><i className="icon-eye" /><span>QUICK VIEW</span></a> */}
                        {/* <QuickViewButton
                            product_id={this.props.product_data.id}
                            toggleQuickViewModal={this.props.toggleQuickViewModal}
                        /> */}
                        <QuickViewButton
                                product_data = {this.props.product_data}
                                product_id={this.props.product_data.id}
                                toggleQuickViewModal={this.props.toggleQuickViewModal}
                            />
                    </div>
                </div>
                <div className="prd-info">
                    <div className="prd-info-wrap">
                        {/* <div className="prd-info-top">
                            <div className="prd-rating"><i className="icon-star-fill fill" />
                            <i className="icon-star-fill fill" />
                            <i className="icon-star-fill fill" /><i className="icon-star-fill fill" />
                            <i className="icon-star-fill fill" /></div>
                        </div> */}
                        <div className="prd-tag"><Link to={`/store/${company_id}`}>
                            {company_name}
                        </Link></div>

                        <h2 className="prd-title">
                            <Link to={`/product?id=${id}`}>
                                {name}
                            </Link></h2>
                        <div className="prd-description">
                            <p id="" dangerouslySetInnerHTML={{ __html: short_description }}>
                            </p>
                        </div>
                    </div>
                    <div className="prd-hovers">
                        <div className="prd-circle-labels">
                            {/* <div><a href="#" className="circle-label-compare circle-label-Wishlist--add js-add-Wishlist mt-0" title="Add To Wishlist"><i className="icon-heart-stroke" /></a><a href="#" className="circle-label-compare circle-label-Wishlist--off js-remove-Wishlist mt-0" title="Remove From Wishlist"><i className="icon-heart-hover" /></a></div> */}
                            <WishListButton
                                product_data={this.props.product_data}
                            />
                            {/* <div><a href="#" className="circle-label-qview prd-hide-mobile js-prd-quickview" data-src="ajax/ajax-quickview.html"><i className="icon-eye" /><span>QUICK VIEW</span></a></div> */}

                            {/* <QuickViewButton
                                product_id={this.props.product_data.id}
                                toggleQuickViewModal={this.props.toggleQuickViewModal}
                            /> */}
                            <QuickViewButton
                                product_data = {this.props.product_data}
                                product_id={this.props.product_data.id}
                                toggleQuickViewModal={this.props.toggleQuickViewModal}
                            />

                        </div>
                        <div className="prd-price">
                            <div className="price-new">${this.state.isLoggedIn ? price : "**"}</div>
                        </div>
                        <div className="prd-action">
                            <div className="prd-action-left">
                                {/* <form action="#"> */}
                                {/* <button
                                    type="button"
                                    className="btn js-prd-addtocart"
                                    onClick={() => this.addToCart(this.props.product_data)}
                                    // data-product="{&quot;name&quot;: &quot;QLED TV&quot;, &quot;path&quot;:&quot;images/skins/electronics/products/product-02-1.jpg&quot;, &quot;url&quot;:&quot;product.html&quot;, &quot;aspect_ratio&quot;:0.778}"
                                    data-product={`{"name": "${name}", "path":"${this.state.app_site_url}${this.state.app_site_storage_path}${image}", "url":"/products?id=${id}", "aspect_ratio":0.778}`}

                                >
                                    Add To Cart
                                </button> */}
                                <AddToCartButton
                                    product_data={this.props.product_data}
                                    name={name}
                                    image={image}
                                    id={id}
                                />

                                {/* </form> */}
                            </div>
                            <div className="prd-action-right">
                                <div className="prd-action-right-inside">
                                    <div className="prd-tag"><Link to={`/store/${company_id}`}>
                                        {company_name}
                                    </Link></div>
                                    {/* <div className="prd-rating"><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});

const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist,
    };
};

// const ProductHorizontalWithRouter = withRouter(ProductHorizontal)

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(ProductHorizontal);
