import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'
// import './App.css';
import axiosConfig from './axiosConfig';

import Home from './components/Home/Index'
import Header from './components/Header'

import Footer from './components/Footer'
import ProductListing from './components/Products/ProductListing'
import Product from './components/Products/Product'

import Cart from './components/Cart/Cart'
import EmptyCart from './components/Cart/EmptyCart'
import Store from './components/Store'

import AccountCreate from './components/Account/AccountCreate'
import AccountDetailsListing from './components/Account/AccountDetailsListing';
import Login from './components/Account/Login';
import ForgotPassword from './components/Account/ForgotPassword';
import ForgotPasswordConfirm from './components/Account/ForgotPasswordConfirm';

import Logout from './components/Account/Logout';


import Checkout from './components/Checkout'
import Invoice from './components/Invoice';

import Error404 from './Error404';

import { connect } from 'react-redux'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist, replaceWishlist, replaceCart, replacePoints } from './actions'
import Helmet from 'react-helmet';



class App extends Component {
  constructor(props) {
    super(props)

    window.AppComponent = this


    this.state = {
      isLoggedIn: false,
      userdata: {},
    }

    if (localStorage.getItem('userToken')) {

      axiosConfig.get('/api/account-details?app-js')
        .then(res => {
          if (res) {
            let res_data = {
              first_name: res.data.data.first_name,
              last_name: res.data.data.last_name,
            }

            this.setState({
              isLoggedIn: true,
              userdata: res_data
            }, () => {
              if (this.state.isLoggedIn) {
                this.getWishList();
                this.getPointsSummary();
              }
            })

            let appState = {
              isLoggedIn: true
            };
            localStorage["appState"] = JSON.stringify(appState);
          }
          else {
            this.setState({
              isLoggedIn: false,
            })

            let appState = {
              isLoggedIn: false
            };
            localStorage.removeItem("userToken")
            localStorage["appState"] = JSON.stringify(appState);
          }

        })
        .catch(err => {
          if (err.response && err.response.status === 500) {
            this.setState({
              isLoggedIn: false
            })

            let appState = {
              isLoggedIn: false
            };

            localStorage["appState"] = JSON.stringify(appState);
          }
          else if (err.response && err.response.status === 401 && err.response.data === "Unauthorized.") {
            this.setState({
              isLoggedIn: false
            })

            let appState = {
              isLoggedIn: false
            };
            localStorage.removeItem("userToken")
            localStorage["appState"] = JSON.stringify(appState);
          }
        })
    }
    else {
      this.setState({
        isLoggedIn: false
      })

      let appState = {
        isLoggedIn: false
      };

      localStorage["appState"] = JSON.stringify(appState);

      // this.props.history.push('/login')
    }

    this.updateParentUserDataState = this.updateParentUserDataState.bind(this)

  }

  async getPointsSummary() {

    await axiosConfig.get(`/api/points-summary`)
      .then(res => {
        if (res.data.success) {
          this.props.replacePoints(res.data.data);
        }
      })
      .catch(err => {
      })
  }

  updateParentUserDataState(res) {
    // this.setState({
    //   userdata: res
    // })

    if (res.isLoggedIn) {
      this.setState({
        isLoggedIn: true,
      })
    } else {
      this.setState({
        isLoggedIn: false,
      })
    }
  }

  componentDidMount() {
    // this.resetJqueries();
    // if( this.state.isLoggedIn ) {
    //   this.getWishList();
    // }
  }

  // resetJqueries() {

  //   // <script defer src="js/vendor-special/lazysizes.min.js"></script>
  //   // <script defer src="js/vendor-special/ls.bgset.min.js"></script>
  //   // <script defer src="js/vendor-special/ls.aspectratio.min.js"></script>
  //   // <script defer src="js/vendor-special/jquery.min.js"></script>
  //   // <script defer src="js/vendor-special/jquery.ez-plus.js"></script>
  //   // <script defer src="js/vendor-special/instafeed.min.js"></script>
  //   // <script defer src="js/vendor/vendor.min.js"></script>
  //   // <script defer src="js/app-html.js"></script>
  //   let arr = [
  //     "js/vendor-special/lazysizes.min.js",
  //     "js/vendor-special/ls.bgset.min.js",
  //     "js/vendor-special/ls.aspectratio.min.js",
  //     "js/vendor-special/jquery.min.js",
  //     "js/vendor-special/jquery.ez-plus.js",
  //     "js/vendor-special/instafeed.min.js",
  //     "js/vendor/vendor.min.js",
  //     "js/app-html.js"
  //   ]

  //   arr.forEach(el => {
  //     if (document.getElementById(el)) {
  //       document.getElementById(el).remove()
  //     }
  //     var script = document.createElement('script');
  //     script.id = el
  //     script.src = el
  //     script.defer = true
  //     document.getElementsByTagName('body')[0].appendChild(script);

  //     script.onload = function () {

  //       // this.resetNestable();  
  //     }
  //   })


  //   // if (document.getElementById("sliders-script")) {
  //   //   document.getElementById("sliders-script").remove()
  //   // }
  //   // var script = document.createElement('script');
  //   // script.id = "sliders-script"
  //   // script.src = "/js/app-html.js";
  //   // script.defer = true
  //   // document.getElementsByTagName('body')[0].appendChild(script);

  //   // script.onload = function () {

  //   //   // this.resetNestable();  

  //   // }

  // }

  async getWishList() {
    await axiosConfig.get("/api/wishlists")
      .then(res => {
        if (res.data.success) {
          this.props.replaceWishlist(res.data.data);
        }
      })
      .catch(err => {

      })
  }


  resetJqueries() {
    let arr = [
      "/js/vendor-special/lazysizes.min.js",
      "/js/vendor-special/ls.bgset.min.js",
      "/js/vendor-special/ls.aspectratio.min.js",
      "/js/vendor-special/jquery.min.js",
      "/js/vendor-special/jquery.ez-plus.js",
      "/js/vendor/vendor.min.js",
      "/js/app-html.js"
    ]

    // if(this.state.featured_products.length == 0){
    //     arr.push("add_pfeaurd_prod")
    // }

    arr.forEach(el => {
      if (document.getElementById(el)) {
        document.getElementById(el).remove()
      }
      // var script = document.createElement('script');
      // script.id = el
      // script.src = el
      // // script.defer = true
      // // script.async = true
      // document.getElementsByTagName('body')[0].appendChild(script);

      // script.onload = function () {
      // }
    })

  }



  render() {

    return (
      <div className="body-wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="author" content="MERCHANT BITGOFULFILL" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>
            Merchant Bitgofulfill
          </title>
        </Helmet>

        <Header
          isLoggedIn={this.state.isLoggedIn}
          updateParentUserDataState={this.updateParentUserDataState}
        />
        {/* <AccountSidebar/> */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route
            exact path="/product-listing">
            <ProductListing />
          </Route>
          <Route exact path="/product">
            <Product />
          </Route>
          <Route exact path="/store/:id">
            <Store />
          </Route>
          <Route exact path="/cart">
            <Cart />
          </Route>
          <Route exact path="/cart-empty">
            <EmptyCart />
          </Route>
          <Route exact path="/register">
            <AccountCreate />
          </Route>
          <Route exact path="/login">
            <Login
              isLoggedIn={this.state.isLoggedIn}
              updateParentUserDataState={this.updateParentUserDataState}
            />
          </Route>
          <Route exact path="/reset_password">
            <ForgotPassword
              isLoggedIn={this.state.isLoggedIn}
              updateParentUserDataState={this.updateParentUserDataState}
            />

          </Route>
          <Route exact path='/password-reset'>
            <ForgotPasswordConfirm
              isLoggedIn={this.state.isLoggedIn}
              updateParentUserDataState={this.updateParentUserDataState}
            />
          </Route>
          <Route exact path="/Logout">
            <Logout
              isLoggedIn={this.state.isLoggedIn}
              updateParentUserDataState={this.updateParentUserDataState} />
          </Route>

          <Route exact path="/account-details">
            <AccountDetailsListing />
          </Route>
          <Route exact path="/account-addresses">
            <AccountDetailsListing />
          </Route>
          <Route exact path="/account-wishlist">
            <AccountDetailsListing />
          </Route>
          <Route exact path="/account-history">
            <AccountDetailsListing />
          </Route>
          <Route exact path="/account-balance">
            <AccountDetailsListing />
          </Route>
          <Route exact path="/account-balance/recharge">
            <AccountDetailsListing />
          </Route>
          <Route exact path="/account-balance/recharge/:id">
            <AccountDetailsListing />
          </Route>
          <Route exact path="/checkout">
            <Checkout />
          </Route>
          <Route exact path="/invoice">
            <Invoice />
          </Route>
          <Route exact path="/*">
            <Error404 />
          </Route>
        </Switch>
        <Footer />
        {/* {this.resetJqueries()} */}
      </div>
    )
  }
}


// const AppWithUserConsumer = WithUserConsumer(App)
// export default withRouter(App)

const mapStateToProps = state => ({
  cart_reducer: state.cart_reducer,
  wishlist_reducer: state.wishlist_reducer,
  points_reducer: state.points_reducer,
});

const mapDispatchToProps = () => {
  return {
    incrementCart, decrementCart, addToWishlist, removeFromWishlist, replaceCart, replaceWishlist, replacePoints
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withRouter(App));


