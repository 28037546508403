import React, { Component } from 'react'
import axiosConfig from '../axiosConfig'
import { withRouter } from 'react-router'

// redux
import { connect } from 'react-redux'
import { incrementCart, decrementCart, incrementCartQuantity, decrementCartQuantity, clearCart } from '../actions'
import { Redirect } from 'react-router-dom'

import { getUrlParameters, handleError } from '../helper_funcitons'
import BreadCrumb from './BreadCrumb'
import AlertMessage from '../AlertMessage'
import Spinner from '../Spinner'

class Invoice extends Component {
    constructor(props) {
        super(props)

        var appState = JSON.parse(localStorage.getItem('appState'))

        this.state = {
            isLoggedIn: appState?.isLoggedIn || false,
            validation_errors: [],
            alert_message: "",
            alert_status: "",

            order_id: "",

            order_items: [],
            delivery_data: [],
            payment_data: [],


            show_spinner: true,


            delivery_options: [],
            payment_options: [],
            selected_payment_option: '',
            selected_delivery_option: "",
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,

            account_details: [{
                account_name: "",
                account_number: "",
                bank_name: "",
                sort_code: "",
                iban: "",
                bic_swift: '',
            }],

            countries: [],
            states: [],
            billing_states: [],

            // shipping-address
            first_name: "",
            last_name: "",
            country: "",
            state: "",
            postal_code: "",
            address_1: "",
            save_address: false,


            // biiling-address
            same_as_shipping: false,
            billing_country: "",
            billing_state: "",
            billing_postal_code: "",
            billing_address_1: "",


            //credit-card
            cart_number: "",
            month: "",
            year: "",
            cvv_code: "",
            order_comment: "",


            // shipping_address
            shipping_address: {},
            billing_address: {}
        }

        this.getInvoiceData = this.getInvoiceData.bind(this)

        this.onChange = this.onChange.bind(this)

        // bank
        this.addAccountDetail = this.addAccountDetail.bind(this)
        this.removeAccountDetail = this.removeAccountDetail.bind(this)
        this.onChangeAccountDetails = this.onChangeAccountDetails.bind(this)

        this.onChangeReactSelect = this.onChangeReactSelect.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toLogin = this.toLogin.bind(this)
    }


    getInvoiceData() {
        this.setState({
            show_spinner: true,
        })

        axiosConfig.get("/api/invoice?id=" + this.state.order_id)
            .then(res => {
                this.setState({
                    show_spinner: false
                })
                if (res.data.success) {
                    let { order_items, delivery_data, payment_data, created_at, shipping_address, billing_address, order_number, order_comment } = res.data.data;
                    this.setState({
                        order_items,
                        delivery_data,
                        payment_data,
                        created_at,
                        shipping_address,
                        billing_address,
                        order_number,
                        order_comment
                    })
                }
            })
            .catch(err => {
                handleError(err, this.toLogin)
            })
    }

    toLogin() {
        this.setState({
            redirect_to_login: true
        })
    }

    getCountries() {
        axiosConfig.get("api/countries")
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        countries: res.data.data,
                    })
                }
            })
            .catch(err => {

            })
    }

    getStates(country_id) {
        axiosConfig.get("api/states/" + country_id)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        states: res.data.data,
                    })
                }
            })
            .catch(err => {

            })

    }

    async componentDidMount() {

        let url_parameters = getUrlParameters(this.props.location);
        if (url_parameters) {
            let { id, cart } = url_parameters
            console.log({ id })
            await this.setState({
                order_id: id || "",
            })

            if (cart === "clear") {
                this.props.clearCart()
            }

        }

        this.getInvoiceData();

        // this.getInvoiceData();
        // this.getDeliveryOptions();

        // this.getCountries();
        // this.getStates();
    }


    onChange(e) {
        if (e.target.type === "checkbox" || e.target.type === 'radio') {
            if (e.target.name === "selected_payment_option" || e.target.name === "selected_delivery_option") {
                this.setState({
                    [e.target.name]: e.target.value
                }, () => {
                    this.props.history.push("?delivery=" + this.state.selected_delivery_option + "&payment_type=" + this.state.selected_payment_option)
                })
            } else {
                this.setState({
                    [e.target.name]: e.target.checked
                })
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })

            let arr = ["first_name"];
            if (arr.includes(e.target.name)) {
                if (e.target.value.length > 0) {
                    e.target.parentElement.classList.remove("has-error");
                }
                else {
                    e.target.name !== "phone" && e.target.parentElement.classList.add("has-error");
                }
            }
        }
    }

    paymentOptionsFields() {
        if (this.state.selected_payment_option === "Bank") {
            return <>
            </>
        }
        return <>
            <label>Cart Number</label>
            <div className="form-group">
                <input type="text"
                    onChange={this.onChange}
                    className="form-control form-control--sm" />
            </div>
            <div className="row">
                <div className="col-sm-9">
                    <label>Month:</label>
                    <div className="form-group select-wrapper">
                        <select className="form-control form-control--sm"
                            defaultValue={1}
                            onChange={this.onChange}
                            name="month"
                        >
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-9">
                    <label>Year:</label>
                    <div className="form-group select-wrapper">
                        <select className="form-control form-control--sm">
                            <option value={2019}>2019</option>
                            <option value={2020}>2020</option>
                            <option value={2021}>2021</option>
                            <option value={2022}>2022</option>
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <label>CVV Code</label>
                <div className="form-group">
                    <input type="text"
                        onChange={this.onChange}
                        className="form-control form-control--sm" />
                </div>
            </div>
        </>

    }

    removeAccountDetail(index) {

        let temp = [...this.state.account_details];
        let removed = temp.filter((el, t_index) => t_index !== index)
        this.setState({
            account_details: removed
        })

    }

    addAccountDetail() {
        let temp = [...this.state.account_details];
        temp.push({
            account_name: "",
            account_number: "",
            bank_name: "",
            sort_code: "",
            iban: "",
            bic_swift: '',
        })
        this.setState({
            account_details: temp
        })
    }

    onChangeAccountDetails(e, index) {

        let temp = [...this.state.account_details]
        if (e) {
            temp[index][e.target.name] = e.target.value;
        }
        this.setState({
            account_details: temp

        })
    }

    async handleSubmit(e) {
    }

    onChangeReactSelect(e, event) {
        if (e) {
            this.setState({
                [event.name]: e.value
            }, () => {
                if (event.name === "country") {
                    this.getStates(this.state.country, "states")
                } else if (event.name === "billing_country") {
                    this.getStates(this.state.billing_country, "billing_states")
                }

                // let group_select_grand_parent = document.querySelector(`.${[event.name]}`);
                // group_select_grand_parent.querySelector(".has-error")?.classList.remove("has-error")

                if (e.value.length > 0) {

                    document.getElementsByName([event.name])[0].parentElement.classList.remove("has-error")
                    // e.target.parentElement.classList.remove("has-error");

                }
                else {
                    // e.target.parentElement.classList.add("has-error");
                    // document.getElementsByName([event.name])[0].add("has-error")

                }
            })
        } else {
            this.setState({
                [event.name]: ""
            })
        }
    }

    getCreditCardYear() {
        let current_year = new Date().getFullYear();
        let j = 20;
        let temp = [];
        for (let i = 0; i < j; i++) {
            if (i < 10) {
                temp.push(current_year - (10 - i))
            } else {
                temp.push(current_year + (i - 10))
            }
        }
        return temp.map(el => {
            return <option value={el}>{el}</option>
        })

    }

    printInvoice() {
        window.print();
    }

    render() {


        if (this.state.redirect_to_login) {
            return <Redirect to="/login" />
        }


        if (this.state.show_spinner) {
            return <Spinner />
        }
        // let cart_items = [...this.props.cart_reducer]

        // let total_cart_item_price = 0;

        // cart_items.forEach(el => {
        //     total_cart_item_price += (el.price * el.quantity)
        // })

        let order_items_mapping = this.state.order_items.map(el => {
            return <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.product_name}</td>
                <td>{el.quantity}</td>
                <td>{el.price}</td>
                <td className="text-left">{el.amount}</td>
            </tr>
        })

        // let payments_mapping = this.state.delivery_data.


        let {
            address_1: shipping_address_1,
            first_name: shipping_first_name,
            last_name: shipping_last_name,
            postal_code: shipping_postal_code,
            state: shipping_state,
            country: shipping_country,
        } = this.state.shipping_address


        let {
            address_1: billing_address_1,
            first_name: billing_first_name,
            last_name: billing_last_name,
            postal_code: billing_postal_code,
            state: billing_state,
            country: billing_country,
        } = this.state.billing_address


        let delivery_type = this.state.delivery_data.name;
        delivery_type = delivery_type?.replaceAll("_", " ");

        let delivery_time = this.state.delivery_data.delivery_time;

        let payment_type = this.state.payment_data.payment_type




        return (
            <div className="page-content">
                <BreadCrumb
                    title={this.state.order_id}
                    parents={[
                        {
                            title: "Home",
                            route: "/",
                        },
                        {
                            title: "Invoice",
                            route: "/",
                        },
                    ]}
                />
                <div className="holder">
                    <div className="container">
                        <div className="no-print">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-center align-items-center w-100">
                                    <h1 className="text-center mb-0">Invoice</h1>
                                </div>
                                <button type="button" className="btn btn-secondary"
                                    onClick={this.printInvoice}>
                                    Print Invoice
                                </button>
                            </div>
                        </div>
                        {
                            (this.state.alert_status !== '' && this.state.alert_message !== '')
                            &&
                            <AlertMessage
                                status={this.state.alert_status}
                                messages={this.state.alert_message}
                            />
                        }
                        <div className="card mt-2" id="printdiv">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-sm-6 form-group">
                                        <h2>To</h2>
                                        <strong className="mt-0">{billing_last_name} {billing_first_name}</strong>
                                        <p className="mt-0">{billing_address_1}{billing_address_1 && ", "}{billing_postal_code}{billing_postal_code && ", "}{billing_state}, {billing_country}</p>
                                    </div>

                                    <div className="col-sm-6 form-group">
                                        <h2>From</h2>
                                        <strong className="mt-0 text-capitalize">{shipping_last_name} {shipping_first_name}</strong>
                                        <p className="mt-0">{shipping_address_1}{shipping_address_1 && ", "}{shipping_postal_code}{shipping_postal_code && ", "}{shipping_state}, {shipping_country}</p>
                                    </div>

                                    <div className=" col-sm-6 form-group">
                                        <p className="mt-0 text-capitalize">Invoice from <strong>#{this.state.order_id}</strong></p>
                                        <p className="mt-0">
                                            {this.state.created_at?.substring(0, 10)}
                                        </p>
                                        <p className="mt-0 ">Delivery on <span className="text-capitalize">{delivery_type} ( {delivery_time} )</span></p>
                                        <p className="mt-0">Payment from {payment_type}</p>
                                    </div>
                                </div>

                                <hr className="my-2" />
                                <div>
                                    <h2>Item Details</h2>
                                    <table className="table table-bordered table-striped table-order-history">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Quantity </th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Amount</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.order_items.length > 0
                                                    ?
                                                    order_items_mapping
                                                    :
                                                    <tr>
                                                        <td colSpan="4">
                                                            <p className="text-center mt-4"> There are no records to display.</p>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="mt-3">
                                    <h2>Order Note:</h2>
                                    <p className="border border-light p-4 bg-light rounded" style={{ marginTop: 10 }}>{this.state.order_comment}</p>
                                </div>

                                <p className="text-center">
                                    If you have any questions, please send an email to <span><a href="mailto:info@bitgofulfill.com" className="text-primary text-center">info@bitgofulfill.com</a></span>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}



const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, incrementCartQuantity, decrementCartQuantity, clearCart
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(Invoice));
