import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import axiosConfig from '../../axiosConfig';
import { handleError, scrollToTop, inputValidation } from '../../helper_funcitons';
import InputValidationErrorMessage from '../../inputValidationErrorMessage';

export default class AccountRecharge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation_errors: [],
            redirect_to_login: false,

            linked_account: "",
            total_funds: "",
            files: [],
            in_process: false,
            created_at: "",
            request_balance_process_spinner: false,

            merchant_app_site_url: process.env.REACT_APP_MERCHANT_API,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,



        }

        this.toLogin = this.toLogin.bind(this)
        this.onChange = this.onChange.bind(this)
        this.uploadVoucherFile = this.uploadVoucherFile.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    toLogin() {
        this.setState({
            redirect_to_login: true
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === "total_funds") {
            if (e.target.value.length > 0) {
                e.target.parentElement.classList.remove("has-error");
            }
            else {
                e.target.parentElement.classList.add("has-error");
            }
        }

    }

    handleSave(e) {
        e.preventDefault();

        let validation_fields = [
            "linked_account",
            'total_funds',
        ];

        let arr = [];
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })

        let validation_errors = inputValidation(arr);

        if (this.state.files?.length === 0) {
            validation_errors.push({
                error: "",
                error_field: "files"
            })
        }

        this.setState({
            validation_errors: validation_errors
        })

        if (validation_errors.length === 0) {

            this.setState({
                request_balance_process_spinner: true
            })



            let url = "/api/points-request"

            var bodyFormData = new FormData();
            bodyFormData.append('description', this.state.description || "")
            bodyFormData.append('transferred_balance', this.state.total_funds || "")
            bodyFormData.append('linked_account', this.state.linked_account || "")

            if (this.state.files?.length > 0) {
                this.state.files?.forEach((el, index) => {
                    bodyFormData.append(`image_${index}`, this.state.files[index]);
                })
            }

            axiosConfig.post(url, bodyFormData)
                .then(res => {
                    this.setState({
                        request_balance_process_spinner: false,
                    })

                    if (res.data.success) {
                        this.setState({
                            in_process: true,
                            transaction_id: res.data.transaction_id,
                            created_at: res.data.created_at,
                            files: JSON.parse(res.data.files),
                        })
                    }

                }).catch(err => {
                    this.setState({
                        request_balance_process_spinner: false,
                    })

                    handleError(err, this.toLogin)
                })
        }

    }

    uploadVoucherFile(e) {
        let new_files = [...e.target.files]
        let temp = [...this.state.files];
        let max_length = 5;
        new_files.some(el => {
            if (temp.length < max_length) {
                temp.push(el)
            } else {
                alert("Only 5 files allowed ")
            }
            return true;
        })

        this.setState({
            files: temp
        })
    }

    componentDidMount() {
        scrollToTop(0, 0)
    }


    getLocalTime(db_date) {
        if (db_date) {
            //  2021-09-07 07:44:30 
            // 2021-09-07T07:42:44.000000Z  => UTC time

            if (!db_date.includes("Z")) {
                db_date += " UTC";
            }

            let date = new Date(db_date);
            let result = date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear() +
                " " + (date.getHours().toString().length === 1 ? "0" + date.getHours().toString() : date.getHours()) +
                ":" + (date.getMinutes().toString().length === 1 ? "0" + date.getMinutes().toString() : date.getMinutes()) +
                ":" + (date.getSeconds().toString().length === 1 ? "0" + date.getSeconds().toString() : date.getSeconds());

            return result;
        }
    }

    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }} />
        }

        let linked_account_validation = ''
        if (this.state.validation_errors.filter(el => el.error_field === "linked_account").length > 0 && (!this.state.linked_account)) {
            linked_account_validation = <span className="text-danger"> ( The field is required ) </span>
        }


        let files_validation = ''
        if (this.state.validation_errors.filter(el => el.error_field === "files").length > 0 && (this.state.files?.length === 0)) {
            files_validation = <span className="text-danger"> ( The field is required ) </span>
        }

        return (
            // <div className="col-md-18 aside">
            //     <h2 className="mb-2" id="account-balance-title">Add Account Funds</h2>

            //     <div className="card mb-4 add-account-funds">
            //         <div className="card-body p-5">
            //             <form onSubmit={this.handleSave} onReset={()=>{
            //                 this.setState({
            //                     linked_account:"",
            //                     total_funds:"",
            //                     validation_errors:[],
            //                     files:[],
            //                 })
            //             }}>
            //                 <div className="form-group row">
            //                     <label htmlFor="linked_account" className="col-sm-6 col-form-label required-input_star">Linked Account {linked_account_validation}

            //                     </label>
            //                     <div className="col-sm-12">
            //                         <div className="form-check form-check-inline">
            //                             <input className="form-check-input"
            //                                 onChange={this.onChange}
            //                                 type="radio" name="linked_account" id="wire_transfer" value="wire_transfer" />
            //                             <label className="form-check-label" htmlFor="wire_transfer">Wire transfer</label>
            //                         </div>
            //                         <div className="form-check form-check-inline">
            //                             <input className="form-check-input" onChange={this.onChange}
            //                                 type="radio" name="linked_account" id="payooner" value="payooner" />
            //                             <label className="form-check-label" htmlFor="payooner">Payoneer</label>
            //                         </div>
            //                     </div>
            //                 </div>
            //                 <div className="form-group row">
            //                     <label htmlFor="total_funds" className="col-sm-6 col-form-label required-input_star">Total Account Funds to be added</label>
            //                     <div className="col-sm-6">
            //                         <input type="number"
            //                             className="form-control"
            //                             id="total_funds" name="total_funds"
            //                             value={this.state.total_funds}
            //                             onChange={this.onChange}
            //                             placeholder="" step=".01" />
            //                         <InputValidationErrorMessage
            //                             validation_errors={this.state.validation_errors}
            //                             input_field={this.state.total_funds}
            //                             error_field="total_funds"
            //                         />
            //                     </div>
            //                 </div>
            //                 <div className="form-group">
            //                     <label htmlFor="uploads" className="col-form-label required-input_star">Bank Voucher Upload {files_validation}</label>
            //                     <input type="file" name="files" accept="image/*,.pdf"
            //                         multiple
            //                         onChange={this.uploadVoucherFile}
            //                         className="upload d-block"
            //                         data-img-types="['image\png','image\jpeg','image\jpg']" />
            //                 </div>
            //                 <div className="upload-tip mb-5">5 pictures maximum, cannot exceed 30MB, supported files: .JPG, .PNG</div>

            //                 <hr />

            //                 <div className="text-center mt-5">
            //                     <button type="submit" className="btn btn-primary">Submit</button>
            //                     <button type="reset" className="btn btn-secondary ml-2">Clear</button>
            //                 </div>

            //             </form>
            //         </div>
            //     </div>
            // </div>

            <div className="col-md-18 aside">
                <h2 className="mb-2" id="account-balance-title">Account Funds Detail</h2>

                <div className="tab-content p-2" id="accountBalanceTabContent">
                    <div className="tab-pane fade show active p-sm-5" role="tabpanel" aria-labelledby="account-balance-tab">
                        <div className="row justify-content-between align-items-top  px-sm-5">
                            <div className="d-flex flex-column align-items-center" style={{
                                width: "60px"
                            }} >
                                <button type="button" className="btn-primary rounded-circle border-0"
                                    style={{
                                        height: "60px",
                                        width: "60px",
                                        background: "#5378f4",
                                    }}>
                                    <i className="icon-filter"></i>
                                </button>
                                <span className="text-nowrap font-weight-bold" style={{
                                    color: "#5378f4",
                                }}>Submit</span>
                                <span className="d-none d-sm-block text-nowrap">{this.getLocalTime(this.state.created_at)}</span>
                                <span className="d-sm-none ">{this.getLocalTime(this.state.created_at)}</span>
                            </div>
                            <span className=""
                                style={{
                                    height: "3px",
                                    flexGrow: "1",
                                    marginTop: "30px",
                                    backgroundColor: this.state.in_process ? "#5378f4" : "#6c757d",
                                }}>
                            </span>
                            <div className="d-flex flex-column align-items-center" style={{
                                width: "60px"
                            }}>
                                <button type="button" className="btn-primary rounded-circle border-0"
                                    style={{
                                        height: "60px",
                                        width: "60px",
                                        backgroundColor: this.state.in_process ? "#5378f4" : "#6c757d",
                                    }}>
                                    <i className="icon-filter"></i>
                                </button>
                                <span className="text-nowrap font-weight-bold" style={{
                                    color: this.state.in_process ? "#5378f4" : "#6c757d",
                                }}>Processing</span>
                            </div>
                            <span className=""
                                style={{
                                    height: "3px",
                                    flexGrow: "1",
                                    marginTop: "30px",
                                    backgroundColor: this.state.completed_at ? "#5378f4" : "#6c757d",
                                }}>
                            </span>
                            <div className="d-flex flex-column align-items-center" style={{
                                width: "60px"
                            }}>
                                <button type="button" className="btn-primary rounded-circle border-0 -0"
                                    style={{
                                        height: "60px",
                                        width: "60px",
                                        background: this.state.completed_at ? "#5378f4" : "#6c757d",
                                    }}>
                                    <i className="icon-filter"></i>
                                </button>
                                <span className="text-nowrap font-weight-bold" style={{
                                    color: this.state.completed_at ? "#5378f4" : "#6c757d",
                                }}>Completed</span>
                                <span className="text-nowrap">{this.state.completed_at}</span>
                            </div>

                        </div>


                        {/* <span>Account Funding Details</span> */}
                        <div className="d-flex justify-content-between mt-3 ">
                            <div className="text-center" style={{
                                borderBottom: "2px solid #5378f4",
                                width: "33%",
                            }}
                            >
                                <span className=" font-weight-bold" style={{
                                    color: "#5378f4"
                                }}>
                                    {this.state.in_process ? "Processing" : "Submit"}
                                </span>

                            </div>
                            <span>
                            </span>
                            <span>
                            </span>

                        </div>
                        <div className="card mb-4 add-account-funds mt-0" style={{
                        }}>
                            <div className="card-body ">
                                <form onSubmit={this.handleSave} onReset={() => {
                                    this.setState({
                                        linked_account: "",
                                        total_funds: "",
                                        validation_errors: [],
                                        files: [],
                                    })
                                }}>
                                    {
                                        !this.state.in_process
                                            ?
                                            <>
                                                <div className="form-group row">
                                                    <label htmlFor="linked_account" className="col-sm-6 col-form-label required-input_star">Linked Account {linked_account_validation}

                                                    </label>
                                                    <div className="col-sm-12">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                onChange={this.onChange}
                                                                type="radio" name="linked_account" id="wire_transfer" value="wire_transfer" />
                                                            <label className="form-check-label" htmlFor="wire_transfer">Wire transfer</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" onChange={this.onChange}
                                                                type="radio" name="linked_account" id="payooner" value="payooner" />
                                                            <label className="form-check-label" htmlFor="payooner">Payoneer</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="total_funds" className="col-sm-6 col-form-label required-input_star">Total Account Funds to be added</label>
                                                    <div className="col-sm-6">
                                                        <input type="number"
                                                            className="form-control"
                                                            id="total_funds" name="total_funds"
                                                            value={this.state.total_funds}
                                                            onChange={this.onChange}
                                                            placeholder="" step=".01" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.total_funds}
                                                            error_field="total_funds"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="uploads" className="col-form-label required-input_star">Bank Voucher Upload {files_validation}</label>
                                                    <input type="file" name="files" accept="image/*,.pdf"
                                                        multiple
                                                        onChange={this.uploadVoucherFile}
                                                        className="upload d-block"
                                                        data-img-types="['image\png','image\jpeg','image\jpg']" />
                                                </div>
                                                <div className="upload-tip mb-5">5 pictures maximum, cannot exceed 30MB, supported files: .JPG, .PNG</div>

                                                <hr />

                                                <div className="text-center mt-5">
                                                    <button
                                                        disabled={this.state.request_balance_process_spinner}
                                                        type="submit" className="btn btn-primary">Submit</button>
                                                    <button type="reset" className="btn btn-secondary ml-2">Clear</button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="form-group row">
                                                    <label htmlFor="linked_account" className="col-sm-6 col-form-label ">Linked Account {linked_account_validation}

                                                    </label>
                                                    <input type="text"
                                                        className="form-control col-sm-12"
                                                        value={this.state.linked_account}
                                                    />

                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="linked_account" className="col-sm-6 col-form-label ">Transaction ID

                                                    </label>
                                                    <input type="text"
                                                        className="form-control col-sm-12"
                                                        value={this.state.transaction_id}
                                                    />

                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="total_funds" className="col-sm-6 col-form-label ">Total Account Funds to be added</label>
                                                    <input type="number"
                                                        className="form-control col-sm-12"
                                                        id="total_funds" name="total_funds"
                                                        value={this.state.total_funds}
                                                        onChange={this.onChange}
                                                        placeholder="" step=".01" />
                                                </div>
                                                <div className="form-group row ">
                                                    <label htmlFor="uploads" className="col-form-label col-sm-6 ">Bank Voucher Upload</label>
                                                    <div className="col-sm-12">
                                                        <div className="row">
                                                            {
                                                                this.state.files.map((el, index) => {

                                                                    return <img src={`${this.state.app_site_storage_path}${el}`}
                                                                        // return <img src={`${el.target.result}`}

                                                                        style={{
                                                                            // width: "33%",
                                                                            height: "150px",
                                                                            width: "150px",

                                                                        }}
                                                                        className={`m-1`}
                                                                        alt=''
                                                                    />
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                    }

                                </form>
                            </div>
                        </div>

                    </div>

                </div>

            </div>



        )
    }
}
