import React, { Component } from 'react'
import BreadCrumb from '../BreadCrumb'
import axiosConfig from '../../axiosConfig';
import { Redirect, withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

import QuickViewModal from '../../QuickViewModal';

import { connect } from 'react-redux'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist } from '../../actions'

import { getImagesArr, getMainImage, getUrlParameters, scrollToTop, getParsedDiscription } from '../../helper_funcitons';
import WishListButton from '../WishListButton';
import Spinner from '../../Spinner';

import ProductsRecommended from '../../ProductsRecommended';
import HelmetComponent from '../../HelmetComponent';

class Product extends Component {
    constructor(props) {
        super(props);

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.slider_ref = React.createRef();

        this.state = {
            isLoggedIn: appState ? appState.isLoggedIn : false,
            show_spinner: true,
            // product-data
            sku: "",
            name: "",
            description: "",
            short_description: "",
            sizing_guide: "",
            price: "",
            color: [],
            size: [],
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,
            product_quantity: "",
            quantity: 1, // quantity to be added to cart
            image: "",
            product_id: "",
            product_data: {},

            show_quickview_modal: false,
            quick_product_id: "",

            redirect_to_login: false,

            warrants: "",
            marketplace_policies: "",


            product_images: []
        }

        this.getProductData = this.getProductData.bind(this)
        this.changeQuantity = this.changeQuantity.bind(this)
        this.addToCart = this.addToCart.bind(this)
        this.toggleQuickViewModal = this.toggleQuickViewModal.bind(this)
        this.product_images = [];
    }

    toggleQuickViewModal(id, quick_view_product = {}) {
        this.setState({
            product_id: id,
            quick_view_product

        }, () => {
            this.setState((prev) => ({
                show_quickview_modal: !prev.show_quickview_modal
            }))
        })
    }

    async getProductData() {
        await axiosConfig.get("/api/product/edit/" + this.state.product_id)
            .then(res => {
                this.setState({
                    show_spinner: false
                })
                if (res.data.success) {
                    let { id, name, description, short_description, sizing_guide, color, size, price, image, sku, total_qty, category_id, category_name,
                        weight_lbs, length_in, width_in, height_in } = res.data.data;
                    this.setState({
                        product_id: id,
                        product_data: res.data.data,
                        product_quantity: total_qty,
                        sku,
                        name,
                        description: getParsedDiscription(description),
                        // description,
                        // short_description: short_description ? JSON.parse(short_description) : "",
                        short_description: getParsedDiscription(short_description),
                        sizing_guide,

                        price,
                        // image,
                        category_id,
                        category_name,
                        color: color ? JSON.parse(color) : [],
                        size: size ? JSON.parse(size) : [],
                        weight_lbs, length_in, width_in, height_in,

                    }, async () => {


                        let temp = getImagesArr(image)


                        //  show main first
                        let main_images = temp.filter(el => el.main === true);
                        let not_main_images = temp.filter(el => el.main !== true);

                        let all = [...main_images, ...not_main_images]
                        await this.setState({
                            product_images: all

                        }, () => {
                            this.product_images = all;
                            // this.removeSlick();
                            setTimeout(() => {
                                this.resetJqueries()
                            }, 500)
                        })

                        await this.setState({
                            image: getMainImage(image)

                        })

                    })

                } else {
                    if (this.props.history.action !== "POP") {
                        this.props.history.goBack();
                    } else {
                        this.props.history.push("/")
                    }
                }
            })
            .catch(err => {
                this.setState({
                    show_spinner: false
                })
            })
    }


    async componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {

            await this.setState({
                show_spinner: true,
            })

            let old_url_parameters = getUrlParameters(prevProps.location)
            let new_url_parameters = getUrlParameters(this.props.location)

            if (old_url_parameters && new_url_parameters) {
                if (old_url_parameters.id !== new_url_parameters.id) {

                    this.setState({
                        product_id: new_url_parameters.id
                    }, async () => {
                        await this.getProductData();
                        scrollToTop()
                    })
                }

            }
        }


    }


    async componentDidMount() {

        scrollToTop()

        let url_params = getUrlParameters(this.props.location)
        url_params.id && this.setState({
            product_id: url_params.id
        }, async () => {
            await this.getProductData()
            await this.getMerchantOptions();
        })


    }

    changeQuantity(type) {

        // this.setState({
        //     quantity: document.getElementById("product-quantity").value
        // })
        if (type === 'add') {
            this.setState((prev) => ({
                quantity: parseInt(prev.quantity) + 1
            }))
        } else {
            if (this.state.quantity !== 1) {

                this.setState((prev) => ({
                    quantity: parseInt(prev.quantity) - 1
                }))
            }
        }

    }

    addToCart(el) {

        if (this.state.isLoggedIn) {

            let obj = {
                id: el.id,
                name: el.name,
                price: el.price,
                quantity: this.state.quantity,
                image: getMainImage(el.image)

            }
            this.props.incrementCart(obj);
            this.setState({
                quantity: 1,
            })
        } else {
            this.setState({
                redirect_to_login: true,
            })
        }
    }

    resetJqueries() {

        // for  image-sliders 
        let arr = [
            // "js/vendor-special/lazysizes.min.js",
            // "js/vendor-special/ls.bgset.min.js",
            // "js/vendor-special/ls.aspectratio.min.js",
            // "js/vendor-special/jquery.min.js",
            // "js/vendor-special/jquery.ez-plus.js",
            // "js/vendor-special/instafeed.min.js",
            // "js/vendor/vendor.min.js",
            "/js/app-html.js"
        ]

        // if(this.state.featured_products.length == 0){
        //     arr.push("add_pfeaurd_prod")
        // }

        arr.forEach(el => {
            if (document.getElementById(el)) {
                document.getElementById(el).remove()
            }
            var script = document.createElement('script');
            script.id = el
            script.src = el
            script.defer = true
            // script.async = true
            document.getElementsByTagName('head')[0].appendChild(script);

            script.onload = function () {
                // window.MapComponent.resetPartnersSlider();
            }
        })




    }

    removeSlick() {
        if (document.getElementById("remove_slick_script")) {
            document.getElementById("remove_slick_script").remove()
        }
        var script2 = document.createElement('script');
        script2.id = "remove_slick_script"
        script2.text = `
            $(".recommended-products a.image-container").on( 'click', function() {
                if( $('.js-product-main-carousel').hasClass( 'slick-initialized' ) ) {
                    $('.js-product-main-carousel').slick('unslick');
                }
                else {
                }
                
            } )
        `
        script2.defer = true
        // script.async = true
        // document.getElementsByTagName('body')[0].appendChild(script2);
    }


    async getMerchantOptions() {
        let url = "/api/merchant-single-product-contents";
        await axiosConfig.get(url)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        warrants: getParsedDiscription(res.data.data.warrants),
                        marketplace_policies: getParsedDiscription(res.data.data.marketplace_policies),
                    }, () => {
                    })
                }
            })
            .catch(err => {
            })
    }

    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }} />
        }

        if (this.state.show_spinner) {
            return <Spinner />
        }

        let slider_images_mapping = this.state.product_images.map((el, index) => {

            return <div data-value="Beige" data-image-path={`${el.path}`} key={`${el}${index}`}>
                <span className="prd-img" style={{
                    paddingBottom: "0px"
                }}>
                    <img
                        src={`${this.state.app_site_storage_path}${el.path}`}
                        className="lazyload fade-up elzoom"
                        alt=""
                    />
                </span>
            </div>

        })

        // let holder_img_mapping = this.state.product_images.map(el => {
        //     return <a href="#">
        //         <span className="prd-img" style={{
        //             paddingBottom: "0px"
        //         }}>
        //             <img className="lazyload fade-up"
        //                 src={`${this.state.app_site_storage_path}${el.path}`}
        //                 alt="" />
        //             <i className="icon-arrow-left" />
        //         </span>
        //     </a>
        // })

        let bottom_slider_mapping = this.state.product_images.map((el, index) => {
            return <a href="#" data-value="Beige" key={`${el}${index}`}>
                <span className="prd-img" style={{
                    paddingBottom: "0px"
                }}>
                    <img
                        src={`${this.state.app_site_storage_path}${el.path}`}
                        className="lazyload fade-up"
                        style={{
                        }}
                        alt='' />
                </span>
            </a>
        })




        return (
            <div className="page-content">
                <HelmetComponent
                    title={this.state.name}
                />

                <BreadCrumb
                    title={this.state.name || " "}
                    parents={[
                        {
                            title: "Home",
                            route: "/",
                        },
                        {
                            title: "Product",
                            route: "/",
                        },
                    ]}
                />
                <div className="holder">
                    <div className="container js-prd-gallery" id="prdGallery">
                        {/* <div className="row prd-block prd-block-under prd-block--prv-bottom d-none">
                            <div className="col-md-auto prd-block-prevnext-wrap">
                                <div className="prd-block-prevnext" >
                                    {holder_img_mapping}
                                </div>
                            </div>
                        </div> */}

                        <div className="row prd-block prd-block--prv-bottom">
                            <div className="col-md-8 col-lg-8 col-xl-8 aside--sticky js-sticky-collision">
                                <div className="aside-content">
                                    {/* Product Gallery */}
                                    <div className="mb-2 js-prd-m-holder" />
                                    <div className="prd-block_main-image">
                                        <div className="prd-block_main-image-holder" id="prdMainImage">
                                            <div className="product-main-carousel js-product-main-carousel" id="slider_images_mapping" data-zoom-position="inner" ref={this.slider_ref}>
                                                {slider_images_mapping}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-previews-wrapper">
                                        <div className="product-previews-carousel js-product-previews-carousel" id="bottom_slider_mapping">
                                            {
                                                bottom_slider_mapping
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-10 col-lg-10 col-xl-10 mt-1 mt-md-0">
                                <div className="prd-block_info prd-block_info--style1" data-prd-handle="/products/copy-of-suede-leather-mini-skirt">


                                    <div className="prd-holder prd-block_info_item order-0 mt-15 mt-md-0">
                                        <div className="prd-block_title-wrap">
                                            {/* <div className="prd-block_reviews" data-toggle="tooltip" data-placement="top" title data-original-title="Scroll To Reviews"><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star" />
                                                <span className="reviews-link"><a href="#" className="js-reviews-link"> (17 reviews)</a></span>
                                            </div> */}
                                            <h1 className="prd-block_title">{this.state.name}</h1>
                                        </div>
                                    </div>


                                    <div className="prd-block_info-top prd-block_info_item order-0 order-md-2">
                                        <div className="prd-block_price prd-block_price--style2">
                                            <div className="prd-block_price--actual">${this.state.isLoggedIn ? this.state.price : "**"}</div>
                                            {/* <div className="prd-block_price-old-wrap">
                                                <span className="prd-block_price--old">$210.00</span>
                                                <span className="prd-block_price--text">You Save: $131.99 (28%)</span>
                                            </div> */}
                                        </div>
                                        {/* <div className="prd-block_viewed-wrap d-none d-md-flex">
                                            <div className="prd-block_viewed">
                                                <i className="icon-time" />
                                                <span>This product was viewed 13 times within last hour</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="prd-block_description prd-block_info_item  mb-0">
                                        <h3>Short description</h3>
                                        {/* <p>{
                                            this.state.description
                                        }</p> */}
                                        <p id="" dangerouslySetInnerHTML={{ __html: this.state.short_description }}></p>
                                        {/* <div className="mt-1" /> */}
                                        {/* <div className="row vert-margin-less">
                                            <div className="col-sm">
                                                <ul className="list-marker">
                                                    <li>100% Polyester</li>
                                                    <li>Lining:100% Viscose</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm">
                                                <ul className="list-marker">
                                                    <li>Do not dry clean</li>
                                                    <li>Only non-chlorine</li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="prd-progress prd-block_info_item" data-left-in-stock>
                                        <div className="prd-progress-text">
                                            <span className="prd-progress-text-left js-stock-left">{this.state.isLoggedIn ? this.state.product_quantity : "**"}</span> in stock
                                        </div>
                                        <div className="prd-progress-text-null" />
                                        <div className="prd-progress-bar-wrap progress">
                                            <div className="prd-progress-bar progress-bar active" data-stock="50, 10, 30, 25, 1000, 15000" style={{ width: '53%' }} />
                                        </div>
                                    </div>
                                    {/* <div className="prd-block_countdown js-countdown-wrap prd-block_info_item countdown-init">
                                            <div className="countdown-box-full-text w-md">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-sm-auto text-center">
                                                        <div className="countdown js-countdown" data-countdown="2021/07/01" />
                                                    </div>
                                                    <div className="col">
                                                        <div className="countdown-txt"> TIME IS RUNNING OUT!</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         <div className="prd-block_order-info prd-block_info_item " data-order-time data-locale="en">
                                        <i className="icon-box-2" />
                                        <div>Order in the next <span className="prd-block_order-info-time countdownCircleTimer" data-time="8:00:00, 15:30:00, 23:59:59"><span><span>04</span>:</span><span><span>46</span>:</span><span><span>24</span></span></span> to get it by <span data-date>Tuesday, September 08, 2020</span></div>
                                    </div>  */}
                                    <div className="prd-block_info_item prd-block_info-when-arrives d-none" data-when-arrives>
                                        <div className="prd-block_links prd-block_links m-0 d-inline-flex">
                                            <i className="icon-email-1" />
                                            <div><a href="#" data-follow-up data-name="Oversize Cotton Dress" className="prd-in-stock" data-src="#whenArrives">Inform me when the item arrives</a></div>
                                        </div>
                                    </div>
                                    <div className="prd-block_info-box prd-block_info_item">
                                        <div className="two-column"><p>Availability:
                                            <span className="prd-in-stock" data-stock-status>In stock</span></p>
                                            {/* <p className="prd-taxes">Tax Info:
                                                <span>Tax included.</span>
                                            </p> */}
                                            <p>Category: <span>
                                                <Link to={`/product-listing?category_id=${this.state.category_id}`} data-toggle="tooltip" data-placement="top" data-original-title="View all">
                                                    {this.state.category_name}
                                                </Link>
                                            </span>

                                            </p>
                                            <p>Sku: <span data-sku>{this.state.sku}</span></p>
                                            {/* <p>Vendor: <span>Banita</span></p> */}
                                            {/* <p>Barcode: <span>314363563</span></p> */}
                                        </div>
                                    </div>
                                    <div className="order-0 order-md-100">
                                        <form method="post" action="#">
                                            <div className="prd-block_options">
                                                {/* <div className="prd-color swatches">
                                                    <div className="option-label">Color:</div>
                                                    <select className="form-control hidden single-option-selector-modalQuickView" id="SingleOptionSelector-0" data-index="option1">
                                                        <option value="Beige" selected="selected">Beige</option>
                                                        <option value="Black">Black</option>
                                                        <option value="Red">Red</option>
                                                    </select>
                                                    <ul className="/images-list js-size-list" data-select-id="SingleOptionSelector-0">
                                                        <li className="active">
                                                            <a href="#" data-value="Beige" data-toggle="tooltip" data-placement="top" data-original-title="Beige"><span className="image-container image-container--product"><img src="/images/skins/fashion/product-page/product-01.jpg" alt="" /></span></a>
                                                        </li>

                                                        <li>
                                                            <a href="#" data-value="Black" data-toggle="tooltip" data-placement="top" data-original-title="Black"><span className="image-container image-container--product"><img src="/images/skins/fashion/product-page/product-04.jpg" alt="" /></span></a>
                                                        </li>

                                                        <li>
                                                            <a href="#" data-value="Red" data-toggle="tooltip" data-placement="top" data-original-title="Red"><span className="image-container image-container--product"><img src="/images/skins/fashion/product-page/product-07.jpg" alt="" /></span></a>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                                <div className="prd-size swatches">
                                                    <div className="option-label">Size:</div>
                                                    <select className="form-control hidden single-option-selector-modalQuickView" id="SingleOptionSelector-1" data-index="option2">
                                                        <option value="Small" selected="selected">Small</option>
                                                        <option value="Medium">Medium</option>
                                                        <option value="Large">Large</option>
                                                    </select>
                                                    <ul className="size-list js-size-list" data-select-id="SingleOptionSelector-1">
                                                        <li className="active"><a href="#" data-value="Small"><span className="value">Small</span></a></li>
                                                        <li><a href="#" data-value="Medium"><span className="value">Medium</span></a></li>
                                                        <li><a href="#" data-value="Large"><span className="value">Large</span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="prd-block_actions prd-block_actions--wishlist">
                                                <div className="prd-block_qty">
                                                    <div className="qty qty-changer">
                                                        <button type="button" className="decrease"
                                                            onClick={() => this.changeQuantity("subt")}
                                                        />
                                                        <input type="number" id="product-quantity" className="qty-input" name="quantity"
                                                            // defaultValue={1}
                                                            data-min={1}
                                                            // data-max={1000}
                                                            value={this.state.quantity}
                                                        />
                                                        <button type="button" className="increase"
                                                            onClick={() => this.changeQuantity("add")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="btn-wrap">
                                                    <button
                                                        type="button"
                                                        className="btn btn--add-to-cart js-trigger-addtocart js-prd-addtocart"
                                                        data-product={this.state.isLoggedIn ? `{"name": "${this.state.name ? this.state.name.replaceAll('"', " ").replaceAll(",", " ") : ""}", "path":"${this.state.app_site_storage_path}${this.state.image}", "url":"/products?id=${this.state.product_id}", "aspect_ratio":0.778}` : undefined}
                                                        onClick={() => this.addToCart(this.state.product_data)}
                                                    >
                                                        Add to cart</button>
                                                </div>
                                                <div className="btn-wishlist-wrap heart--big pt-2 pl-1">
                                                    {/* <a href="#" className="btn-add-to-wishlist ml-auto btn-add-to-wishlist--add js-add-wishlist" title="Add To Wishlist"><i className="icon-heart-stroke" /></a> */}
                                                    {/* <a href="#" className="btn-add-to-wishlist ml-auto btn-add-to-wishlist--off js-remove-wishlist" title="Remove From Wishlist"><i className="icon-heart-hover" /></a> */}
                                                    <WishListButton
                                                        product_data={this.state.product_data}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        {/* <div className="prd-block_agreement prd-block_info_item order-0 order-md-100 text-right" data-agree>
                                            <input id="agreementCheckboxProductPage" className="js-agreement-checkbox" data-button=".shopify-payment-agree" name="agreementCheckboxProductPage" type="checkbox" />
                                            <label htmlFor="agreementCheckboxProductPage"><a href="#" data-fancybox className="modal-info-link" data-src="#agreementInfo">I agree to the terms of service</a></label>
                                        </div> */}
                                    </div>
                                    <div className="prd-block_info_item">
                                        {/* <ul className="prd-block_links list-unstyled">
                                            <li><i className="icon-size-guide" /><a href="#" data-fancybox className="modal-info-link" data-src="#sizeGuide">Size Guide</a></li>
                                            <li><i className="icon-delivery-1" /><a href="#" data-fancybox className="modal-info-link" data-src="#deliveryInfo">Delivery and Return</a></li>
                                            <li><i className="icon-email-1" /><a href="#" data-fancybox className="modal-info-link" data-src="#contactModal">Ask about this product</a></li>
                                        </ul> */}
                                        <div id="sizeGuide" style={{ display: 'none' }} className="modal-info-content modal-info-content-lg">
                                            <div className="modal-info-heading">
                                                <div className="mb-1"><i className="icon-size-guide" /></div>
                                                <h2>Size Guide</h2>
                                            </div>
                                            <div className="sizing-guide-image"
                                            // style={{ backgroundImage: `url(${this.state.app_site_storage_path}${this.state.sizing_guide})` }}
                                            >
                                                <img
                                                    // src="/images/skins/electronics/products/product-07-1.jpg"
                                                    src={`${this.state.app_site_storage_path}${this.state.sizing_guide}`}
                                                    style={{
                                                        width: "100%",
                                                        objectFit: "contain",
                                                    }}
                                                    alt="different sizes guide"
                                                    className="js-prd-img" />
                                            </div>
                                            {/* <div className="table-responsive">
                                                <table className="table table-striped table-borderless text-center">
                                                    <thead>
                                                        <tr>
                                                            <th>USA</th>
                                                            <th>UK</th>
                                                            <th>France</th>
                                                            <th>Japanese</th>
                                                            <th>Bust</th>
                                                            <th>Waist</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>8</td>
                                                            <td>36</td>
                                                            <td>7</td>
                                                            <td>32"</td>
                                                            <td>61 cm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6</td>
                                                            <td>10</td>
                                                            <td>38</td>
                                                            <td>11</td>
                                                            <td>34"</td>
                                                            <td>67 cm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>8</td>
                                                            <td>12</td>
                                                            <td>40</td>
                                                            <td>15</td>
                                                            <td>36"</td>
                                                            <td>74 cm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>10</td>
                                                            <td>14</td>
                                                            <td>42</td>
                                                            <td>17</td>
                                                            <td>38"</td>
                                                            <td>79 cm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>12</td>
                                                            <td>16</td>
                                                            <td>44</td>
                                                            <td>21</td>
                                                            <td>40"</td>
                                                            <td>84 cm</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                         */}
                                        </div>
                                        <div id="deliveryInfo" style={{ display: 'none' }} className="modal-info-content modal-info-content-lg">
                                            <div className="modal-info-heading">
                                                <div className="mb-1"><i className="icon-delivery-1" /></div>
                                                <h2>Delivery and Return</h2>
                                            </div>
                                            <br />
                                            <h5>Our parcel courier service</h5>
                                            <p>Foxic is proud to offer an exceptional international parcel shipping service. It is straightforward and very easy to organise international parcel shipping. Our customer service team works around the clock to make sure that you receive high quality courier service from start to finish.</p>
                                            <p>Sending a parcel with us is simple. To start the process you will first need to get a quote using our free online quotation service. From this, you’ll be able to navigate through the online form to book a collection date for your parcel, selecting a shipping day suitable for you.</p>
                                            <br />
                                            <h5>Shipping Time</h5>
                                            <p>The shipping time is based on the shipping method you chose.<br />
                                                EMS takes about 5-10 working days for delivery.<br />
                                                DHL takes about 2-5 working days for delivery.<br />
                                                DPEX takes about 2-8 working days for delivery.<br />
                                                JCEX takes about 3-7 working days for delivery.<br />
                                                China Post Registered Mail takes 20-40 working days for delivery.</p>
                                        </div>
                                        <div id="contactModal" style={{ display: 'none' }} className="modal-info-content modal-info-content-sm">
                                            <div className="modal-info-heading">
                                                <div className="mb-1"><i className="icon-envelope" /></div>
                                                <h2>Have a question?</h2>
                                            </div>
                                            <form method="post" action="#" id="contactForm" className="contact-form">
                                                <div className="form-group">
                                                    <input type="text" name="contact[name]" className="form-control form-control--sm" placeholder="Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="contact[email]" className="form-control form-control--sm" placeholder="Email" required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="contact[phone]" className="form-control form-control--sm" placeholder="Phone Number" />
                                                </div>
                                                <div className="form-group">
                                                    <textarea className="form-control textarea--height-170" name="contact[body]" placeholder="Message" required defaultValue={"Hi! I need next info about the \"Oversize Cotton Dress\":"} />
                                                </div>
                                                <button className="btn" type="submit">Ask our consultant</button>
                                                <p className="p--small mt-15 mb-0">and we will contact you soon</p><input name="recaptcha-v3-token" type="hidden" defaultValue="03AGdBq27T8WvzvZu79QsHn8lp5GhjNX-w3wkcpVJgCH15Ehh0tu8c9wTKj4aNXyU0OLM949jTA4cDxfznP9myOBw9m-wggkfcp1Cv_vhsi-TQ9E_EbeLl33dqRhp2sa5tKBOtDspTgwoEDODTHAz3nuvG28jE7foIFoqGWiCqdQo5iEphqtGTvY1G7XgWPAkNPnD0B9V221SYth9vMazf1mkYX3YHAj_g_6qhikdQDsgF2Sa2wOcoLKWiTBMF6L0wxdwhIoGFz3k3VptYem75sxPM4lpS8Y_UAxfvF06fywFATA0nNH0IRnd5eEPnnhJuYc5LYsV6Djg7_S4wLBmOzYnahC-S60MHvQFf-scQqqhPWOtgEKPihUYiGFBJYRn2p1bZwIIhozAgveOtTNQQi7FGqmlbKkRWCA" /></form>
                                        </div>
                                    </div>
                                    {/* <div className="prd-block_info_item">
                                        <img className="img-responsive lazyload d-none d-sm-block" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="/images/payment/safecheckout.png" alt = "" />
                                        <img className="img-responsive lazyload d-sm-none" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="/images/payment/safecheckout-m.png" alt = "" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="holder prd-block_links-wrap-bg d-none d-md-block">
                    <div className="prd-block_links-wrap prd-block_info_item container mt-2 mt-md-5 py-1">
                        <div className="prd-block_link"><span><i className="icon-call-center" />24/7 Support</span></div>
                         <div className="prd-block_link">
                            <span>Use promocode  FOXIC to get 15% discount!</span>
                        </div> 
                        <div className="prd-block_link"><span><i className="icon-delivery-truck" /> Fast Shipping</span></div>
                    </div>
                </div> */}
                <div className="holder mt-3 mt-md-5">
                    <div className="container">
                        <ul className="nav nav-tabs product-tab">
                            <li className="nav-item"><a href="#TabSpecification" className="nav-link active" data-toggle="tab">Specification
                                <span className="toggle-arrow"><span /><span /></span>
                            </a></li>
                            <li className="nav-item"><a href="#Tab1" className="nav-link" data-toggle="tab">Description
                                <span className="toggle-arrow"><span /><span /></span>
                            </a></li>
                            <li className="nav-item"><a href="#Tab2" className="nav-link" data-toggle="tab">Sizing Guide
                                <span className="toggle-arrow"><span /><span /></span>
                            </a></li>
                            <li className="nav-item"><a href="#TabReurnAndWarranty" className="nav-link" data-toggle="tab">Return &amp; Warrant
                                <span className="toggle-arrow"><span /><span /></span>
                            </a></li>
                            <li className="nav-item"><a href="#TabMarketplacePolicy" className="nav-link" data-toggle="tab">Marketplace Policy
                                <span className="toggle-arrow"><span /><span /></span>
                            </a></li>
                        </ul>
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade show active" id="TabSpecification">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th style={{ width: "30%" }}>Item Code</th>
                                            <td style={{ width: "70%" }} className="border border-light"> {this.state.sku} </td>
                                        </tr>
                                        <tr>
                                            <th>Product Type</th>
                                            <td>{this.state.category_name || " - "}</td>
                                        </tr>
                                        <tr>
                                            <th>Product Name</th>
                                            <td>{this.state.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Main Color</th>
                                            <td>{this.state.color?.length > 0 ? this.state.color.join(" , ") : " - "}</td>
                                        </tr>
                                        <tr>
                                            <th>Main Material</th>
                                            <td>{" - "}</td>
                                        </tr>
                                        <tr>
                                            <th>Packaging Size</th>
                                            <td>{`${this.state.length_in || ""} * ${this.state.width_in || ""} * ${this.state.height_in || ""} ${(this.state.length_in || this.state.width_in || this.state.height_in) ? "inches" : ""} ${this.state.weight_lbs || ""} ${this.state.weight_lbs ? "lbs" : ""}`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div role="tabpanel" className="tab-pane fade show " id="Tab1">
                                <p id="" dangerouslySetInnerHTML={{ __html: this.state.description }}></p>
                            </div>

                            <div role="tabpanel" className="tab-pane fade" id="Tab2">
                                {/* <h3>Single Size Conversion</h3> */}
                                {/* <img
                                    src={`${this.state.app_site_storage_path}${this.state.sizing_guide}`}
                                    alt="image for sizing guide"
                                ></img> */}
                                <div className="sizing-guide-image"
                                // style={{ backgroundImage: `url(${this.state.app_site_storage_path}${this.state.sizing_guide})` }}
                                >
                                    <img
                                        // src="/images/skins/electronics/products/product-07-1.jpg"
                                        src={`${this.state.app_site_storage_path}${this.state.sizing_guide}`}
                                        style={{
                                            width: "100%",
                                            objectFit: "contain"
                                        }}
                                        alt="different sizes guide"
                                        className="js-prd-img" />
                                </div>
                                {/* <table className="table table-striped">
                                
                                    </tbody></table>
                             */}
                            </div>

                            <div role="tabpanel" className="tab-pane fade show" id="TabReurnAndWarranty">
                                {
                                    <div dangerouslySetInnerHTML={{ __html: this.state.warrants }}></div>
                                }
                            </div>
                            <div role="tabpanel" className="tab-pane fade show " id="TabMarketplacePolicy">
                                {
                                    <div dangerouslySetInnerHTML={{ __html: this.state.marketplace_policies }}></div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className="holder">
                    <div className="container">
                        <div className="title-wrap text-center">
                            <h2 className="h1-style">You may also like</h2>
                            <div className="carousel-arrows carousel-arrows--center" />
                        </div>
                        <ProductsRecommended
                            toggleQuickViewModal={this.toggleQuickViewModal}
                        />
                    </div>
                </div>
                {
                    this.state.show_quickview_modal
                    &&
                    <QuickViewModal
                        product_id={this.state.quick_product_id}
                        toggleQuickViewModal={this.toggleQuickViewModal}
                        product_data={this.state.quick_view_product}

                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist,
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(Product));


// export default withRouter(Product)