import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import axiosConfig from '../../axiosConfig';
import Spinner from '../../Spinner';
import { handleError } from '../../helper_funcitons';

import { scrollToTop, inputValidation } from '../../helper_funcitons';
import InputValidationErrorMessage from '../../inputValidationErrorMessage';

import AlertMessage from '../../AlertMessage';
import $ from 'jquery'
class AccountDetails extends Component {
    constructor(props) {
        super(props);

        var appState = JSON.parse(localStorage.getItem('appState'))

        this.state = {
            isLoggedIn: appState.isLoggedIn,
            validation_errors: [],
            alert_message: "",
            alert_status: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            password: "",
            confirm_password: "",
            show_spinner: true,
            address_mode: "",
            response_got_once: false,
        }

        this.onChange = this.onChange.bind(this)
        this.getAccountDetails = this.getAccountDetails.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.resetForm = this.resetForm.bind(this)
        this.changeEditAddress = this.changeEditAddress.bind(this)
        this.toLogin = this.toLogin.bind(this)
    }

    toLogin() {
        this.setState({
            isLoggedIn: false,
        })
    }


    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        })

        if (e.target.value.length > 0) {
            e.target.parentElement.classList.remove("has-error");

            // remove if exists
            // $("#"+e.target.name).next('.invalid-feedback').remove();


        }
        else {

            e.target.name !== "phone" && e.target.parentElement.classList.add("has-error");
        }
    }

    async getAccountDetails() {
        this.setState({
            show_spinner: true,
        })
        await axiosConfig.get('/api/account-details')
            .then(res => {
                this.setState({
                    show_spinner: false
                })
                if (res) {
                    let { first_name, last_name, email, phone } = res.data.data
                    this.setState({
                        first_name: first_name,
                        last_name: last_name,
                        email: email,
                        phone: phone,
                        old_first_name: first_name,
                        old_last_name: last_name,
                        old_email: email,
                        old_phone: phone
                    })
                }
            })
            .catch(err => {
                handleError(err,this.toLogin);
            })
    }

    resetForm() {
        this.setState(
            {
                first_name: this.state.old_first_name,
                last_name: this.state.old_last_name,
                email: this.state.old_email,
                phone: this.state.old_phone,
                password: "",
                confirm_password: "",
            }
        )
    }

    componentDidMount() {
        scrollToTop(0, 0)
        this.getAccountDetails();
    }

    changeEditAddress() {
        // let target = this.state.addresses.find(el => el.id == address_id);

        this.setState({
            // country_id: target.country_id,
            // state_id: target.state_id,
            // email: target.email,
            // password: target.password,
            // is_default: target.as_default == "Yes" ? true : false,
            address_mode: "edit_address",
            // edit_address_id: address_id,
        }, () => {
            // this.getStates(this.state.country_id);
        })
    }

    async handleSubmit(e) {
        e.preventDefault();

        let validation_fields = ["first_name", "last_name", "email"];

        let arr = []
        validation_fields.forEach(el => {
            let obj = {
                field: el,
                state: this.state[el],
                target: e.target[el],
            }
            arr.push(obj)
        })
        let validation_errors = inputValidation(arr);

        this.setState({
            validation_errors: validation_errors
        })

        if (validation_errors.length === 0) {

            if (this.state.response_got_once) {
                $(".invalid-feedback").remove();
            }

            let data = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                phone: this.state.phone,
                password: this.state.password,
                confirm_password: this.state.confirm_password,

            }
            let url = "/api/users/update";

            this.setState({
                show_spinner: true,
            })
            axiosConfig.put(url, data)
                .then(async (res) => {
                    this.setState({
                        show_spinner: false,
                        response_got_once: true,
                    })

                    if (res.data.success) {
                        await this.getAccountDetails()
                        this.resetForm();
                        this.setState({
                            alert_message: res.data.data,
                            alert_status: "success"
                        })
                        scrollToTop();
                    } else {
                        var messages = JSON.parse(res.data.data);

                        let count = 0;
                        if (messages instanceof Object) {
                            var _message = '';

                            for (const [key, value] of Object.entries(messages)) {
                                _message += `${value}`;
                                count++;
                                $("#" + key).closest('.form-group').addClass('has-error');
                                $("#" + key).after(`<p class="invalid-feedback text-left"> ${value} </p>`);

                            }
                            if (count <= 1) {
                                this.setState({
                                    alert_status: "danger",
                                    alert_message: _message,
                                })
                            }
                        }
                        else {
                            this.setState({
                                alert_status: "error",
                                alert_message: res.data.data,
                            })
                        }
                    }
                })
                .catch(err => {

                    this.setState({
                        show_spinner: false,
                    })

                    handleError(err,this.toLogin)

                })
        }
    }

    render() {

        axiosConfig.defaults.headers.common = {
            'Authorization': 'Bearer ' + (localStorage.userToken || "")
        };

        if (!this.state.isLoggedIn) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }}/>
        }

        return (
            <div className="col-18 aside">
                <h1 className="mb-3">Account Details</h1>
                {
                    (this.state.alert_status !== '' && this.state.alert_message !== '')
                    &&
                    <AlertMessage
                        status={this.state.alert_status}
                        messages={this.state.alert_message}
                    />
                }
                {
                    this.state.show_spinner ?
                        <Spinner
                        // type={ClockLoader}
                        />
                        :
                        <>
                            <div className="row vert-margin">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3>Personal Info</h3>
                                            <p>
                                                <b>First Name:</b> {this.state.old_first_name}<br />
                                                <b>Last Name:</b> {this.state.old_last_name}<br />
                                                <b>E-mail:</b> {this.state.old_email}<br />
                                                <b>Phone:</b> {this.state.old_phone}
                                            </p>
                                            <div className="mt-2 clearfix">
                                                {/* <a className="link-icn js-show-form" data-form="#updateDetails"><i className="icon-pencil" />Edit</a> */}

                                                <button
                                                    type="button"
                                                    className="link-icn js-show-form border-0 bg-transparent"
                                                    onClick={

                                                        () => this.changeEditAddress()
                                                    }
                                                >
                                                    <i className="icon-pencil" />Edit
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.address_mode === "edit_address"
                                &&
                                <div className="card mt-3 d-nonee"
                                >
                                    <form onSubmit={this.handleSubmit} onReset={this.resetForm}>
                                        <div className="card-body">
                                            <h3>Edit Address</h3>

                                            {/* <label className="text-uppercase input_star">Country:</label>
                                            <div className="form-group select-wrapper">
                                                <div className="validate_react_select country_id">
                                                    <Select
                                                        options={country_options}
                                                        value={country_options_value}
                                                        name="country_id"
                                                        onChange={this.onChangeReactSelect}
                                                    />
                                                    <InputValidationErrorMessage
                                                        validation_errors={this.state.validation_errors}
                                                        input_field={this.state.country_id}
                                                        error_field="country_id"
                                                    />
                                                </div>
                                            </div> */}
                                            {/* <label className="text-uppercase input_star">State:</label>
                                            <div className="form-group select-wrapper">
                                                <div className="validate_react_select state_id">
                                                    <Select
                                                        options={state_options}
                                                        value={state_options_value}
                                                        name="state_id"
                                                        onChange={this.onChangeReactSelect}
                                                    />
                                                    <InputValidationErrorMessage
                                                        validation_errors={this.state.validation_errors}
                                                        input_field={this.state.state_id}
                                                        error_field="state_id"
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <label className="text-uppercase">First Name : </label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="first_name"
                                                            value={this.state.first_name}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.first_name}
                                                            error_field="first_name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className="text-uppercase">Last Name : </label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="last_name"
                                                            value={this.state.last_name}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.last_name}
                                                            error_field="last_name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className="text-uppercase">Phone : </label>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            value={this.state.phone}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.phone}
                                                            error_field="phone"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="row"> */}
                                                <div className="col-sm-9">
                                                    <label className="text-uppercase">Email:</label>
                                                    <div className="form-group">
                                                        <input
                                                            id="email"
                                                            type="email"
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={this.onChange}
                                                            className="form-control"
                                                        />
                                                        <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.email}
                                                            error_field="email"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="form-group col-18 mt-2">
                                                    <div className="alert alert-info alert-dismissible" role="alert">
                                                        Leave the password field empty if you don't want to change.
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className="text-uppercase">Password : </label>
                                                    <div className="form-group">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            value={this.state.password}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        {/* <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.password}
                                                            error_field="password"
                                                        /> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <label className="text-uppercase">Confirm Password : </label>
                                                    <div className="form-group">
                                                        <input
                                                            type="password"
                                                            id="confirm_password"
                                                            name="confirm_password"
                                                            value={this.state.confirm_password}
                                                            onChange={this.onChange}
                                                            className="form-control" />
                                                        {/* <InputValidationErrorMessage
                                                            validation_errors={this.state.validation_errors}
                                                            input_field={this.state.confirm_password}
                                                            error_field="confirm_password"
                                                        /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="clearfix">
                                                <input
                                                    id="formCheckbox1"
                                                    name="is_default"
                                                    value={this.state.is_default}
                                                    onChange={this.onChange}
                                                    type="checkbox"
                                                    checked={this.state.is_default ? true : false}
                                                />
                                                <label htmlFor="formCheckbox1">Set address as default</label>
                                            </div> */}
                                            <div className="col-18 mt-2 row">
                                                <button type="reset" className="btn btn--alt js-close-form">Cancel</button>
                                                <button type="submit" className="btn ml-1">Edit Address</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </>
                }
            </div>
        )
    }
}

export default AccountDetails;
