import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
// import $ from 'jquery';
import { connect } from 'react-redux'
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist } from '../actions'
import WishListButton from './WishListButton'
import AddToCartButton from './AddToCartButton'
import QuickViewButton from './QuickViewButton'
import { getMainImage, getParsedDiscription } from '../helper_funcitons'

class ProductVertical extends Component {

    constructor(props) {
        super(props)

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.state = {
            isLoggedIn: appState ? appState.isLoggedIn : false,
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,
            redirect_to_login: false,
        }
        this.addToCart = this.addToCart.bind(this)
        // this.recommendImageClick =  this.recommendImageClick.bind(this)
        // this.resetJqueries = this.resetJqueries.bind(this);
    }

    addToCart(el) {

        if (this.state.isLoggedIn) {
            let obj = {
                id: el.id,
                name: el.name,
                price: el.price,
                quantity: 1,
                image: getMainImage(el.image)
            }
            this.props.incrementCart(obj);
        } else {
            this.setState({
                redirect_to_login: true,
            })
        }
    }

    recommendImageClick() {
        // alert("ok")
        // $(".recommended-products a.image-container").on('click', function () {
        // if ($('.js-product-main-carousel').hasClass('slick-initialized')) {
        //     $('.js-product-main-carousel').slick('unslick');
        // }
        // else {
        // }

        // })
        // this.resetJqueries();


    }

    resetJqueries() {
        let arr = [
            // "js/vendor-special/lazysizes.min.js",
            // "js/vendor-special/ls.bgset.min.js",
            // "js/vendor-special/ls.aspectratio.min.js",
            // "js/vendor-special/jquery.min.js",
            // "js/vendor-special/jquery.ez-plus.js",
            // "js/vendor-special/instafeed.min.js",
            // "js/vendor/vendor.min.js",
            "/js/app-html.js"
        ]

        // if(this.state.featured_products.length == 0){
        //     arr.push("add_pfeaurd_prod")
        // }

        arr.forEach(el => {
            if (document.getElementById(el)) {
                document.getElementById(el).remove()
            }
            var script = document.createElement('script');
            script.id = el
            script.src = el
            script.defer = true
            // script.async = true
            // document.getElementsByTagName('head')[0].appendChild(script);

            script.onload = function () {
                // window.MapComponent.resetPartnersSlider();
            }
        })




    }

    componentDidMount() {
        // $(".recommended-products a.prd-img.image-container").on( 'click', function() {
        //     alert( 'okay' )
        //     if( $('.js-product-main-carousel').hasClass( 'slick-initialized' ) ) {
        //         $('.js-product-main-carousel').slick('unslick');
        //      }
        //      else {
        //         $('.js-product-main-carousel').slick();

        //      }
        // } );
    }


    render() {

        if (this.state.redirect_to_login) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: (`${window.location.pathname}${window.location.search}`) }
            }} />
        }
        let { id, name, price, short_description, company_id, image: res_image, company_name } = this.props.product_data
        short_description = getParsedDiscription(short_description);

        let image = getMainImage(res_image);

        return <div className="recommended-products prd prd--style2 prd-labels--max prd-labels-shadow prd-w-xl" key={id}>
            <div className="prd-inside">
                <div className="prd-img-area">
                    {/* <a href="product.html" className="prd-img image-hover-scale image-container"> */}
                    <Link to={`/product?id=${id}`} className="prd-img image-hover-scale image-container" onClick={this.recommendImageClick}>
                        {/* <img
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-src={`${this.state.app_site_url}${this.state.app_site_storage_path}${image}`}
                        alt={name}
                        className="js-prd-img lazyload fade-up" /> */}
                        <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${image}')` }}>
                            <img src="/images/skins/electronics/products/product-07-1.jpg"
                                // <img src={`${this.state.app_site_url}${this.state.app_site_storage_path}${image})`}
                                alt={name}
                                className="js-prd-img" />
                        </div>

                        <div className="foxic-loader" />
                    </Link>

                    {/* </a> */}
                    <div className="prd-circle-labels">
                        <WishListButton
                            product_data={this.props.product_data}
                        />
                        {/* <QuickViewButton
                            product_id={this.props.product_data.id}
                            toggleQuickViewModal={this.props.toggleQuickViewModal}
                        /> */}
                        <QuickViewButton
                                product_data = {this.props.product_data}
                                product_id={this.props.product_data.id}
                                toggleQuickViewModal={this.props.toggleQuickViewModal}
                            />
                    </div>
                    {/* <ul className="list-options color-swatch">
                    <li data-image={`${this.state.app_site_url}${this.state.app_site_storage_path}${image}`}
                        className="active"><a href="#" className="js-color-toggle" data-toggle="tooltip"
                            data-placement="right" title="Color Name">
                            <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={`${this.state.app_site_url}${this.state.app_site_storage_path}${image}`} className="lazyload fade-up" alt="Color Name" /></a></li>

                    <li
                        data-image={`${this.state.app_site_url}${this.state.app_site_storage_path}${image}`}
                    ><a href="#" className="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-src={`${this.state.app_site_url}${this.state.app_site_storage_path}${image}`}
                        className="lazyload fade-up" alt="Color Name" /></a></li>
                </ul> */}
                </div>
                <div className="prd-info">
                    <div className="prd-info-wrap">
                        <div className="prd-info-top">
                            {/* <div className="prd-rating"><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" />
                            </div> */}
                        </div>
                        {/* <div className="prd-rating justify-content-center"><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /><i className="icon-star-fill fill" /></div> */}
                        <div className="prd-tag"><Link to={`/store/${company_id}`}>
                            {company_name}
                        </Link></div>

                        <h2 className="prd-title">
                            <Link to={`/product?id=${id}`}>
                                {name}
                            </Link>
                        </h2>
                        <div className="prd-description">
                            <p id="" dangerouslySetInnerHTML={{ __html: short_description }}>
                            </p>
                        </div>
                        <div className="prd-action">
                            {/* <form action="#"> */}
                            {/* <button className="btn js-prd-addtocart"
                                type="button"
                                data-product="{&quot;name&quot;: &quot;Window Air Conditioner&quot;, &quot;path&quot;:&quot;images/skins/electronics/products/product-07-1.jpg&quot;, &quot;url&quot;:&quot;product.html&quot;, &quot;aspect_ratio&quot;:0.778}"
                                onClick={() => this.addToCart(this.props.product_data)}
                            >
                                Add To Cart
                            </button> */}
                            <AddToCartButton
                                product_data={this.props.product_data}
                                name={name}
                                image={image}
                                id={id}
                            />
                            {/* </form> */}
                        </div>
                    </div>
                    <div className="prd-hovers">
                        {/* <div className="prd-circle-labels">
                            <div>
                                <a href="#" className="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0" title="Add To Wishlist"
                                    onClick={() => this.addToWishlist(this.props.product_data)}><i className="icon-heart-stroke" /></a><a href="#" className="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0" title="Remove From Wishlist"
                                        onClick={() => this.removeFromWishlist(id)}><i className="icon-heart-hover" /></a>
                            </div>
                            <div className="prd-hide-mobile"><a href="#" className="circle-label-qview js-prd-quickview" data-src="ajax/ajax-quickview.html"><i className="icon-eye" /><span>QUICK VIEW</span></a></div>
                        </div> */}
                        <div className="prd-circle-labels">
                            <WishListButton
                                product_data={this.props.product_data}
                            />
                            {/* <a href="#" className="circle-label-qview js-prd-quickview prd-hide-mobile">
                                <i className="icon-eye" /><span>QUICK VIEW
                                </span>
                            </a> */}
                            <QuickViewButton
                                product_data = {this.props.product_data}
                                product_id={this.props.product_data.id}
                                toggleQuickViewModal={this.props.toggleQuickViewModal}
                            />
                        </div>

                        <div className="prd-price">
                            {/* <div className="price-old">${price + 20}</div> */}
                            <div className="price-new">${this.state.isLoggedIn ? price : "**"}</div>
                        </div>
                        <div className="prd-action">
                            <div className="prd-action-left">
                                {/* <form action="#"> */}
                                <button
                                    className="btn js-prd-addtocart"
                                    type="button"
                                    // data-product="{&quot;name&quot;: &quot; &quot;, &quot;path&quot;:&quot;images/skins/electronics/products/product-07-1.jpg&quot;, &quot;url&quot;:&quot;product.html&quot;, &quot;aspect_ratio&quot;:0.778}"
                                    data-product={this.state.isLoggedIn ? `{"name": "${name ? name.replaceAll('"', " ").replaceAll(","," ") : ""}", "path":"${this.state.app_site_storage_path}${image}", "url":"/products?id=${id}", "aspect_ratio":0.778}` : undefined}

                                    onClick={() => this.addToCart(this.props.product_data)}
                                >
                                    {/* Add To Cart */}
                                    Add To Cart

                                </button>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <QuickViewModal /> */}
        </div>

    }
}




const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist,
    };
};

// const ProductVerticalWithRouter = withRouter(ProductVertical)

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(ProductVertical);
