import React, { Component } from 'react'

class AlertMessage extends Component {

    render() {
        return (
            // <div className="col-md-12">
            <div className={`alert alert-${this.props.status} alert-dismissible fade show mb-1 d-flex`} role="alert">
                <p className="mb-0">
                    {this.props.messages}
                </p>
                {/* todo : onclick button this.props.resetAlert() */}
                <button type="button" className="close btn m-1 d-flex d-flex justify-content-end " data-dismiss="alert" aria-label="Close"
                    style={{
                        backgroundColor: "transparent",
                        fontSize: "2rem",
                    }}
                >
                    <span aria-hidden="true">×</span>
                </button>

            </div >
            // </div>
        )
    }
}

export default AlertMessage;