import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

import ProductHorizontal from '../ProductHorizontal';

// redux 
import axiosConfig from '../../axiosConfig';
import { incrementCart, decrementCart, addToWishlist, removeFromWishlist } from '../../actions'
import ProductVertical from '../ProductVertical';
import QuickViewModal from '../../QuickViewModal';
import { scrollToTop } from '../../helper_funcitons';
class Home extends React.Component {

    constructor(props) {
        super(props);

        window.MapComponent = this

        let appState = localStorage.getItem('appState')
        if (appState) {
            appState = JSON.parse(localStorage.getItem('appState'))
        }

        this.state = {
            isLoggedIn: appState ? appState.isLoggedIn : false,
            app_site_url: process.env.REACT_APP_APP_SITE,
            app_site_storage_path: process.env.REACT_APP_APP_SITE_STORAGE_PATH,
            product_categories: [],
            // old_hero_banners: [
            //     {
            //         "image": `${this.state.app_site_storage_path}/slide-electronics-01.png`,
            //         "alignment": "txt-left",
            //         "button_color": "#FFA500",
            //         "tables": [
            //             {
            //                 "content": {
            //                     "text": "PRICE $1000",
            //                 },
            //                 "design": {
            //                     "animation": "fadeInUp",
            //                     "animation_delay": "800",
            //                     "font_weight": "600",
            //                     "font_color": "#363b4b"
            //                 }
            //             },
            //             {
            //                 "content": {
            //                     "text": "EARPHONE"
            //                 },
            //                 "design": {
            //                     "animation": "fadeInUp",
            //                     "animation_delay": "800",
            //                     "font_weight": "600",
            //                     "font_color": "#363b4b"
            //                 }
            //             }
            //         ]
            //     },
            //     {
            //         "image": `${this.state.app_site_storage_path}/slide-electronics-02.png`,
            //         "alignment": "txt-right",
            //         "button_color": "#FF0000",
            //         "tables": [
            //             {
            //                 "content": {
            //                     "text": "PRICE $9999",

            //                 },
            //                 "design": {
            //                     "animation": "fadeInUp",
            //                     "animation_delay": "800",
            //                     "font_weight": "600",
            //                     "font_color": "#FFFFFF"
            //                 }
            //             },
            //             {
            //                 "content": {
            //                     "text": "TVVVVV"
            //                 },
            //                 "design": {
            //                     "animation": "fadeInUp",
            //                     "animation_delay": "800",
            //                     "font_weight": "500",
            //                     "font_color": "#FFFFFF"
            //                 }
            //             }
            //         ]
            //     }
            // ],
            hero_banners: [],
            new_arivals: [],
            our_partners: [],
            featured_products: [],
            featured_categories: [],
            merchant_shippings: [],
            storefronts: [],

            // modal
            show_quickview_modal: false,
            product_id: "",
        }

        this.addToCart = this.addToCart.bind(this)
        this.getHomePageContents = this.getHomePageContents.bind(this)
        this.toggleQuickViewModal = this.toggleQuickViewModal.bind(this)
    }

    addToCart(data) {
        let obj = {
            id: data,
            name: "oled TV" + data,
            price: (100 * data),
            quantity: 1,
        }
        this.props.incrementCart(obj);
    }

    resetJqueries() {
        let arr = [
            // "js/vendor-special/lazysizes.min.js",
            // "js/vendor-special/ls.bgset.min.js",
            // "js/vendor-special/ls.aspectratio.min.js",
            // "js/vendor-special/jquery.min.js",
            // "js/vendor-special/jquery.ez-plus.js",
            // "js/vendor-special/instafeed.min.js",
            // "js/vendor/vendor.min.js",
            "/js/app-html.js"
        ]

        // if(this.state.featured_products.length == 0){
        //     arr.push("add_pfeaurd_prod")
        // }

        window.popup_checkout_called = true;

        arr.forEach(el => {
            if (document.getElementById(el)) {
                document.getElementById(el).remove()
            }
            var script = document.createElement('script');
            script.id = el
            script.src = el
            script.defer = true
            // script.async = true
            document.getElementsByTagName('head')[0].appendChild(script);

        })

    }

    resetPartnersSlider() {
        if (document.getElementById("partner_slider")) {
            document.getElementById("partner_slider").remove()
        }
        var partner_slider_script = document.createElement('script');
        partner_slider_script.id = "partner_slider"
        // script.src = el
        // script.defer = true
        // script.async = true
        partner_slider_script.text = `
            swipemode = true;
            maxXS = 480;
            maxSM = 768;
            maxMD = 992;
            maxLG = 1200;

            jQuery(document).ready(function() {
                if (jQuery('.js-brand-carousel').length) {
                    jQuery('.js-brand-carousel').each(function () {
                        var $this = jQuery(this);
                        if ($this.hasClass('slick-initialized')) return false;
                        var arrowsplace = $this.parent().find('.carousel-arrows').length ? $this.parent().find('.carousel-arrows') : $this,
                            slidesToShow_lg = 5,
                            slidesToScroll_lg = 1,
                            slidesToShow_md = 4,
                            slidesToScroll_md = 1,
                            slickTimer = void 0;
                        if ($this.closest('.aside ').length) {
                            slidesToShow_lg = 4;
                            slidesToScroll_lg = 1;
                            slidesToShow_md = 3;
                            slidesToScroll_md = 1;
                        }
                        $this.slick({
                            arrows: false,
                            dots: false,
                            slidesToShow: slidesToShow_lg,
                            slidesToScroll: slidesToScroll_lg,
                            appendArrows: arrowsplace,
                            swipe: swipemode,
                            infinite: true,
                            draggable: false,
                            speed: 750,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                            //waitForAnimate: true,
                            responsive: [{
                                breakpoint: maxMD,
                                settings: {
                                    speed: 1000,
                                    slidesToShow: slidesToShow_md,
                                    slidesToScroll: slidesToScroll_md
                                }
                            }, {
                                breakpoint: maxXS,
                                settings: {
                                    speed: 500,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            }]
                        });
                    });
                }
            })
        `

        document.getElementsByTagName('body')[0].appendChild(partner_slider_script);

    }

    async getHomePageContents() {
        await axiosConfig.get("/api/merchant-page-contents")
            .then(res => {
                if (res.data.success) {
                    let {
                        product_categories,
                        hero_banners,
                        new_arivals,
                        our_partners,
                        featured_products,
                        featured_categories,
                        shippings: merchant_shippings,
                        storefronts,
                    } = res.data.data;

                    this.setState({
                        product_categories,
                        hero_banners,
                        new_arivals,
                        our_partners,
                        featured_products,
                        featured_categories,
                        merchant_shippings,
                        storefronts
                    }, () => {

                        // for product_image
                        this.resetJqueries()

                        // for partners slider
                        this.resetPartnersSlider();
                    })
                }
            })
            .catch(err => {

            })
    }

    componentDidUpdate() {

    }

    async componentDidMount() {

        scrollToTop(0, 0)
        // this.resetPartnersSlider();
        await this.getHomePageContents()

        // this.setState()

        // setTimeout(() => {
        //     this.setState({
        //         hero_banners: [1, 2, 3]
        //     })
        // }, 10000)

        // let arr = [
        //     "js/vendor-special/lazysizes.min.js",
        //     "js/vendor-special/ls.bgset.min.js",
        //     "js/vendor-special/ls.aspectratio.min.js",
        //     "js/vendor-special/jquery.min.js",
        //     "js/vendor-special/jquery.ez-plus.js",
        //     "js/vendor-special/instafeed.min.js",
        //     "js/vendor/vendor.min.js",
        //     "js/app-html.js"
        // ]
        // arr.forEach(el => {
        //     loadjs(el);
        // })

    }

    toggleQuickViewModal(id, quick_view_product = {}) {
        this.setState({
            product_id: id,
            quick_view_product

        }, () => {
            this.setState((prev) => ({
                show_quickview_modal: !prev.show_quickview_modal
            }))
        })
    }

    render() {

        let product_categories_mapping = this.state.featured_categories.map(el => {
            return <div className="collection-grid-2-item col-9 col-md-quarter col-lg-3" key={el.id}>
                {/* <a href="collections.html" target="_self" className="bnr-wrap collection-grid-2-item-inside"> */}
                <Link
                    to={`/product-listing?category_id=${el.id}`}
                    className="bnr-wrap collection-grid-2-item-inside"
                >
                    <div className="collection-grid-2-img image-container image-hover-scale"
                        style={{ paddingBottom: '77.778%' }}>
                        {/* <img className="lazyload fade-up"
                            data-src="/images/skins/electronics/category-img-01.png"
                            data-sizes="auto" alt={el.name}
                        /> */}
                        <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${el.file_name}')` }}>
                            <img src="/images/skins/electronics/category-img-01.png"
                                alt={el.name}
                                className="js-prd-imggg fade-up" />
                        </div>
                    </div>
                    <h3 className="collection-grid-2-title pb-15 heading-font">{el.name}</h3>

                </Link>
                {/* </a> */}
            </div>
        })


        let hero_banners_mapping = this.state.hero_banners.map((el, index) => {
            return <a href="#" target="_self" className="bnslider-slide" key={index}>
                <div className="bnslider-image-mobile lazyload fade-up-fast" data-bgset={`${this.state.app_site_storage_path}${el.image}`} data-sizes="auto" />
                <div className="bnslider-image lazyload fade-up-fast" data-bgset={`${this.state.app_site_storage_path}${el.image}`} data-sizes="auto" />
                {/* <div className="bnslider-text-wrap bnslider-overlay container"> */}
                {/* <div className={`bnslider-text-content txt-middle ${el.alignment} txt-middle-m txt-center-m`}>
                        <div className="bnslider-text-content-flex  container ">
                            <div className="bnslider-vert w-s-60 w-ms-80 p-0"> */}
                {/* {
                                    el.tables && el.tables.map((el, index_) => {
                                        return <div className={`bnslider-text order-${el.design?.order} mt-sm bnslider-text--${el.design?.size} text-center`}
                                            data-animation={el.design.animation}
                                            data-animation-delay={el.design.animation_delay}
                                            data-fontcolor={el.design.font_color}
                                            data-bgcolor
                                            data-fontweight={el.design.font_weight}
                                            data-fontline={1.00} data-otherstyle
                                            key={index_}
                                        >
                                            {el.content.text}
                                        </div>
                                    })
                                } */}
                {/* <div className="bnslider-text order-3 mt-sm bnslider-text--sm text-center" data-animation="zoomIn"
                                 data-animation-delay={1000}
                                  data-fontcolor="#ffffff"
                                   data-bgcolor data-fontweight={500}
                                    data-fontline="1.5"
                                     data-otherstyle>Enjoy with superb portability
                                     </div> */}

                {/* <div className="bnslider-text order-1 mt-0 bnslider-text--md text-center" data-animation="fadeInDown" data-animation-delay={1600} data-fontcolor="#5378f4" data-bgcolor data-fontweight={600} data-fontline={1.00} data-otherstyle>$799</div> */}

                {/* <div className="btn-wrap text-center order-4 mt-md" data-animation="fadeInUp" data-animation-delay={2000}>
                                    <div className="btn btn--lg" style={{
                                        backgroundColor: el.button_color
                                    }}>Shop now</div>
                                </div> */}
                {/* </div>
                        </div>
                    </div> */}
                {/* </div> */}
            </a>
        })

        let new_arrivals_mapping = this.state.new_arivals.map((el, index) => {
            return <ProductHorizontal
                key={el.id}
                product_data={el}
                toggleQuickViewModal={this.toggleQuickViewModal}
            />
        })

        let featured_products_mapping = this.state.featured_products.map(el => {
            return <ProductVertical
                key={el.id}
                product_data={el}
                toggleQuickViewModal={this.toggleQuickViewModal}
            />
        })

        let our_partners_mapping = this.state.our_partners.map((el, index) => {
            return <li key={index}>
                <div className="partner-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${el.image}')` }}
                // style={{
                //     border: "2px solid"
                // }}
                >
                    <img src="/images/skins/electronics/brand-02.png"
                        alt={el.name}
                        className="js-prd-img" />
                </div>

                {/* <div>
                    <img className="lazyload lazypreload" data-src={`${this.state.app_site_url}${el.image}`} data-sizes="auto" alt={el.image} />
                </div> */}
            </li>
        })

        let merchant_shippings_mapping = this.state.merchant_shippings.map((el, index) => {
            return <div className="col-sm" key={index}>
                <div className="shop-feature-bg row  ">
                    <div className="shop-feature-icon col-auto"><i className={el.image} /></div>
                    <div className="shop-feature-text col">
                        <div className="shop-feature-title heading-font">{el.title}</div>
                        {el.description}
                    </div>
                </div>
            </div>
        })


        let storefronts_mapping = this.state.storefronts.map(el => {
            return <div className="collection-grid-2-item col-9 col-md-quarter col-lg-3" key={el.id}>
                <Link
                    to={`/product-listing?store_id=${el.company_id}`}
                    className="bnr-wrap collection-grid-2-item-inside"
                >
                    <div className="collection-grid-2-img image-container image-hover-scale"
                        style={{ paddingBottom: '77.778%' }}>
                        <div className="product-image" style={{ backgroundImage: `url('${this.state.app_site_storage_path}${el.logo}')` }}>
                            <img src="/images/skins/electronics/category-img-01.png"
                                alt={el.name}
                                className="js-prd-imggg fade-up" />
                        </div>
                    </div>
                    <h3 className="collection-grid-2-title pb-15 heading-font">{el.company_name}</h3>
                </Link>
            </div>
        })

        return (
            <div className="page-content">
                <div id="shopify-section-1586608150816" className="shopify-section index-section index-section--flush">
                    <div className="holder fullwidth fullboxed mt-0 full-nopad">
                        <div className="container">
                            <div className="bnslider-wrapper">
                                <div className="bnslider keep-scale" data-start-width={1920} data-start-height={785} data-start-mwidth={414} data-start-mheight={736} id="bnslider-1586608150816" data-autoplay="true" data-speed={5000}>
                                    {
                                        hero_banners_mapping
                                    }

                                    {/* <a href="#" target="_self" className="bnslider-slide ">
                                        <div className="bnslider-image-mobile lazyload fade-up-fast" data-bgset="/images/skins/electronics/slider/slide-electronics-01.webp" data-sizes="auto" />
                                        <div className="bnslider-image lazyload fade-up-fast" data-bgset="/images/skins/electronics/slider/slide-electronics-01.webp" data-sizes="auto" />
                                        <div className="bnslider-text-wrap bnslider-overlay container">
                                            <div className="bnslider-text-content txt-middle txt-left txt-middle-m txt-center-m">
                                                <div className="bnslider-text-content-flex  container ">
                                                    <div className="bnslider-vert w-s-60 w-ms-80 p-0">
                                                        <div className="bnslider-text order-2 mt-sm bnslider-text--xl text-center" data-animation="fadeInUp" data-animation-delay={800} data-fontcolor="#363b4b" data-bgcolor data-fontweight={600} data-fontline={1.00} data-otherstyle>EAR PHONES</div>
                                                        <div className="bnslider-text order-3 mt-sm bnslider-text--sm text-center" data-animation="zoomIn" data-animation-delay={1000} data-fontcolor="#ffffff" data-bgcolor data-fontweight={500} data-fontline="1.5" data-otherstyle>Enjoy with superb portability</div>
                                                        <div className="bnslider-text order-1 mt-0 bnslider-text--md text-center" data-animation="fadeInDown" data-animation-delay={1600} data-fontcolor="#5378f4" data-bgcolor data-fontweight={600} data-fontline={1.00} data-otherstyle>$799</div>
                                                        <div className="btn-wrap text-center  order-4 mt-md" data-animation="fadeInUp" data-animation-delay={2000}>
                                                            <div className="btn btn--lg">Shop now</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#" target="_self" className="bnslider-slide ">
                                        <div className="bnslider-image-mobile lazyload fade-up-fast" data-bgset="/images/skins/electronics/slider/slide-electronics-02.webp" data-sizes="auto" />
                                        <div className="bnslider-image lazyload fade-up-fast" data-bgset="/images/skins/electronics/slider/slide-electronics-02.webp" data-sizes="auto" />
                                        <div className="bnslider-text-wrap bnslider-overlay container">
                                            <div className="bnslider-text-content txt-middle txt-right txt-middle-m txt-center-m">
                                                <div className="bnslider-text-content-flex  container ">
                                                    <div className="bnslider-vert w-s-60 w-ms-80 p-0">
                                                        <div className="bnslider-text order-2 mt-sm bnslider-text--xxxl text-center" data-animation="fx4" data-animation-delay={800} data-fontcolor="#ffffff" data-bgcolor data-fontweight={700} data-fontline="1.1" data-otherstyle>QLED</div>
                                                        <div className="bnslider-text order-2 mt-0 bnslider-text--xl text-center" data-animation="fx2" data-animation-delay={1000} data-fontcolor="#ffffff" data-bgcolor data-fontweight={500} data-fontline="1.1" data-otherstyle>MONITORS</div>
                                                        <div className="bnslider-text order-3 mt-sm bnslider-text--sm text-center" data-animation="fadeInDown" data-animation-delay={1600} data-fontcolor="#ffffff" data-bgcolor data-fontweight={400} data-fontline="1.3" data-otherstyle>Special designed for games</div>
                                                        <div className="btn-wrap text-center  order-4 mt-md" data-animation="fadeIn" data-animation-delay={2000}>
                                                            <div className="btn btn--lg" data-color="#464b5c" data-bgcolor="#ffd800" data-hover-color="#ffffff" data-hover-bgcolor="#464b5c" style={{ color: '#464b5c', backgroundColor: '#ffd800' }}>Shop now</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                 */}
                                </div>
                                <div className="bnslider-loader" />
                                <div className="bnslider-arrows d-sm-none container"><div /></div>
                                <div className="bnslider-dots d-none d-sm-block container" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="holder holder-mt-small" id="holderCollectionGrid">
                    <div className="container">
                        <div className="collection-grid-2 row justify-content-center">
                            {
                                product_categories_mapping
                            }
                        </div>
                        <style dangerouslySetInnerHTML={{ __html: "\n\t\t\t#holderCollectionGrid .collection-grid-2-title {\n\t\t\t\tfont-size: 16px;\n\t\t\t\tfont-weight: 600;\n\t\t\t\tcolor: #464b5c\n\t\t\t}\n\t\t\t#holderCollectionGrid .collection-grid-2-title:hover {\n\t\t\t\tcolor: #464b5c\n\t\t\t}\n\t\t" }} />
                    </div>
                </div>
                {
                    this.state.new_arivals.length > 0
                    &&
                    <div className="holder">
                        <div className="container">
                            <div className="title-wrap title-md">
                                <h2 className="h2-style" style={{
                                    color: "red !important",
                                    fontWeight: "700"
                                }}>NEW ARRIVALS</h2>
                            </div>
                            <div className="prd-grid-wrap position-relative">
                                <div className="prd-grid data-to-show-3 data-to-show-lg-3 data-to-show-md-2 data-to-show-sm-2 data-to-show-xs-2 js-category-grid justify-content-start">
                                    {
                                        new_arrivals_mapping
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.our_partners.length > 0
                    &&
                    <div className="holder holder-mt-medium">
                        <div className="container">
                            <div className="title-wrap text-left">
                                <h2 className="h2-style">OUR PARTNERS</h2>
                            </div>
                            <ul className="brand-carousel js-brand-carousel slick-arrows-aside-simple" data-slick="{&quot;slidesToShow&quot;: 5,  &quot;responsive&quot;: [{&quot;breakpoint&quot;: 992,&quot;settings&quot;: {&quot;slidesToShow&quot;: 4 }},{&quot;breakpoint&quot;: 480,&quot;settings&quot;: {&quot;slidesToShow&quot;: 2 }}]}">
                                {
                                    our_partners_mapping
                                }
                            </ul>
                        </div>
                    </div>
                }

                {
                    this.state.featured_products.length > 0
                    &&
                    <div className="holder">
                        <div className="container">
                            <div className="title-wrap title-md">
                                <h2 className="h2-style">FEATURED PRODUCTS</h2>
                            </div>
                            <div className="prd-grid-wrap position-relative">
                                <div className="prd-grid data-to-show-4 data-to-show-lg-3 data-to-show-md-2 data-to-show-sm-2 data-to-show-xs-2 js-category-grid justify-content-start">
                                    {
                                        featured_products_mapping
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.storefronts.length > 0
                    &&
                    <div className="holder holder-mt-small" id="new-stores">
                        <div className="container">
                            <div className="title-wrap title-md">
                                <h2 className="h2-style">NEW STORES</h2>
                            </div>
                            <div className="collection-grid-2 row justify-content-start">
                                {
                                    storefronts_mapping
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.merchant_shippings.length > 0
                    &&
                    <div className="holder holder-mt-small" id="holderShopFeature">
                        <div className="container">
                            <div className="row no-gutters ">
                                {merchant_shippings_mapping}
                            </div>
                        </div>
                        <style dangerouslySetInnerHTML={{ __html: "\n\t\t#holderShopFeature .shop-feature-bg .shop-feature-icon {\n\t\t\tcolor: #ffd800\n\t\t}\n\t\t\n\t\t#holderShopFeature .shop-feature-bg:hover .shop-feature-icon {\n\t\t\tcolor: #ffd800\n\t\t}\n\t\t\n\t\t#holderShopFeature .shop-feature-bg {\n\t\t\tbackground-color: #f7f7f8;\n\t\t}\n\t\t\n\t\t#holderShopFeature .shop-feature-bg:hover {\n\t\t\tbackground-color: #5378f4\n\t\t}\n\t\t\n\t\t#holderShopFeature .shop-feature-bg .shop-feature-text {\n\t\t\tcolor: #464b5c\n\t\t}\n\t\t\n\t\t#holderShopFeature .shop-feature-bg:hover .shop-feature-text {\n\t\t\tcolor: #ffffff\n\t\t}\n\t" }} />
                    </div>
                }
                {
                    this.state.show_quickview_modal
                    &&
                    <QuickViewModal
                        product_id={this.state.product_id}
                        toggleQuickViewModal={this.toggleQuickViewModal}
                        product_data={this.state.quick_view_product || {}}

                    />
                }
            </div>
        );
    }
}

// export default Home;


const mapStateToProps = state => ({
    cart_reducer: state.cart_reducer,
    wishlist_reducer: state.wishlist_reducer,
});


const mapDispatchToProps = () => {
    return {
        incrementCart, decrementCart, addToWishlist, removeFromWishlist,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(Home);