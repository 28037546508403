import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { css } from "@emotion/react";
class Spinner extends Component {
    constructor(props) {

        super(props);
        var appState = JSON.parse(localStorage.getItem('appState'))

        this.state = {
            isLoggedIn: appState?.isLoggedIn || false,
        }
    }

    render() {

        const override = css`
        ${this.props.css}
        `;
        // if (this.props.small) {
        //     return (
        //         <div className="spinner_container" style={{ minHeight: "100px", display: "flex", justifyContent: "center" }}>
        //             <div className="spinner-border" role="status">
        //                 <span className="sr-only">Loading...</span>
        //             </div>
        //         </div>
        //     )
        // }

        // return (
        //     <div className="row">
        //         <div className="col-md-12">
        //             <div className="spinner_container">
        //                 <div className="spinner-border" role="status">
        //                     <span className="sr-only">Loading...</span>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // )

        return <div className="d-flex justify-content-center align-items-center" style={{
            height: this.props.container_size || "400px",
        }}>
            {
                this.props.type ?
                    <this.props.type size={this.props.size || 150} color={this.props.color || "#000000"} css={override} />
                    :
                    <ClipLoader color={this.props.color || "#000000"} size={this.props.size || 150} css={override} />
            }
        </div>
    }
}

export default withRouter(Spinner);
