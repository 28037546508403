import React, { Component } from 'react'
import ProductVertical from './components/ProductVertical'
import axiosConfig from './axiosConfig'

class ProductsRecommended extends Component {
    constructor(props) {
        super(props)
        this.state = {
            recommended_products: []
        }
    }

    resetRecommendationSlider() {
        if (document.getElementById("recommendation_slider")) {
            document.getElementById("recommendation_slider").remove()
        }

        var recommendation_slider = document.createElement('script');
        recommendation_slider.id = "recommendation_slider"

        recommendation_slider.text = `

            swipemode = true,
            maxXS = 480;
            maxSM = 768;
            maxMD = 992;
            maxLG = 1200;

            w = window.innerWidth
            function prdCarousel() {
                THEME.prdcarousel = {
                    defaults: {
                        carousel: '.js-prd-carousel'
                    },
                    init: function init(options) {
                        $.extend(this.defaults, options);
                        if (w < maxSM && $(this.defaults.carousel).hasClass('js-product-grid-sm')) {
                            return false;
                        }
                        this.reinit();
                    },
                    reinit: function reinit() {
                        if (w < maxSM && $(this.defaults.carousel).hasClass('js-product-grid-sm')) {
                            if ($(this.defaults.carousel).hasClass('slick-initialized')) {
                                $(this.defaults.carousel).css({
                                    'height': ''
                                }).slick('unslick');
                            }
                            return false;
                        } else if ($(this.defaults.carousel).hasClass('slick-initialized')) {
                            return false;
                        }
                        $(this.defaults.carousel).each(function () {
                            var $this = $(this),
                                arrowsplace = void 0;
                            if ($this.parent().find('.carousel-arrows').length) {
                                arrowsplace = $this.parent().find('.carousel-arrows');
                            } else if ($this.closest('.holder').find('.carousel-arrows').length) {
                                arrowsplace = $this.closest('.holder').find('.carousel-arrows');
                            }
                            $this.on('beforeChange', function () {
                                $this.find('.color-swatch').each(function () {
                                    $(this).find('.js-color-toggle').first().trigger('click');
                                });
                            });
                            $this.on('init', function () {
                                THEME.product.productWidth('.prd', $this);
                            });
                            var slidesToShow = 4,
                                slidesToShowLG = 4,
                                slidesToShowMD = 3,
                                slidesToShowSM = 2,
                                slidesToShowXS = 2,
                                speed = 500;
                            if ($this.hasClass('data-to-show-6')) {
                                slidesToShow = 6;
                                speed = 300;
                            } else if ($this.hasClass('data-to-show-5')) {
                                slidesToShow = 5;
                                speed = 300;
                            } else if ($this.hasClass('data-to-show-3')) {
                                slidesToShow = 3;
                                speed = 300;
                            } else if ($this.hasClass('data-to-show-2')) {
                                slidesToShow = 2;
                                speed = 200;
                            } else if ($this.hasClass('data-to-show-1')) {
                                slidesToShow = 1;
                                speed = 200;
                            }
                            if ($this.hasClass('data-to-show-lg-6')) {
                                slidesToShowLG = 6;
                            } else if ($this.hasClass('data-to-show-lg-5')) {
                                slidesToShowLG = 5;
                            } else if ($this.hasClass('data-to-show-lg-3')) {
                                slidesToShowLG = 3;
                            } else if ($this.hasClass('data-to-show-lg-2')) {
                                slidesToShowLG = 2;
                            } else if ($this.hasClass('data-to-show-lg-1')) {
                                slidesToShowLG = 1;
                            }
                            if ($this.hasClass('data-to-show-md-6')) {
                                slidesToShowMD = 6;
                            } else if ($this.hasClass('data-to-show-md-5')) {
                                slidesToShowMD = 5;
                            } else if ($this.hasClass('data-to-show-md-3')) {
                                slidesToShowMD = 3;
                            } else if ($this.hasClass('data-to-show-md-2')) {
                                slidesToShowMD = 2;
                            } else if ($this.hasClass('data-to-show-md-1')) {
                                slidesToShowMD = 1;
                            }
                            if ($this.hasClass('data-to-show-sm-6')) {
                                slidesToShowSM = 6;
                            } else if ($this.hasClass('data-to-show-sm-5')) {
                                slidesToShowSM = 5;
                            } else if ($this.hasClass('data-to-show-sm-3')) {
                                slidesToShowSM = 3;
                            } else if ($this.hasClass('data-to-show-sm-2')) {
                                slidesToShowSM = 2;
                            } else if ($this.hasClass('data-to-show-sm-1')) {
                                slidesToShowSM = 1;
                            }
                            if ($this.hasClass('data-to-show-xs-6')) {
                                slidesToShowXS = 6;
                            } else if ($this.hasClass('data-to-show-xs-5')) {
                                slidesToShowXS = 5;
                            } else if ($this.hasClass('data-to-show-xs-3')) {
                                slidesToShowXS = 3;
                            } else if ($this.hasClass('data-to-show-xs-2')) {
                                slidesToShowXS = 2;
                            } else if ($this.hasClass('data-to-show-xs-1')) {
                                slidesToShowXS = 1;
                            }
                            $this.slick({
                                slidesToShow: slidesToShow,
                                slidesToScroll: slidesToShow,
                                arrows: true,
                                appendArrows: arrowsplace,
                                adaptiveHeight: true,
                                swipe: swipemode,
                                speed: speed,
                                infinite: false,
                                draggable: false,
                                responsive: [{
                                    breakpoint: maxLG,
                                    settings: {
                                        slidesToShow: slidesToShowLG,
                                        slidesToScroll: slidesToShowLG
                                    }
                                }, {
                                    breakpoint: maxMD,
                                    settings: {
                                        slidesToShow: slidesToShowMD,
                                        slidesToScroll: 1
                                    }
                                }, {
                                    breakpoint: maxSM,
                                    settings: {
                                        slidesToShow: slidesToShowSM,
                                        slidesToScroll: 1
                                    }
                                }, {
                                    breakpoint: maxXS,
                                    settings: {
                                        slidesToShow: slidesToShowXS,
                                        slidesToScroll: 1
                                    }
                                }]
                            });
                        });
                    }
                };
                THEME.prdcarousel.init();
            }
            setTimeout(()=>{
                prdCarousel();
            },500)
        `
        document.getElementsByTagName('body')[0].appendChild(recommendation_slider);

    }

    async componentDidMount() {

        // params product without the ids
        await axiosConfig.get("/api/product/recommended?without_ids=")
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        recommended_products: res.data.data
                    }, () => {
                        setTimeout(() => {
                            this.resetRecommendationSlider();
                        }, 200)
                    })
                }
            })
            .catch(err => {

            })
    }

    render() {

        let recommended_products_mapping = this.state.recommended_products.map((el, index) => {
            return <ProductVertical key={el.id}
                product_data={el}
                toggleQuickViewModal={this.props.toggleQuickViewModal}
            />

        })

        return (
            <div
                className="prd-grid prd-carousel js-prd-carousel slick-arrows-aside-simple slick-arrows-mobile-lg data-to-show-4 data-to-show-md-3 data-to-show-sm-3 data-to-show-xs-2"
                data-slick={`"slidesToShow": 4, "slidesToScroll": 2, "responsive": [{"breakpoint": 992,"settings": {"slidesToShow": 3, "slidesToScroll": 1}},{"breakpoint": 768,"settings": {"slidesToShow": 2, "slidesToScroll": 1}},{"breakpoint": 480,"settings": {"slidesToShow": 2, "slidesToScroll": 1}}]}`}>
                {
                    recommended_products_mapping
                }
            </div>
        )
    }

}


export default ProductsRecommended;